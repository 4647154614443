<template>
  <div class="btn-group align-bottom">
    <button type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
      <i class="bi bi-download"></i> Pobierz&nbsp;<i class="bi bi-chevron-down"></i>
    </button>
    <ul class="dropdown-menu">
      <li>
        <button type="button" class="dropdown-item" @click="generatePDF('pl')">PL</button>
      </li>
      <li>
        <button type="button" class="dropdown-item" @click="generatePDF('en')">EN</button>
      </li>
      <li>
        <button type="button" class="dropdown-item" @click="generatePDF('de')">DE</button>
      </li>
    </ul>
  </div>
</template>

<script>
import route from "../../../vendor/tightenco/ziggy/src/js/index.js";
import tinymce from "tinymce";

export default {
  components: {tinymce},
  props: {
    formData: Object,
    customer: Object,
  },
  data() {
    return {
      generatingPDF: false,
    };
  },
  methods: {
    async generatePDF(lang) {
      if (this.generatingPDF) {
        return;
      }
      this.generatingPDF = true;

      // Pobierz dane z formularza
      const data = this.formData;
      data.lang = lang;
      data.customer_id = this.customer.id;
      data.introduction = tinymce.get('textarea_introduction').getContent();
      data.description = tinymce.get('textarea_description').getContent();
      data.epilogue = tinymce.get('textarea_epilogue').getContent();
      data.technical_data = tinymce.get('textarea_technical_data').getContent();

      const offerNumber = $('#offerNumber').html();

      try {
        // Wysyłanie danych POST do kontrolera
        const response = await axios.post(route('offerPdf.download'), data, {
          responseType: 'arraybuffer', // Ustawienie responseType na 'arraybuffer' do obsługi plików binarnych
        });

        // Tworzenie odpowiedzi blob
        const blob = new Blob([response.data], {type: 'application/pdf'});

        // Tworzenie linku do pobrania pliku PDF
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = 'oferta-' + offerNumber + '_' + (data.lang ?? 'pl') + '.pdf' // Nazwa pliku do pobrania
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Zwolnienie zasobów URL
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Błąd podczas generowania pliku PDF:', error);
      } finally {
        this.generatingPDF = false;
      }
    },
  },
};
</script>
