<template>
  <div>
    <div class="d-inline-block">
      <label class="me-1 form-label">{{ label }}
        <div v-if="info" class="d-inline-block">
          <i class="bi bi-info-circle" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-html="true"
             :title="tooltip()">
          </i>
        </div>
        :
      </label>
      <button type="button" class="btn btn-xs btn-outline-primary mb-1"
              @click="toggleVisibility('textarea_'+modelName)">
        <i class="bi" :class="{'bi-eye-fill':hiddenEditor, 'bi-eye-slash':!hiddenEditor}" style="font-size: 14px"></i>
      </button>
    </div>

    <textarea
        ref="textarea"
        :id="'textarea_'+modelName"
        v-model="localModelText"
        class="form-control form-control-sm"
        :class="{'d-none':hiddenEditor}"
        v-bind="attributes"
        :style="{ height: autoHeight ? 'auto' : (computedHeight + 'px') }">
        </textarea>

  </div>
</template>

<script>
import {Tooltip} from 'bootstrap';

export default {
  props: {
    label: String,
    modelName: String,
    modelText: String,
    language: String,
    hidden: Boolean,
    attributes: Object,
    info: Array
  },

  data() {
    return {
      localModelText: this.modelText,
      localLabel: this.label,
      localType: this.type,
      autoHeight: true,
      computedHeight: 0,
      hiddenEditor: this.hidden
    }
  },

  mounted() {
    this.adjustTextareaHeight();
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },

  methods: {
    toggleVisibility(el) {
      let elem = document.getElementById(el);
      this.hiddenEditor = !this.hiddenEditor;
      if (this.hiddenEditor)
        elem.classList.add('d-none')
      else
        elem.classList.remove('d-none')
    },

    adjustTextareaHeight() {
      this.$nextTick(() => {
        const textarea = this.$refs.textarea;
        if (textarea) {
          textarea.style.height = 'auto'; // Resetuj wysokość przed pomiarami
          textarea.style.height = `${textarea.scrollHeight + 5}px`; // Ustaw wysokość na wysokość zawartości
          this.computedHeight = textarea.scrollHeight; // Zapisz obliczoną wysokość
          this.autoHeight = false; // Wyłącz automatyczną wysokość
        }
      });
    },

    tooltip() {
      let text = '<ul>';
      this.info.forEach(function (i) {
        text += `<li>${i}</li>`;
      })
      text += '</ul>';
      return text;
    }
  },

  watch: {
    modelText(newVal) {
      this.localModelText = newVal;
    },

    localModelText(newVal) {
      this.$emit('updateModel', {
        modelName: this.modelName,
        value: newVal,
      });
    }
  }
}
</script>
<style>
.tooltip-inner {
  text-align: left !important;
}
</style>