<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">

        <projectMenu :project="project"></projectMenu>

        <div class="widget-content">
          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="card-body">
            <div class="row mt-5">
              <div class="col-sm-12">
                <h4>Płatności</h4>
                <div class="loader-box-risk d-none"
                     style="position:absolute; height: 100%; width: 100%; z-index: 99; text-align: center; background-color: rgba(255,255,255,.7); display: flex;justify-content: center;align-items: center;">
                  <div class="loader"></div>
                  <div class="position-absolute" style="top:75%">Dodawanie nowego wiersza</div>
                </div>
                <table class="table table-bordered">
                  <tr>
                    <td></td>
                    <td class="text-center py-2"><b>KWOTA NETTO</b></td>
                    <td class="text-center py-2"><b>KWOTA BRUTTO</b></td>
                    <td colspan="text-center py-2">VAT</td>
                    <td colspan="7"></td>
                  </tr>
                  <tr>
                    <td class="ps-2">Wartość projektu</td>
                    <td class="text-center">
                      {{ formatMoney(offerPriceNet) ?? '0,00' }} {{ offerCurrency }}
                    </td>
                    <td class="text-center">
                      {{ formatMoney(offerPriceGross) }} {{ offerCurrency }}
                    </td>
                    <td class="position-relative">
                      <select class="form-select form-select-sm btn-outline-primary"
                              @change="changeGrossPayment(paymentVat, 'vat')" v-model="paymentVat">
                        <option value="0" :selected="paymentVat === 0">0%</option>
                        <option value="8" :selected="paymentVat === 8">8%</option>
                        <option value="23" :selected="paymentVat === 23">23%</option>
                      </select>
                      <div v-if="vatIsUpdating"
                           class="position-absolute spinner-border spinner-border-reverse align-self-center loader-sm"
                           style="top:8px; right: 10px">
                      </div>
                    </td>
                    <td colspan="7">
                    </td>
                  </tr>
                  <tr>
                    <td :rowspan="editPayments.length <= 0 ? 2 : editPayments.length+2" class="ps-2">Płatności</td>
                    <td colspan="2" class="text-center pt-3 pb-3">Warunki płatności</td>
                    <td class="text-center">Kwota zapłaty netto</td>
                    <td class="text-center">Kwota zapłaty brutto</td>
                    <td class="text-center">Waluta</td>
                    <td class="text-center">Kurs</td>
                    <td class="text-center">Numer faktury</td>
                    <td class="text-center">Data zapłaty</td>
                    <td></td>
                  </tr>
                  <tr class="text-center" v-for="payment in editPayments">
                    <td @dblclick="editingPaymentTerms1(payment)">
                      <div v-if="isEditingTerms1[payment.id]">
                        <input type="text" v-model="payment.payment_terms1" class="form-control form-control-sm">
                      </div>
                      <div v-else>
                        {{ payment.payment_terms1 }}
                      </div>
                    </td>
                    <td @dblclick="editingPaymentTerms2(payment)">
                      <div v-if="isEditingTerms2[payment.id]">
                        <input type="text" v-model="payment.payment_terms2" class="form-control form-control-sm">
                      </div>
                      <div v-else>
                        {{ payment.payment_terms2 }}
                      </div>
                    </td>
                    <td>
                      <input @change="changeGrossPayment(payment.id, 'net_payment_amount')"
                             class="w-100 btn btn-sm btn-outline-primary" type="number" step="0.01" :key="payment.id"
                             v-model="payment.net_payment_amount">
                    </td>
                    <td>
                      <input @change="changeGrossPayment(payment.id, 'gross_payment_amount')"
                             class="w-100 btn btn-sm btn-outline-primary" type="number" step="0.01" :key="payment.id"
                             v-model="payment.gross_payment_amount">
                    </td>
                    <td>
                      <select @change="changeGrossPayment(payment.id, 'currency')"
                              class="form-select form-select-sm btn-outline-primary" v-model="payment.currency"
                              :key="payment.id">
                        <option value="PLN" :selected="payment.currency === 'PLN'">PLN</option>
                        <option value="EUR" :selected="payment.currency === 'EUR'">EUR</option>
                        <option value="USD" :selected="payment.currency === 'USD'">USD</option>
                      </select>
                    </td>
                    <td>
                      <input @change="changeGrossPayment(payment.id, 'exchange_rate')"
                             class="w-100 btn btn-sm btn-outline-primary" type="number" step="0.01" min="1"
                             :key="payment.id" v-model="payment.exchange_rate">
                    </td>
                    <td>
                      <input @change="changeGrossPayment(payment.id, 'invoice_number')"
                             class="w-100 btn btn-sm btn-outline-primary" type="text" :key="payment.id"
                             v-model="payment.invoice_number">
                    </td>
                    <td>
                      <input @change="changeGrossPayment(payment.id, 'payment_date')"
                             class="w-100 btn btn-sm btn-outline-primary" type="date" :key="payment.id"
                             v-model="payment.payment_date">
                    </td>
                    <td>
                      <button @click="removePayment(payment)">
                        <i class="bi bi-trash text-danger"></i>
                      </button>
                    </td>
                    <td class="text-center align-middle">
                                            <span v-for="changes in paymentChanged" class="p-0">
                                                <button v-if="changes === payment.id" @click="updatePayment(payment.id)"
                                                        type="button" class="btn btn-sm btn-primary">Aktualizuj</button>
                                            </span>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="creatingPaymentTerms">
                      <input type="text" class="form-control form-control-sm btn-outline-warning"
                             v-model="newPaymentTerm.term1">
                    </td>
                    <td v-if="creatingPaymentTerms">
                      <input type="text" class="form-control form-control-sm btn-outline-warning"
                             v-model="newPaymentTerm.term2">
                    </td>

                    <td :colspan="creatingPaymentTerms === true ? 1 : 8" class="text-end">
                      <button class="btn btn-sm btn-primary me-2 py-1" v-if="creatingPaymentTerms"
                              @click="storePaymentTerm()">
                        <i class="bi bi-download"></i>&nbsp;Zapisz
                      </button>
                      <button class="btn btn-sm btn-outline-primary py-1" @click="createPaymentTerm()">
                        <i class="bi bi-plus-circle"></i>&nbsp;
                        <span v-if="creatingPaymentTerms">anuluj </span>
                        <span v-if="!creatingPaymentTerms">dodaj </span>
                        &nbsp;warunki
                      </button>
                    </td>
                  </tr>
                  <tr style="font-weight: 600;">
                    <td class="ps-2 pt-3 pb-3" colspan="2">Pozostaje do zapłaty Netto</td>
                    <td class="text-end">
                                            <span
                                                :class="
                                        {'text-warning':(offerPriceNet - alreadyPaid.net.toFixed(2)) < 0,
                                         'text-success':(offerPriceNet - alreadyPaid.net.toFixed(2)) === 0}"
                                                v-if="offerPriceNet && alreadyPaid.net">
                                            {{ formatMoney(offerPriceNet - alreadyPaid.net) }} {{ offerCurrency }}
                                            </span> <span v-else>-</span>
                    </td>
                    <td colspan="8"></td>
                  </tr>
                  <tr style="font-weight: 600;">
                    <td class="ps-2 pt-3 pb-3" colspan="2">Pozostaje do zapłaty Brutto</td>
                    <td class="text-end">
                                            <span
                                                :class="
                                        {'text-warning':(offerPriceGross - alreadyPaid.gross.toFixed(2)) < 0,
                                         'text-success':(offerPriceGross - alreadyPaid.gross.toFixed(2)) === 0}"
                                                v-if="offerPriceNet && offerPriceGross && alreadyPaid.gross">
                                            {{ formatMoney(offerPriceGross - alreadyPaid.gross) }} {{ offerCurrency }}
                                            </span> <span v-else>-</span>
                    </td>
                    <td colspan="8"></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import projectMenu from "@/Pages/Projects/parts/projectMenu.vue";


export default {
  components: {AppLayout, projectMenu},
  props: {
    project: Object,
    payments: Object,
    offerVersion: Object
  },
  data() {
    return {
      vatIsUpdating: false,
      editPayments: this.payments,
      netProjectValue: 0,
      grossProjectValue: 0,
      creatingPaymentTerms: false,
      paymentChanged: [],
      newPaymentTerm: {
        term1: '',
        term2: ''
      },
      offerPriceNet: parseFloat(this.offerVersion.price) || 0,
      paymentVat: this.payments[0] ? this.payments[0].vat : 0,
      offerPriceGross: parseFloat(this.offerVersion.price + (this.offerVersion.price * (this.paymentVat / 100))),
      offerCurrency: this.offerVersion.currency || "PLN",
      alreadyPaid: {net: 0, gross: 0},
      isEditingTerms1: {},
      isEditingTerms2: {}
    }
  },

  mounted() {
    this.getPaidSum();
  },

  methods: {
    route,
    isRouteActive(routeName) {
      return route().current() === routeName;
    },

    createPaymentTerm() {
      this.creatingPaymentTerms = this.creatingPaymentTerms !== true;
    },

    storePaymentTerm() {
      const self = this;

      axios.post(route('projects.show.payments.storePaymentTerm', self.project.id), {
        'payment_terms1': self.newPaymentTerm.term1,
        'payment_terms2': self.newPaymentTerm.term2
      }).then(response => {
        self.newPaymentTerm.term1 = '';
        self.newPaymentTerm.term2 = '';

        self.editPayments = response.data.payments;
        this.creatingPaymentTerms = false;

        Toast.fire({
          toast: true,
          icon: response.data.icon,
          title: response.data.title,
          html: response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })
      }).catch(error => {
        console.log(error);
        Toast.fire({
          toast: true,
          icon: 'error',
          title: 'Błąd',
          html: error.response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })
        console.log(error);
      })
    },

    updatePayment(paymentId) {
      const self = this;
      let payment = this.editPayments.find(payment => payment.id === paymentId);

      axios.post(route('projects.show.payments.updatePayment', paymentId), {
        'payment': payment,
      }).then(response => {

        if (response.data.payments) {
          // usuń kwoty do zmiany z paymentChanged
          let toRemove = self.paymentChanged.indexOf(paymentId);
          if (toRemove !== -1) {
            self.paymentChanged.splice(toRemove, 1);
          }

          // usuń warunki płatności z paymentChanged
          delete self.isEditingTerms1[paymentId];
          delete self.isEditingTerms2[paymentId];
        }
        if (self.paymentChanged.length === 0) {
          self.editPayments = response.data.payments;
        }

        self.getPaidSum(); // zaktualizuj pozostałe do zapłaty

        Toast.fire({
          toast: true,
          icon: response.data.icon,
          title: response.data.title,
          html: response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })
      }).catch(error => {
        console.log(error);
        Toast.fire({
          toast: true,
          icon: 'error',
          title: 'Błąd',
          html: error.response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })
        console.log(error);
      })
    },

    editingPaymentTerms1(payment) {
      this.isEditingTerms1[payment.id] = true;
      this.changeGrossPayment(payment.id, 'payment_terms1');
    },

    editingPaymentTerms2(payment) {
      this.isEditingTerms2[payment.id] = true;
      this.changeGrossPayment(payment.id, 'payment_terms2');
    },

    removePayment(payment) {
      Toast.fire({
        toast: false,
        icon: 'warning',
        title: 'Usuwanie płatności',
        html: 'Jesteś pewny że chcesz usunąć płatność?',
        showDenyButton: true,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        position: 'center',
        showConfirmButton: true,
        denyButtonText: 'Nie',
        confirmButtonText: 'Tak',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(route('projects.show.payments.destroyPayment', this.project.id), {
            payment: payment
          }).then(response => {
            this.editPayments = response.data.payments;
            this.getPaidSum();
            Toast.fire({
              toast: true,
              icon: response.data.icon,
              title: response.data.title,
              html: response.data.message,
              showClass: {popup: 'animate__animated animate__fadeInDown'},
              hideClass: {popup: 'animate__animated animate__fadeOutUp'},
              timer: 2500,
            })
          }).catch(error => {
            console.log(error);
            Toast.fire({
              toast: true,
              icon: 'error',
              title: 'Błąd',
              html: error.response.data.message,
              showClass: {popup: 'animate__animated animate__fadeInDown'},
              hideClass: {popup: 'animate__animated animate__fadeOutUp'},
              timer: 2500,
            })
            console.log(error);
          })
        }
      });
    },

    changeGrossPayment(id, item) {
      if (!this.paymentChanged.includes(id)) {
        this.paymentChanged.push(id);
      }

      // jeśli zmienia się kwota zapłaty netto pozycji
      if (item === 'net_payment_amount') {
        let net = this.editPayments.find(payment => payment.id === id).net_payment_amount;
        let exchangeRate = this.editPayments.find(payment => payment.id === id).exchange_rate;
        this.editPayments.find(payment => payment.id === id).gross_payment_amount = (net * exchangeRate + (net * (this.paymentVat / 100))).toFixed(2);

      } else if (item === 'vat') {
        let newVat = id;
        // zmień vat dla wszystkich pozycji płatności
        // oraz przelicz brutto dla nich
        this.editPayments.forEach(function (row, val) {
          let net = row.net_payment_amount;
          row.vat = newVat;
          row.gross_payment_amount = (net + (net * (newVat / 100))).toFixed(2);
        })

        // zapisz nowy vat w bazie
        this.vatIsUpdating = true;
        axios.post(route('projects.show.payments.updateVatPayment', {project: this.project}), {
          vat: newVat
        }).catch(error => {
          console.log(error);
        }).finally(()=>{
          this.vatIsUpdating=false;
        })

        // oblicz brutto dla wartości projektu
        this.offerPriceGross = this.offerPriceNet + (this.offerPriceNet * (newVat / 100));
      }

      this.getPaidSum();
    },


    getPaidSum() {
      let payments = this.editPayments;
      let paidNet = 0;
      let paidGross = 0;
      let vat = this.editPayments[0] ? this.editPayments[0].vat : 0;

      // oblicz brutto dla wartości projektu
      this.offerPriceGross = this.offerPriceNet + (this.offerPriceNet * (vat / 100));

      // oblicz ile projektu zostało opłacone
      payments.forEach(function (payment) {
        paidNet += parseFloat(payment.net_payment_amount) * (payment.exchange_rate ?? 1)
        paidGross += parseFloat(payment.gross_payment_amount) * (payment.exchange_rate ?? 1)
      });

      this.alreadyPaid.net = paidNet;
      this.alreadyPaid.gross = paidGross;
    },

    formatMoney(value) {
      return new Intl.NumberFormat('pl-PL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);
    }
  },
}
</script>
