<template>
    <AppLayout>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-six">

                <div class="widget-heading">
                    <div class="row">
                        <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
                            <h4 class="me-3 my-0">Status płatności</h4>
                        </div>
                    </div>
                </div>

                <div class="widget-content">
                    <div class="mt-4">

                        <div class="custom-table">
                            <div class="table-responsive">
                                <table id="table-status-payments" class="table table-hover">
                                    <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Nazwa projektu</th>
                                        <th>Klient</th>
                                        <th>Numer</th>
                                        <th>Data zakończenia FAT</th>
                                        <th>Data zakończenia SAT</th>
                                        <th>Kierownik projektu</th>
                                        <th>Uwagi</th>
                                        <th>Informacje o Płatności</th>
                                        <th>Kwota netto (brutto)</th>
                                        <th>Pozostało do zapłaty</th>
                                        <th>Faktura</th>
                                        <th>Data płatności</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import {usePage} from "@inertiajs/vue3";

export default {
    components: {AppLayout},
    props: {
        payments: Object
    },

    setup(props) {

        return {
            paymentsData: props.payments
        }
    },

    data() {
        return {
            table: null,
            projects: usePage().props.projects,
        }
    },

    mounted() {
        this.initDataTable();
        this.initFilters();
    },
    beforeDestroy() {
        this.table.destroy();
    },


    methods: {
        initDataTable() {
            const self = this;
            const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;


            this.table = $(document.getElementById('table-status-payments')).DataTable({
                    colReorder: true,
                    stateSave: true,
                    serverSide: true,
                    processing: true,
                    ajax: {
                        url: '/projects/projects-payments-status/get-payments', // endpoint do pobierania danych z serwera
                        type: 'GET',
                        data: function (params) {
                            params.additional_param = 'value'; // Dodaj dodatkowe parametry, jeśli są potrzebne
                            return params;
                        },
                        dataSrc: 'data',
                    },
                    stateSaveCallback: function (settings, data) {
                        $.ajax({
                            url: '/save-column-data?table=payments-status',
                            data: data,
                            type: 'POST',
                            dataType: 'json',
                            headers: {
                                'X-CSRF-TOKEN': csrfToken, // Include the CSRF token in the headers
                            },
                            "success": function () {
                                const datTab = $('.dataTable');
                                // generuj link do wersji oferty
                                let link = datTab.find('a[data-edit]');
                                link.on('click', function (event) {
                                    event.preventDefault();
                                    const id = $(this).data('edit');
                                    self.generateEditProjectLink(id);
                                });

                            }
                        });
                    },
                    stateLoadCallback: function (settings) {
                        let o;
                        $.ajax({
                            url: '/get-table-data?table=payments-status',
                            async: false,
                            dataType: 'json',
                            success: function (json) {
                                o = json;
                            }
                        });
                        let elem = this;
                        self.initColumnSearch(elem, o)
                        return o;
                    },
                    columns: [
                        {
                            width: "3%",
                            title: '#',
                            name: 'id',
                            isUnique: true,
                            data: function (d) {
                                return '<a class="text-primary" href="#" data-edit="' + d.id + '">' + d.id + '</a>';
                            },
                        },
                        {
                            name: 'name',
                            data: function (d) {
                                return '<a class="text-primary" href="#" data-edit="' + d.id + '">' + d.name + '</a>';
                            },
                        },
                        {
                            data: 'customer'
                        },
                        {
                            data: 'number'
                        },
                        {
                            data: 'fat'
                        },
                        {
                            data: 'planned_completion_date'
                        },
                        {
                            data: 'project_manager'
                        },
                        {
                            data: 'comments'
                        },
                        {
                            data: 'payments_terms'
                        },
                        {
                            data: function (d) {
                                let net = d.payments_amount_net ?? '';
                                let gross = d.payments_amount_gross ? '(' + d.payments_amount_gross + ')' : '';
                                let currency = d.currency ?? '';
                                return '<span class="text-nowrap">' + net + ' ' + currency + '</span>' +
                                    '<br>' + gross;
                            },
                        },
                        {
                            data: 'left_to_pay'
                        },
                        {
                            data: 'invoice'
                        },
                        {
                            data: 'payment_date'
                        },
                    ],
                    createdRow: function (row, data, dataIndex) {
                        // po kliknieciu w 'td' przenies do edycji customera
                        const link = $(row).find('a[data-edit]');
                        link.on('click', function (event) {
                            event.preventDefault();
                            const id = $(this).data('edit');
                            self.generateEditProjectLink(id);
                        });
                    },
                },
            );
        },

        initColumnSearch(elem, o) {
            setTimeout(() => {
                let api = elem.api();
                api.columns().eq(0).each((colIdx) => {
                    let cell = document.querySelectorAll('.filters td')[api.column(colIdx).header().cellIndex];
                    let titleCell = document.querySelectorAll('th')[api.column(colIdx).header().cellIndex];
                    let searchText = o.columns[colIdx].search.search;
                    let cleanSearchText = (typeof searchText === 'string') ? searchText.replace(/[()]/g, '') : '';

                    if (cleanSearchText) {
                        cell.innerHTML = `<input type="text" value="${cleanSearchText}" />`;
                    } else {
                        let title = titleCell.textContent;
                        cell.innerHTML = `<input type="text" placeholder="${title}" />`;
                    }

                    // Dodaj klasy z nagłówka kolumny do komórki
                    cell.classList.add(...titleCell.className.split(' ').filter(cls => cls !== 'sorting'));

                    let input = cell.querySelector('input');
                    input.removeEventListener('change', handleInputChange);
                    input.addEventListener('change', handleInputChange);
                    input.removeEventListener('keyup', handleInputChange);
                    input.addEventListener('keyup', handleInputChange);
                });

                function handleInputChange(e) {
                    e.target.setAttribute('title', e.target.value);
                    api.column(e.target.parentNode.cellIndex).search(e.target.value).draw();
                }
            }, 500);
        },

        initFilters() {
            const headerRow = document.querySelector('#table-status-payments thead tr');
            const inputRow = document.createElement('tr');

            Array.from(headerRow.querySelectorAll('th')).forEach(() => {
                const inputCell = document.createElement('td');
                const input = document.createElement('input');
                input.type = 'text';
                input.placeholder = 'Filter...';

                inputCell.classList.add('filters');
                inputCell.appendChild(input);
                inputRow.classList.add('filters');
                inputRow.appendChild(inputCell);
            });

            headerRow.after(inputRow);
        },

        generateEditProjectLink(id) {
            this.$inertia.visit(route('projects.show', {project: id}));
        },
    }
}
</script>
