<template>
  <div v-if="allContactsDrag.length <= 0 && offerContactsDrag.length <= 0">
    <i>Brak osób kontaktowych</i>
  </div>
  <div v-else class="mb-3 mt-1">
    <div class="row" style="width: 95%;">
      <div class="col">
        <label class="form-label"> Wybrane osoby kontaktowe do oferty: </label>
        <div style="border: 1px solid #bfc9d4; border-radius: 6px; overflow: hidden auto">
          <div class="p-1">
            <draggable
                v-model="offerContactsDrag"
                group="customersList"
                :component-data="{ name: 'fade' }"
                item-key="id"
                style="min-height: 50px; max-height: 140px;"
                @change="emitOfferCustomerContacts"
                v-bind="dragOptions"
            >
              <template #item="{ element }">
                <div class="p-1 my-1 mx-2 bg-info" style="border-radius: 6px;cursor: move">
                  <i class="bi bi-grip-vertical"></i> {{ element.first_name }} {{ element.last_name }} -
                  {{ element.position }} {{ element.default ? "(Domyślny kontakt)" : '' }}
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>
      <div class="col-1 align-self-center text-center">
        <i class="bi bi-arrow-left-right"></i>
      </div>
      <div class="col">
        <label class="form-label">Dostępne osoby kontaktowe: </label>
        <div style="border: 1px solid #bfc9d4; border-radius: 6px; overflow: hidden auto">
          <div class="p-1">
            <draggable
                v-model="allContactsDrag"
                group="customersList"
                :component-data="{ name: 'fade' }"
                item-key="id"
                class="list-group"
                v-bind="dragOptions"
                style="min-height: 50px; max-height: 140px;"
            >
              <template #item="{ element }">
                <div class="p-1 my-1 mx-2 bg-light list-group-item" style="border-radius: 6px;cursor: move">
                  <i class="bi bi-grip-vertical"></i> {{ element.first_name }} {{ element.last_name }} -
                  {{ element.position }} {{ element.default ? "(Domyślny kontakt)" : '' }}
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {draggable},
  emits: ['updateOfferCustomerContacts'],
  props: {
    customerContacts: Object,
    offerCustomerContacts: Object,
  },

  data() {
    return {
      offerContactsDrag: this.offerCustomerContacts ?? [],
      allContactsDrag: this.f_filterContacts(),
    };
  },

  methods: {
    f_filterContacts() {
      // Przekształcenie obiektu customerContacts na tablicę
      const customerContactsArray = Object.values(this.customerContacts);

      return customerContactsArray.filter(contact => {
        return !this.offerCustomerContacts.some(offerContact => offerContact.id === contact.id);
      });
    },

    emitOfferCustomerContacts() {
      this.$emit('updateOfferCustomerContacts', this.offerContactsDrag);
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        group: "customersList",
        disabled: false,
        ghostClass: "ghost"
      };
    },
  }
};
</script>
