<template>
    <div class="form auth-boxed">
        <div class="form-container outer">
            <div class="form-form">
                <div class="form-form-wrap">
                    <div class="form-container">
                        <div class="form-content">
                            <h3 class="">Wyślij link do resetowania hasła</h3>

                            <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                            <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>


                            <form class="text-start" @submit.prevent="resetPassword">

                                <div class="form">
                                    <div id="username-field" class="field-wrapper input">
                                        <label for="username">Email</label>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-user"
                                        >
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        <input v-model="formData.email" type="email" id="email" class="form-control"/>
                                    </div>


                                    <div class="d-sm-flex justify-content-between">
                                        <div class="field-wrapper">
                                            <button type="submit" class="btn btn-primary">Zresetuj hasło</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import route from "../../../../vendor/tightenco/ziggy/src/js/index.js";
import {Link} from "@inertiajs/vue3";

export default {
    components: {Link},
    data() {
        return {
            successMessage: '',
            errorMessage: '',
            formData: {
                token: 'DSAHkihr34wh',
                email: '',
            },
        };
    },
    methods: {
        async resetPassword() {
            try {
                await this.$inertia.post(route('password.resetLink'), this.formData);

                // Obsługa sukcesu
                this.successMessage = 'Wysłano link resetowania hasła na adres email.';
                this.errorMessage = '';
            } catch (error) {
                // Obsługa błędów
                console.error(error);
                this.successMessage = '';
                this.errorMessage = 'Wystąpił błąd podczas wysyłania żądania resetowania hasła.';
            }
        }
    }
};
</script>
