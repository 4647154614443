<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six offer-card">
        <div class="widget-heading">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
            </div>
          </div>
        </div>

        <div class="widget-content">

          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <h4 class="me-3 my-0">
            <Link href="#" onclick="history.back()" class="btn btn-sm btn-outline-primary">
              <i class="bi bi-arrow-left"></i>&nbsp; Cofnij
            </Link>
            Tworzenie karty zlecenia
          </h4>

          <form @submit.prevent="checkForm()" enctype="multipart/form-data">
            <div class="row mt-3">
              <div class="col-ms-12 col-md-6">
                <div v-if="errors.customer_id" class="invalid-feedback">{{ errors.customer_id }}</div>
                <template v-if="loading">
                  <div>
                    <div class="spinner-grow text-danger me-2 align-self-center loader-sm "></div>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <label class="form-label" for="sel_customer_id">Klient:</label>
                    <div v-if="form.errors.offer_id">{{ form.errors.offer_id }}</div>
                    <select class="select2 form-select" id="sel_customer_id" v-model="form.customer_id">
                      <option v-for="customer in customers" :value="customer.id">{{
                          customer.name
                        }}
                      </option>
                    </select>
                  </div>

                  <div v-if="chosenCustomer">
                    <label class="form-label" for="customer_projects">Projekt</label>
                    <select class="select2-multiple select2 form-select form-select-sm" id="customer_projects"
                            :value="form.selected_project" @change="updateCustomerProjects">
                      <option v-for="project in customer_projects" :value="project.id">{{ project.name }}</option>
                    </select>
                  </div>

                  <div class="col-auto">
                    <label class="form-label" for="open_date">Data otwarcia zlecenia:</label>
                    <div class="input-group">
                      <input type="date" id="open_date" v-model="form.open_date" class="form-control form-control-sm"
                             style="min-width: 150px">
                    </div>

                    <label class="form-label" for="close_date">Ostateczny termin wykonania zlecenia:</label>
                    <div class="input-group">
                      <input type="date" id="close_date" class="form-control form-control-sm" style="min-width: 150px"
                             v-model="form.close_date">
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="row">
              <div class="col-ms-12">

                <div id="descriptionContainer">
                  <RichTextEditorComponent class="mt-2"
                                           id="editorDescription"
                                           label="Opis"
                                           modelName="description"
                                           :modelText="form.description"
                                           :hidden="false"
                                           :language="'pl'"
                                           @updateModel="updateModel">
                  </RichTextEditorComponent>
                </div>

              </div>

              <div class="col mt-4">
                <orderCardPdfPreviewGenerator class="me-3" :formData="form" aria-action="offerPdf.preview"/>
                <offerPdfDownloadGenerator :formData="form" aria-action="offerPdf.preview"/>

                <div class="float-end">
                  <button id="saveCardBtn" class="btn btn-primary"><i class="bi bi-save pe-2"></i>Zapisz
                  </button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import route from "../../../../vendor/tightenco/ziggy/src/js/index.js";
import {ref, watch} from "vue";

import AppLayout from "../../Layouts/App.vue";
import orderCardPdfPreviewGenerator from "@/Components/OrderCardPdfPreviewGenerator.vue";
import offerPdfDownloadGenerator from "@/Components/OrderCardPdfDownloadGenerator.vue";
import RichTextEditorComponent from "@/Pages/Offers/form-elements/RichTextEditorComponent.vue";
import {useForm, usePage} from "@inertiajs/vue3";

export default {
  components: {AppLayout, orderCardPdfPreviewGenerator, offerPdfDownloadGenerator, RichTextEditorComponent},

  props: {
    project: Object,
    errors: Object,
    orderCard: Object
  },

  setup(props) {

    let form = useForm({
      customer_id: ref(usePage().props.orderCard ? usePage().props.orderCard.customer_id : props.project.customer_id || null),
      selected_project: ref(usePage().props.orderCard ? usePage().props.orderCard.selected_project : props.project.id || null),
      description: ref(usePage().props.orderCard ? usePage().props.orderCard.description : ''),
      open_date: ref(usePage().props.orderCard ? usePage().props.orderCard.open_date : props.project.order_opening_date),
      close_date: ref(usePage().props.orderCard ? usePage().props.orderCard.close_date : props.project.deadline_for_completion)
    })

    return {form}
  },

  data() {
    return {
      customer_projects:[],
      loading: false,
      customers: [],
      completion_time_start_from_contract_submission: false,
      chosenCustomer: null,
      customersLoaded: false
    };
  },

  mounted() {
    this.fetchCustomers();

    watch(() => this.chosenCustomer, () => {
      // Sprawdzamy, czy dane klientów są już wczytane
      if (this.customersLoaded) {
        this.fetchCustomerProjects();
      }
    });

    window.addEventListener('keydown', this.saveCardListener);
  },

  beforeUnmount() {
    window.removeEventListener('keydown', this.saveCardListener);
  },

  methods: {
    async fetchCustomers() {
      this.loading = true;
      const self = this;

      await axios.get(route('get-customers-json')).then(response => {
        this.customers = response.data;
        this.customersLoaded = true;
        this.loading = false;
        this.$nextTick(() => {
          $('#sel_customer_id').select2({
            theme: 'bootstrap-5'
          }).on('select2:select', (e) => {
            self.form.customer_id = parseInt(e.params.data.id);
            self.chosenCustomer = parseInt(e.params.data.id);

            this.fetchCustomerProjects();
          });

        });

      }).catch(error => {
        console.log(error(error));
      })

      if (this.form.customer_id) {
        this.chosenCustomer = this.form.customer_id;
      }

    },
    async fetchCustomerProjects() {
      const self = this;

      if (this.form.customer_id) {
        // Wybrano klienta, pobierz jego osoby kontaktowe
        const selectedCustomer = this.customers.find(customer => customer.id === this.form.customer_id);
        this.customer_projects = selectedCustomer.projects || [];

        this.$nextTick(() => {
          $('#customer_projects').select2({
            theme: 'bootstrap-5',
            closeOnSelect: false,
            placeholder: "Wybierz projekt",
            allowHtml: false,
            language: 'pl',
            allowClear: true,
            tags: false
          }).on('change.select2', (e) => {
            let selected_project = this.customer_projects.find(function (project) {
              return project.id == $('#customer_projects').val();
            });
            // pobieranie daty z projektu
            var openDate = selected_project.order_opening_date
            var closeDate = selected_project.deadline_for_completion
            $("#open-date").val(openDate);
            $("#close-date").val(closeDate);
            self.updateCustomerProjects()
          });

          if (this.form.selected_project) {
            $('#customer_projects').val(this.form.selected_project);
            self.updateCustomerProjects()
          }

        });
      } else {
        // Nie wybrano klienta, wyczyść opcje
        this.customer_projects = [];
      }
    },

    updateCustomerProjects() {
      this.form.selected_project = $('#customer_projects').val();
    },

    checkForm() {
      axios.post(route('orderCard.store', {project: this.project.id}), {form: this.form}).then((response) => {
        this.getData();
        Toast.fire({
          position: 'top-end',
          toast: true,
          icon: response.data.icon,
          title: response.data.title,
          html: response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true
        });
      }).catch((error) => {
        console.log(error);
      })
    },

    getData() {
      axios.get(route('orderCard.getData', {project: this.project.id})).then((response) => {
        this.form.customer_id = response.data.customer_id;
        this.form.project_id = response.data.project_id;
        this.form.selected_project = response.data.selected_project;
        this.form.open_date = response.data.open_date;
        this.form.close_date = response.data.close_date;
        this.form.description = response.data.description;
        this.localModelText = this.form.description;
      }).catch((error) => {
        console.log(error);
      })
    },

    updateModel({modelName, value}) {
      this.form.description = value;
    },

    saveCardListener(event) {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        this.submitingForm = true;
        document.getElementById('saveCardBtn').click();
      }
    },
  }
}
</script>
