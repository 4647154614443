<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Lista usuniętych artykułów</h4>
              <Link class="btn btn-outline-primary btn-sm" :href="route('articles.index')">Lista artykułów
              </Link>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <span
              class="text-warning">TODO: ograniczyć usuwanie do artykułów, które nigdzie nie były wykorzystywane.</span>
          <div>
            <ejs-grid ref="deletedArticlesGrid" id="deletedArticlesGrid" :dataSource="articles" height="500px"
                      :allowTextWrap='true'
                      :allowExcelExport='false'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='false'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='false'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :rowDataBound="onRowDataBound"
                      :rowSelected="rowSelected"
                      :beforeBatchAdd="beforeBatchAdd"
                      :cellEdit="onCellEdit"
                      :toolbarClick="toolbarClick">
              <e-columns>
                <e-column field="id" headerText="ID" width="92" :isPrimaryKey="true" :allowEditing="false"></e-column>
                <e-column field="catalog_number" headerText="Nr katalogowy" width="150"
                          :edit="catalogEditor"></e-column>
                <e-column field="model" headerText="Model" width="150"></e-column>
                <e-column field="article_unit.id" headerText="J.m." width="150" editType="dropdownedit"
                          :dataSource="units" foreignKeyValue='name' foreignKeyField='id'
                          :filter='filterUnits'></e-column>
                <e-column field="description" headerText="Nazwa/Opis" :defaultValue="null" width="200"></e-column>
                <e-column field="created_at" headerText="Utworzono" width="150"
                          :valueAccessor="dateFormatter" :allowEditing="false"></e-column>
                <e-column field="created_by.id" headerText="Utworzył" width="150" :allowEditing="false"
                          :dataSource="users" foreignKeyValue='name' foreignKeyField='id'
                          :defaultValue="$page.props.user.id" :filter="filterCreatedBy"></e-column>
                <e-column field="producer.id" headerText="Producent" width="150" editType="dropdownedit"
                          :dataSource="producers" foreignKeyValue='short_name' foreignKeyField='id'
                          :filter='filterProducers'></e-column>
                <e-column field="article_category.id" headerText="Kategoria" width="250" editType="dropdownedit"
                          :dataSource="categories" foreignKeyValue='name' foreignKeyField='id'
                          :filter='filterCategories'></e-column>
                <e-column field="price_netto_zl" headerText="Cena Netto zł" width="150" format="N"
                          type="number"></e-column>
                <e-column field="price_brutto_zl" headerText="Cena brutto zł" width="150" format="N"
                          type="number"></e-column>
                <e-column field="price_netto_eur" headerText="Cena Netto eur" width="150" format="N"
                          type="number"></e-column>
                <e-column field="price_brutto_eur" headerText="Cena Brutto eur" width="150" format="N"
                          type="number"></e-column>
                <e-column field="photos" headerText="Zdjęcia" width="200" :template="'imageTemplate'"
                          :allowEditing="false"></e-column>
                <e-column field="warehouse.id" headerText="Lokalizacja (magazyn)" width="150" editType="dropdownedit"
                          :dataSource="warehouses" foreignKeyValue='short_name' foreignKeyField='id'
                          :filter='filterWarehouses'></e-column>
                <e-column field="stock_quantity" headerText="Ilośc/Stany magazynowe" width="150" type="number"
                          format="N" editType="numericedit"></e-column>
                <e-column field="income" headerText="Przychód" width="150" format="N" type="number"
                          editType="numericedit"></e-column>
                <e-column field="outflow" headerText="Rozchód" width="150" format="N" type="number"
                          editType="numericedit"></e-column>
                <e-column field="value_net" headerText="Wartość (netto)" width="150" format="N" type="number"
                          editType="numericedit"></e-column>
                <e-column field="value_gross" headerText="Wartość (brutto)" width="150" format="N" type="number"
                          editType="numericedit"></e-column>
                <e-column field="suppliers_short_names" headerText="Dostawca" width="150" editType="string"
                          :filter='filterSuppliers' :edit="suppliersParams"></e-column>
              </e-columns>

              <template v-slot:imageTemplate="{ data }">
                <div v-if="data.photos && data.photos.length > 0">
                  <button type="button" class="btn-invisible" data-bs-toggle="modal"
                          data-bs-target="#gallery"
                          @click="setGalleryToModal(data)">
                    <img :src="`/${data.photos[0].photo_path}`" alt="Zdjęcie" width="150"/>
                    <span v-if="data.photos.length > 1" class="d-block mt-1 bold">
                                    +{{ data.photos.length - 1 }} więcej
                                </span>
                  </button>
                </div>
              </template>
            </ejs-grid>
          </div>

        </div>
      </div>
    </div>


  </AppLayout>
</template>

<script>
import AppLayout from "../../../Layouts/App.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {AutoComplete, MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {TextBox} from '@syncfusion/ej2-inputs';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-vue-grids';
import {usePermission} from "@/composables/resources/js/composables/permissions.js";
import {createElement} from "@syncfusion/ej2-base";
import {DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";

const {hasRole} = usePermission();

let dropInstanceFilterUnits;
export default {
  computed: {
    id() {
      return id
    },

    catalogNumbers() {
      return this.articles.map(article => article.catalog_number).filter((value, index, self) => self.indexOf(value) === index);
    }
  },

  components: {
    AppLayout,
    hasRole,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey]
  },

  props: {
    articles: Object,
    units: Object,
    categories: Object,
    warehouses: Object,
    producers: Object,
    suppliers: Object,
    users: Object
  },

  data() {
    let ddElem;
    let multiSelectObj;

    const createSuppliersFn = () => {
      ddElem = document.createElement('input');
      return ddElem;
    };

    const readSuppliersFn = () => {
      return multiSelectObj.value.join(","); // Zwróć wartości jako string
    };

    const destroySuppliersFn = () => {
      multiSelectObj.destroy(); // Usunięcie instancji MultiSelect
    };

    const writeSuppliersFn = (args) => {
      const selectedValues = args.rowData.suppliers_short_names ? args.rowData.suppliers_short_names.split(",") : [];

      // Inicjalizacja MultiSelect
      multiSelectObj = new MultiSelect({
        value: selectedValues,
        dataSource: this.suppliers,
        fields: {text: "short_name", value: "short_name"},
        placeholder: 'Wybierz dostawców',
        mode: 'Box',
        allowFiltering: true,
        popupHeight: '200px',
        closePopupOnSelect: false,
      });

      multiSelectObj.appendTo(ddElem);
    };

    return {
      sportsData: ['Badminton', 'Basketball', 'Cricket',
        'Football', 'Golf', 'Gymnastics',
        'Hockey', 'Rugby', 'Snooker', 'Tennis'
      ],
      photoForm: useForm({
        article_id: null,
        default: null,
        photo_path: null,
      }),
      selectedFile: null,
      isAddingNew: false,
      selectedArticle: null,
      taskGridKey: 0,
      pageSettings: {pageSize: 50},
      filterOptions: {type: "Menu"},
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {type: 'Multiple', cellSelectionMode: 'Box', mode: 'Cell'},
      suppliersParams: {
        create: createSuppliersFn,
        destroy: destroySuppliersFn,
        read: readSuppliersFn,
        write: writeSuppliersFn
      },
      filterUnits: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
      filterCreatedBy: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.users,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kto stworzył',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
      filterWarehouses: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
      filterProducers: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj Producenta',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
      filterSuppliers: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.suppliers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
    }
  },

  methods:
      {
        getEditSettings() {
          return {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            mode: 'Batch',
            newRowPosition: 'Top',
          };
        },

        getToolbar() {
          return [
            {text: 'Usuń na zawsze!', prefixIcon: 'e-delete', id: 'delete_btn'},
            {text: 'Przywróć', prefixIcon: 'e-add', id: 'restore_btn'},
            {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
            {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
            "ColumnChooser",
          ];
        },

        userCanModify() {
          let rights = 0;
          if (this.usersCanChange) {
            rights = this.usersCanChange.find(email => email === this.$page.props.user.email);
          }

          return !(rights === undefined || rights <= 0);
        },

        beforeBatchAdd(args) {
          // blokada na tworzenie jednocześnie kilku wpisów
          if (this.isAddingNew) {
            args.cancel = true;
          } else {
            this.isAddingNew = true;
          }
        },

        onCellEdit(args) {
          if (args.rowData.deleted_at) {
            // Anuluj edycję, jeśli rekord został usunięty
            args.cancel = true;
            // Opcjonalnie wyświetl powiadomienie
            console.log('Nie można edytować usuniętych rekordów.');
          }
        },

        toolbarClick(args) {
          // Obsługa przycisku delete
          if (args.item.id === 'delete_btn') {
            const selectedRecords = this.$refs.deletedArticlesGrid.ej2Instances.getSelectedRecords();
            if (selectedRecords.length > 0) {
              // Wybierz pierwszy zaznaczony rekord i wywołaj metodę restore
              const articleId = selectedRecords[0].id;

              Toast.fire({
                position: 'center',
                toast: false,
                icon: 'warning',
                title: 'Czy na pewno chcesz usunąć ten artykuł?',
                text: "Ta operacja USUNIE TRWALE ten artykuł!",
                timer: false,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Tak, usuń!',
                cancelButtonText: 'Anuluj',
              }).then((result) => {
                if (result.isConfirmed) {
                  // Jeśli użytkownik potwierdził, przywróć artykuł
                  this.deleteArticle(articleId);
                }
              });
            }
          }

          if (args.item.id === 'restore_btn') {
            const selectedRecords = this.$refs.deletedArticlesGrid.ej2Instances.getSelectedRecords();
            if (selectedRecords.length > 0) {
              // Wybierz pierwszy zaznaczony rekord i wywołaj metodę restore
              const articleId = selectedRecords[0].id;

              Toast.fire({
                position: 'center',
                toast: false,
                icon: 'info',
                title: 'Czy na pewno chcesz przywrócić ten artykuł?',
                text: "Ta operacja przywróci usunięty artykuł!",
                timer: false,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Tak, przywróć!',
                cancelButtonText: 'Anuluj',
              }).then((result) => {
                if (result.isConfirmed) {
                  // Jeśli użytkownik potwierdził, przywróć artykuł
                  this.restoreArticle(articleId);
                }
              });
            }
          }
        },

        restoreArticle(articleId) {
          this.$inertia.post(route('articles.deleted.restore', {article: articleId}), {}, {
            only: ['articles', 'flash'],
            onSuccess: () => {
              // Jeśli przywrócenie zakończy się sukcesem
              this.successMsg('Artykuł został przywrócony pomyślnie.');
              this.$refs.deletedArticlesGrid.ej2Instances.refresh();
              this.$makeClean();
            },
            onError: () => {
              this.errorMsg('Wystąpił błąd przy przywracaniu artykułu.');
            }
          });
        },

        deleteArticle(articleId) {
          this.$inertia.post(route('articles.deleted.delete', {article: articleId}), {}, {
            only: ['articles', 'flash'],
            onSuccess: () => {
              // Jeśli przywrócenie zakończy się sukcesem
              this.successMsg('Artykuł został usunięty pomyślnie.');
              this.$refs.deletedArticlesGrid.ej2Instances.refresh();
              this.$makeClean();
            },
            onError: () => {
              this.errorMsg('Wystąpił błąd przy usuwaniu artykułu.');
            }
          });
        },


        onRowDataBound(args) {
          if (args.data.deleted_at) {  // Sprawdź, czy rekord został "miękko" usunięty
            args.row.classList.add('deleted-row');
          }
        },

        rowSelected(args) {
          this.selectedArticle = args.data;
        },

        dateFormatter(field, data) {
          const date = data.created_at ? data.created_at : null;
          if (date)
            return moment(date).format('DD.MM.YYYY HH:mm:ss');

          return '';
        },

        successMsg(resp) {
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: 'success',
            title: 'Sukces!',
            html: resp,
            showClass: {popup: 'animate__animated animate__fadeInDown'},
            hideClass: {popup: 'animate__animated animate__fadeOutUp'},
            timer: 2500,
            timerProgressBar: true,
            showConfirmButton: false,
            showCloseButton: true
          });
        },

        errorMsg(resp) {
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: 'warning',
            title: 'Błąd!',
            html: resp,
            showClass: {popup: 'animate__animated animate__fadeInDown'},
            hideClass: {popup: 'animate__animated animate__fadeOutUp'},
            timer: 2500,
            timerProgressBar: true,
            showConfirmButton: false,
            showCloseButton: true
          });
        },

        isAdmin() {
          return hasRole('ADMIN');
        },

        catalogEditor() {
          return {
            create: () => {
              console.log('sssss');
              const elem = document.createElement('input');
              return elem;
            },
            read: () => {
              return this.autoCompleteObj.value;
            },
            destroy: () => {
              if (this.autoCompleteObj) {
                this.autoCompleteObj.destroy();
              }
            },
            write: (args) => {
              this.autoCompleteObj = new AutoComplete({
                dataSource: this.catalogNumbers,
                placeholder: 'Wpisz lub wybierz istniejący numer katalogowy',
                allowCustom: true, // Pozwala użytkownikowi wpisać nową wartość
                value: args.rowData ? args.rowData.catalog_number : '' // Ustawienie aktualnej wartości
              });
              this.autoCompleteObj.appendTo(args.element);
            }
          };
        }
      }
}
</script>

<style scoped>
.image-column {
  padding: 15px;
  border: 1px solid #ddd;
  align-items: center;
}

.btn-invisible {
  background-color: transparent;
  padding: 5px;
}
</style>