<script setup>
import {usePermission} from "@/composables/resources/js/composables/permissions.js";
const {hasPermission} = usePermission();

</script>
<template>
    <div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
        aria-hidden="true" data-model-id="">
        <div class="modal-dialog" role="document">
            <div class="modal-content" data-model="">
                <div class="modal-header">
                    <h5 class="modal-title">Komentarze</h5>
                    <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form id="comment-form" style="margin-bottom: 40px" v-if="hasPermission('canViewOffers')">
                        <div class="form-group">
                            <label for="comment-text">Dodaj komentarz:</label>
                            <input type="text" class="form-control" id="comment-text"
                                placeholder="Dodaj swój komentarz" required>
                        </div>
                        <button v-if="hasPermission('canEditBasicProject')" type="submit" id="button-text" class="btn btn-primary">Dodaj komentarz</button>
                        <span id="loading-spinner" style="display: none;">Zapisuję...</span>
                    </form>
                    <div style="max-height: 750px; overflow-y: auto">
                        <template v-if="loading">
                            <div>
                                <div class="spinner-grow text-danger me-2 align-self-center loader-sm "></div>
                            </div>
                        </template>

                        <span id="modelId"></span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
export default {
    data() {
        return {
            searchCol: false,
            loading: false
        };
    },
}
</script>
