<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Lista Kategorii</h4>
              <Link class="btn btn-outline-primary btn-sm" :href="route('articles.index')">Lista artykułów</Link>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th>Nazwa</th>
                <th>Kolejność</th>
                <th>Ilość artykułów w kategorii</th>
                <th>Akcje</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(category, index) in categories" :key="category.id">
                <td>
                  <input v-if="category.isEditing" v-model="category.edited_name" class="form-control"/>
                  <span v-else>{{ category.name }}</span>
                </td>
                <td>
                  <input v-if="category.isEditing" v-model="category.edited_sort" class="form-control" type="number"/>
                  <span v-else>{{ category.sort }}</span>
                </td>
                <td>{{ category.articles_count }}</td>
                <td>
                  <button v-if="!category.isEditing" @click="editCategory(category)" type="button" class="btn btn-sm btn-warning">
                    Edytuj
                  </button>
                  <button v-if="category.isEditing" @click="saveCategory(category)" type="button" class="btn btn-sm btn-primary">
                    <i class="bi bi-save me-1"></i>Zapisz
                  </button>
                  <button v-if="category.isEditing" @click="cancelEdit(category, index)" type="button"
                          class="btn btn-sm btn-outline-primary ms-2">
                    <i class="bi bi-x-circle me-1"></i>Anuluj
                  </button>
                  <button v-if="!category.isEditing && category.articles_count < 1" @click="deleteCategory(category)" type="button"
                          class="btn btn-sm btn-danger ms-2">Usuń
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <button v-if="!this.isCreatingNewCategory" class="btn btn-sm btn-outline-success" type="button" @click="createCategory">
              Utwórz nowy
            </button>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "../../../../Layouts/App.vue";
import {useForm} from "@inertiajs/vue3";

export default {
  components: {
    AppLayout,
  },

  props: {
    categories: Array,
  },

  data() {
    return {
      isCreatingNewCategory: false,
      form: useForm({
        name: null,
        sort: null,
      }),
    };
  },

  methods: {
    // Włącza tryb edycji dla wybranego wiersza i zapisuje oryginalne wartości
    editCategory(category) {
      this.categories.forEach((u) => {
        this.$set(u, "isEditing", false);
      });
      this.$set(category, "isEditing", true);
      this.$set(category, "edited_name", category.name);
      this.$set(category, "edited_sort", category.sort);
    },

    // Anuluje edycję i przywraca oryginalne wartości
    cancelEdit(category, index) {
      if (!category.id) {
        this.categories.splice(index, 1);
        this.isCreatingNewCategory = false;
      } else {
        category.isEditing = false;
        category.edited_name = category.name;
        category.edited_sort = category.sort;
      }
    },

    // Zapisuje zmiany do serwera
    saveCategory(category) {
      this.form.name = category.edited_name;
      this.form.sort = category.edited_sort;

      if (!category.id) {
        // Jeśli to nowy wiersz, użyj form.post
        this.form.post(route('articles.categories.store'), {
          preserveScroll: true,
          onSuccess: () => {
            this.$set(category, "isEditing", false);
          },
        });
      } else {
        // Jeśli to istniejący wiersz, użyj form.put
        this.form.put(route('articles.categories.update', category.id), {
          preserveScroll: true,
          onSuccess: () => {
            this.$set(category, "isEditing", false);
          },
        });
      }
    },

    deleteCategory(category) {
      this.form.delete(route('articles.categories.destroy', category.id), {
        preserveScroll: true,
      });
    },

    // Dodaje nowy wiersz do tabeli
    createCategory() {
      const newCategory = {
        id: null, // Nowy wiersz nie ma jeszcze ID
        name: "",
        sort: "",
        articles_count: 0,
        isEditing: true,
        edited_name: "",
        edited_sort: "",
      };
      this.categories.push(newCategory);
      this.isCreatingNewCategory = true;
    },
  },
};
</script>
