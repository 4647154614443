<style>
.select2-results__option--highlighted {
  font-weight: 700 !important;
}

.select2-selection--single {
  border-radius: 7px !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.7);
}

.dataTables_scrollHeadInner {
  overflow: hidden;
}

</style>

<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Lista Ofert</h4>
              <Link :href="route('offers.create')" class="btn btn-outline-primary">
                <i class="bi bi-plus-circle me-2"></i> Utwórz Ofertę
              </Link>
            </div>
          </div>
        </div>

        <div class="widget-content">

          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="custom-table">
            <div class="table-responsive">
              <table ref="dataTables" class="table table-hover"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal history offer version -->
    <OfferVersionHistoryComponent :offer-version-id="historyOfferVersionId"></OfferVersionHistoryComponent>

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
              <h4 class="me-3 my-0">Statystyki</h4>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
              <GraphWhoSentMostOffers></GraphWhoSentMostOffers>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <GraphStatusesOffers></GraphStatusesOffers>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5">
              <GraphMostAcceptedOffersByCustomer></GraphMostAcceptedOffersByCustomer>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CommentModal></CommentModal>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import {Link} from "@inertiajs/vue3";
import route from "../../../../vendor/tightenco/ziggy/src/js/index.js";
import Modal from 'bootstrap/js/dist/modal';
import GraphWhoSentMostOffers from '../../Components/Widgets/Graphs/WhoSentMostOffers.vue'
import GraphStatusesOffers from '../../Components/Widgets/Graphs/StatusesOffers.vue'
import GraphMostAcceptedOffersByCustomer from '../../Components/Widgets/Graphs/MostAcceptedOffersByCustomer.vue'
import CommentModal from '../Modals/Comments.vue'
import OfferVersionHistoryComponent from "../Modals/OfferVersionHistoryComponent.vue";
import {formatDate} from "v-tables-3/compiled/mixins/methods.js";

export default {
  components: {
    OfferVersionHistoryComponent,
    AppLayout,
    Link,
    GraphWhoSentMostOffers,
    GraphStatusesOffers,
    GraphMostAcceptedOffersByCustomer,
    CommentModal
  },

  props: {
    allStatuses: Array,
  },
  data() {
    return {
      searchCol: false,
      loading: false,
      dtable: null,
      oldData: [],
      newData: [],
      intervalUpdatingDataTables: null,
      modal_history: null,
      historyOfferVersionId: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.initDataTable();
        this.initFilters();
      }, 100)
    })

    const self = this;
    this.$nextTick(function () {
      $(self.$refs.dataTables).on('click', '.modal-comment', async function () {
        let offerId = $(this).data('comment');
        $('.modal-content').attr('data-model', offerId);
        $('#commentModal').attr('data-model-id', offerId);
        await self.fetchComments($(this).data('comment'))

        var modalComm = new Modal(document.getElementById('commentModal'));
        modalComm.show();
        $('#modelId').html('');
      });
    })

    // UPDATING DATATABLES EVERY X SECONDS
    this.intervalUpdatingDataTables = setInterval(() => {
      if ($(self.dtable) && $(self.dtable).ready()) {
        this.updatingDataTablesAjax();
      }
    }, 30000);

    $('#comment-form').submit(async function (event) {
      await self.addComment(event)
    });

    // modal history offer
    this.$nextTick(function () {
      $(self.$refs.dataTables).on('click', '.btn-show-history-offer-modal', async function () {
        let data = $(this).attr('data-offer-viersion-id');

        self.setOfferVersionId(data);

        let modalOfferHistory = new Modal(document.getElementById('offerVersionHistoryModal'));
        modalOfferHistory.show();
      })
    });
  },
  beforeUnmount() {
    if (this.dtable) {
      this.dtable.destroy();
    }
    clearInterval(this.intervalUpdatingDataTables);
  },
  methods: {
    setOfferVersionId(id) {
      this.historyOfferVersionId = parseInt(id);
    },

    initDataTable() {
      let allStatuses = $(this.allStatuses);
      let headerElement = document.querySelector('.sub-header-container');
      let heightOfHeader = headerElement.offsetTop + headerElement.offsetHeight;

      const self = this;

      this.dtable = $(this.$refs.dataTables).DataTable({
        destroy: true,
        fixedHeader: {header: true, headerOffset: heightOfHeader},
        scrollX: true,
        scrollCollapse: true,
        serverSide: true,
        processing: true,
        colReorder: true,
        stateSave: true,
        stateDuration: 0,
        order: [[0, 'asc']],
        ajax: {
          url: route('get-offers-datatables'), // endpoint do pobierania danych z serwera
          type: 'get',
          data: function (params) {
            return params;
          },
        },
        stateSaveCallback: function (settings, data) {
          $.ajax({
            url: route('save-column-data', {'table': 'offers'}),
            data: data,
            type: 'POST',
            headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
            dataType: 'json',
            success: function () {
              const datTab = $('.dataTable');
              // generuj link do wersji oferty
              const link = datTab.find('a[data-edit]');
              link.on('click', function (event) {
                event.preventDefault();
                const id = $($(this)).data('edit');
                self.generateEditAccountLink(id);
              });
              self.initSelect2ForStatusColumn(datTab);

            }
          });
        },
        stateLoadCallback: function (settings) {
          let o;
          $.ajax({
            url: route('get-table-data', {'table': 'offers'}),
            async: false,
            type: 'GET',
            dataType: 'json',
            success: function (json) {
              o = json;
            }
          });
          let elem = this;
          self.initColumnSearch(elem, o)
          return o;
        },
        columns: [
          {
            data: 'versions',
            title: 'Numer',
            name: 'all_version_ids',
            render: function (data, idx, row) {
              //sortowanie wyników
              data.sort(function (a, b) {
                return b.id - a.id;
              });
              let versions = data.map(function (version) {
                let startVersion = '<div class="d-flex my-1">';
                let endVersion = '</div>';
                let previewPDF = '<a href="' + route("offerPdf.preview.from-offer", {
                  'offer': version.offer_id,
                  'version': version.version
                }) + '" data-id="' + row.id + '" target="_blank" class="btn btn-xs btn-outline-primary me-1"><i class="bi bi-search"></i></a>';

                const versionClass = version.is_active ? 'text-success' : 'text-muted';
                const versionUrl = `
                    <a class="text-nowrap d-flex" href="/offers/${row.id}?version=${version.version}" data-edit="${row.id}?version=${version.version}">
                        <span class="${versionClass}">${version.number}</span>
                    </a>`;

                let historyModal = '<button type="button" class="ms-1 btn-show-history-offer-modal btn btn-xs btn-outline-info d-inline-flex" data-offer-viersion-id="' + version.id + '"><i class="bi bi-clock-history"></i></button>';
                return startVersion + previewPDF + versionUrl + historyModal + endVersion;
              }).join(''); // Połącz przetworzone wartości w jeden ciąg HTML

              return '<div class="align-items-center">' + versions + '</div>';
            }
          },

          {
            title: 'Klient',
            name: 'customer.name',
            class: 'offerId',
            render: function (data, idx, row) {
              let versions = row.versions;
              let customer = row.customer.name ?? '';
              let version = versions.map(function (version) {
                if (version.is_active) {
                  return version.version;
                }
              }).join('');
              return '<a href="#" class="text-primary" data-id="' + row.id + '" data-edit="' + row.id + '">' + customer + '</a>';
            },
          },
          {
            data: 'versions',
            title: 'Tytuł',
            name: 'active_offer_version_title',
            render: function (data) {
              return data.map(function (version) {
                if (version.is_active) {
                  const trimmedTitle = version.title.length > 100 ? `${version.title.substring(0, 100)}...` : version.title;
                  return `<span title="${version.title}">${trimmedTitle}</span>`;
                }
              }).join('');
            }
          },
          {
            data: 'versions',
            title: 'Cena',
            name: 'active_offer_version_price',
            render: function (data) {
              return data.map(function (version) {
                let currency = 'PLN'
                if (version.is_active) {
                  if (version.currency) {
                    currency = version.currency
                  }
                  if (version.price) {
                    return "<span class='text-nowrap'>" + self.formatMoney(parseFloat(version.price) ?? 0) + " " + currency + "</span>";
                  }
                }
              }).join('');
            }
          },
          {
            data: 'status',
            title: 'Status',
            name: 'status.status',
            render: function (data, idx, row) {
              let offerVersion = row.versions.find(version => version.is_active);

              let selectHtml = '<div data-active-offer-version-id="' + offerVersion.id + '" class="status-container d-flex align-items-center" style="min-width: 180px"><select class="select-status form-select form-control form-select-sm me-1 select2">';

              if (!row.status) {
                row.status = {};
                row.status.id = 1;
              }

              allStatuses.each(function (idx, val) {
                let selected = val.id === offerVersion.status_id ? 'selected' : '';
                selectHtml += '<option value="' + val.id + '" ' + selected + ' data-bg_color="' + val.bg_color + '" data-color="' + val.color + '">' + val.status + '</option>';
              });
              selectHtml += '</select>' +
                  '<div class="loader loader-sm d-none" style="width:28px;"></div>' +
                  '<div class="statusActions d-none d-flex align-items-center ms-1">' +
                  '<button class="save-new-status btn btn-xs btn-success me-1"><i class="bi bi-check-circle"></i></button>' + '<button class="cancel-new-status btn btn-xs btn-danger"><i class="bi bi-x-circle"></i></button>' +
                  '</div>' +
                  '</select>';

              return selectHtml;
            }
          },
          {
            data: 'offer_last_comment',
            title: 'Komentarz',
            name: 'offer_last_comment',
            render: function (data, idx, row) {
              let createdBy = '';
              return "<span class='text-nowrap'>" + data + "</span>" + '<button data-comment="' + row.id + '" class="modal-comment btn btn-xs btn-light-default"><i class="bi bi-chat-dots" style="font-size:.9rem"></i></button>';

            },
          },
          {
            data: 'changed_status_to_sent_at',
            title: 'Wysłana',
            name: 'changed_status_to_sent_at',
            render: function (data) {
              return "<span class='text-nowrap'>" + self.formatDate(data) + "</span>";
            },
          },
          {
            data: 'active_offer_creator',
            title: 'Utworzył',
            name: 'active_offer_creator',
            render: function (data) {
              let createdBy = '';
              $(data).each(function (idx, val) {
                if (val.is_active)
                  createdBy = val.created_by ?? '';
              })
              return "<span class='text-nowrap'>" + data + "</span>";
            },
          },
          {
            data: 'active_offer_creator',
            title: 'Aktualizował',
            name: 'active_offer_creator',
            render: function (data, display, r) {
              let firstName = r.last_version_history?.updated_by?.first_name || data;
              let lastName = r.last_version_history?.updated_by?.last_name || '';

              return "<span class='text-nowrap'>" + firstName + ' ' + lastName + "</span>";
            },
          },
          {
            data: 'created_at',
            title: 'Utworzono',
            render: function (data) {
              return "<span class='text-nowrap'>" + self.formatDate(data) + "</span>";
            },
          },
          {
            data: 'updated_at',
            title: 'Ost. aktualizacja',
            render: function (data, d, r) {
              const date = r.active_version.updated_at || data;
              return "<span class='text-nowrap'>" + self.formatDate(date) + "</span>";
            },
          },
        ],
        initComplete: function () {
        },
      });
    },

    async updatingDataTablesAjax() {
      // sprawdza czy są nowe dane, jeśli tak to updateuje dataTables
      // pobieranie danych:
      this.newData = await this.getDataForDataTables();

      // jezeli jest to pierwsze sprawdzanie, to zrównaj oldData z newData
      if (this.oldData.data?.data === undefined) {
        this.oldData = this.newData;
      }

      if (this.dtable && JSON.stringify(this.newData.data?.data) !== JSON.stringify(this.oldData.data?.data)) {
        this.dtable.ajax.reload();
        this.oldData = this.newData;
      }
    },

    getDataForDataTables() {
      return axios.get(route('get-offers-datatables'));
    },

    generateEditAccountLink(id) {
      this.$inertia.visit(route('offers.show', {offer: id}));
    },

    formatMoney(number) {
      let price = number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ').replace('.', ',');

      return price.replace(' ', '.');
    },

    formatDate(date) {
      if (!date) return '';

      // Tworzenie obiektu Date z formatu "YYYY-MM-DD HH:MM:SS"
      const dateObj = new Date(date.replace(' ', 'T'));

      // Sprawdzenie, czy data jest poprawna
      if (isNaN(dateObj.getTime())) {
        return ''; // Zwróć pusty ciąg znaków, jeśli data jest niepoprawna
      }

      // Formatowanie daty
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Miesiące są zerobazowe, więc dodajemy 1
      const year = dateObj.getFullYear();

      const hours = String(dateObj.getHours()).padStart(2, '0');
      const minutes = String(dateObj.getMinutes()).padStart(2, '0');
      const seconds = String(dateObj.getSeconds()).padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    },

    async fetchComments(offerId) {
      this.loading = true;

      axios.get(route('get-offer-comments'), {
            params: {
              offer_id: offerId
            }
          }
      ).then(response => {
        this.comments = response.data;
        this.loading = false;
        $('#modelId').html(this.comments)
        this.customersLoaded = true;
        $('#modelId').html('');


        // Iterate through the comments and build the HTML
        this.comments.forEach(comment => {
          const commentHtml = `
                <div class="comment" style="padding-top: 15px; border-top:1px solid #ccc">
                    <p class="comment-text text-black"><strong>${comment.comment}</strong></p>
                    <div class="comment-info">
                        <p>
                          <small><i>Utworzony przez: ${comment.created_by_name}</i></small><br>
                          <small><i>Utworzony w dniu: ${comment.created_at}</i></small>
                        </p>
                    </div>
                    <hr/>
                </div>
            `;
          $('#modelId').append(commentHtml);
        });
        this.loading = false;
      }).catch(error => {
        console.log(error);
      })
    },
    async saveStatusColumn(offerId, newStatus) {
      await axios.post(route('offer.save-status', {offerId: offerId, newStatus: newStatus})).then(response => {
        this.$makeClean();
        this.$nextTick(() => {
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
            showClass: {popup: 'animate__animated animate__fadeInDown'},
            hideClass: {popup: 'animate__animated animate__fadeOutUp'},
            timer: 2500,
            timerProgressBar: true,
            showConfirmButton: false,
            showCloseButton: true
          });
        });
      }).catch(error => {
        console.log(error);
      })
    },
    initSelect2ForStatusColumn(table) {
      // włączenie select2 dla statusu
      let select2 = $(table).find('.select-status').select2({
        theme: 'bootstrap-5',
        width: 90 + '%',
        minimumResultsForSearch: Infinity,
      });

      // Po inicjalizacji zmienić kolor tła selecta na podstawie aktualnie wybranej opcji
      select2.each(function () {
        let currentSelect = $(this);
        let currentSelectedOption = currentSelect.find(':selected');

        if (currentSelectedOption.length > 0) {
          let bgColor = currentSelectedOption.data('bg_color');
          let textColor = currentSelectedOption.data('color');

          // Zmień kolor tła całego selecta na podstawie wybranej opcji
          currentSelect.siblings('.select2-container').find('.select2-selection--single').css('background-color', bgColor);
          currentSelect.siblings('.select2-container').find('.select2-selection--single span').css('color', textColor);
        }
      });
      // zmień kolor tła po wybraniu nowej opcji
      select2.on('select2:select', function (e) {
        let selectedData = e.params.data;
        let bgColor = selectedData.element.dataset.bg_color;
        let textColor = selectedData.element.dataset.color;

        // Zmień kolor tła całego selecta na podstawie wybranej opcji
        $(this).siblings('.select2-container').find('.select2-selection--single').css('background-color', bgColor);
        $(this).siblings('.select2-container').find('.select2-selection--single span').css('color', textColor);
      });


      this.savingSelect2StatusColumn(select2, table);
      //this.changeColorsSelect2StatusColumn($(select2));
    },

    async addComment(event) {
      $('#submit-button').prop('disabled', true);
      $('#button-text').hide();
      $('#loading-spinner').show();
      event.preventDefault();
      this.loading = true;
      let offerId = $('.modal-content').data('model')
      const commentText = $('#comment-text').val();

      try {
        await axios.post(route('offers.add-comment'), {
          offer_id: offerId,
          comment: commentText
        }).catch(error => {
          console.log(error);
        });

        await this.fetchComments(offerId)
        $('#comment-text').val('');
      } catch (error) {
        console.error(error);
      } finally {
        // Re-enable the submit button and hide the loading spinner
        $('#submit-button').prop('disabled', false);
        $('#button-text').show();
        $('#loading-spinner').hide();
      }
    },
    savingSelect2StatusColumn(select2, table) {
      let self = this;
      let originSelect;
      let newValue;
      let statusContainer;
      // Pobierz wartość select-status przed zmianą-tzn. oryginalną wartość
      select2.on('select2:opening', function () {
        originSelect = $(this).val();
      });
      // Dodaj obsługę zdarzenia po zmianie selecta
      table.on('change', '.select-status', function () {
        newValue = $(this).val();
        statusContainer = $(this).closest('.status-container');

        $(this).closest('.status-container').find('.statusActions').removeClass('d-none'); // wyświetl buttony statusActions
      });
      // Dodaj obsługę zdarzenia po kliknięciu przycisku "Save"
      table.on('click', '.save-new-status', function (e) {
        e.preventDefault();
        let offerId = $(this).closest('tr').find('.offerId a').data('id');
        //let offerVersionId = $(this).closest('td').find('.status-container').data('active-offer-version-id');
        let newStatus = statusContainer.find('.select-status').val();

        statusContainer.find('.select-status').attr('disabled', true);
        statusContainer.find('.statusActions').addClass('d-none');
        statusContainer.find('.loader').removeClass('d-none');

        self.saveStatusColumn(offerId, newStatus).then(() => {
          statusContainer.find('.select-status').attr('disabled', false);
          statusContainer.find('.statusActions').addClass('d-none');
          statusContainer.find('.loader').addClass('d-none');
        });
      });

      // Dodaj obsługę zdarzenia po kliknięciu przycisku "Cancel"
      table.on('click', '.cancel-new-status', (e) => {
        e.preventDefault();
        statusContainer.find('.select-status').val(originSelect).trigger('change');
        table.find('.statusActions').addClass('d-none');
      });
    },
    changeColorsSelect2StatusColumn(selectElement) {
      // pokoloruj select2
      let selectedOption = $(selectElement[0].currentTarget);
      selectedOption.find('option').each(function (idx, option) {

        let bgColor = $(option).data('bg_color');
        let textColor = $(option).data('color');


        let target = $(selectElement[0].target);
        // Zmień kolor tła całego selecta na podstawie wybranej opcji
        target.next('.select2-container').find('.select2-selection--single').css('background-color', bgColor);

        // Zmień kolor tekstu na podstawie wybranej opcji
        target.next('.select2-container').find('.select2-selection--single span').css('color', textColor);
      })
    },
    initColumnSearch(elem, o) {
      setTimeout(() => {
        var api = elem.api();
        api.columns().eq(0).each(function (colIdx) {
          var cell = $('.filters td').eq(
              $(api.column(colIdx).header()).index()
          );
          var title_cell = $('th').eq(
              $(api.column(colIdx).header()).index()
          );

          let searchText = o.columns[colIdx].search.search
          if (searchText !== null && searchText !== undefined && typeof searchText === 'string') {
            var cleanSearchText = searchText.replace(/\(|\)/g, '');
          }

          if (cleanSearchText) {
            $(cell).html('<input type="text" value=' + cleanSearchText + ' />');
          } else {
            let title = $(title_cell).text();
            $(cell).html('<input type="text" placeholder="' + title + '" />');
          }

          // Dodaj klasy z nagłówka kolumny do komórki
          var classes = $(title_cell).attr('class').replace('sorting', '');
          $(cell).addClass(classes);

          $('input',
              $('.filters td').eq($(api.column(colIdx).header()).index()))
              .off('keyup change')
              .on('change', function (e) {
                $(this).attr('title', $(this).val());
                api.column($(this).parent().index()).search(this.value).draw();
              })
              .on('keyup', function (e) {
                e.stopPropagation();
                $(this).trigger('change');
                $(this)
                    .focus()[0]
                    .setSelectionRange(this.selectionStart, this.selectionStart);
              });
        });
      }, 50);
    },
    initFilters() {
      let self = this;

      // Get the DataTable's header row
      const headerRow = $('.dataTable').find('thead tr');

      // Create a new <tr> element to hold the input elements
      const inputRow = document.createElement('tr');


      // Iterate through the header cells (columns)
      headerRow.find('th').each(function (index) {
        // Create an input element and add it to the input row
        const inputCell = document.createElement('td');
        const input = document.createElement('input');
        input.type = 'text';
        input.placeholder = 'Filter...';

        // Append the input element to the input cell
        $(inputCell).addClass('filters').append(input).css('max-width', '30px');
        $(inputRow).addClass('filters').append(inputCell);
      });

      // Append the input row directly under the header row
      headerRow.after(inputRow);
    },
  }
}
</script>