<template>
    <AppLayout>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-six">

                <div class="widget-content">
                    <ShowProjects :projects="projects"></ShowProjects>
                </div>

            </div>
        </div>
    </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import {usePage} from "@inertiajs/vue3";
import ShowProjects from "@/Pages/Projects/Index/ShowProjects.vue";

export default {
    components: {AppLayout, ShowProjects},

    data() {
        return {
            projects: usePage().props.projects,
        }
    },
}

</script>
