<template>
    <div class="form auth-boxed">
        <div class="form-container outer">
            <div class="form-form">
                <div class="form-form-wrap">
                    <div class="form-container">
                        <div class="form-content">
                            <h1 class="">Logowanie</h1>
                            <p class="">Zaloguj się, aby kontynuować</p>

                            <form @submit.prevent="loginForm.post(route('login.post'))" class="text-start">
                                <div class="form">
                                    <div id="username-field" class="field-wrapper input">
                                        <label for="username">Email</label>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-user"
                                        >
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        <input v-model="loginForm.email" type="text" class="form-control" placeholder="mail@askla.pl"/>
                                        <div v-if="errors.email" class="text-bg-danger">
                                            {{errors.email}}
                                        </div>
                                    </div>

                                    <div id="password-field" class="field-wrapper input mb-2">
                                        <div class="d-flex justify-content-between">
                                            <label for="password">HASŁO</label>
                                            <Link :href="route('password-recovery')" class="forgot-pass-link">Zapomniałeś hasła?</Link>
                                        </div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-lock"
                                        >
                                            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                        </svg>
                                        <input v-model="loginForm.password" id="password" type="password" class="form-control" placeholder="Password"/>
                                        <div v-if="errors.email" class="text-bg-danger">
                                            {{errors.password}}
                                        </div>
                                        <svg
                                            @click="showPassword()"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            id="toggle-password"
                                            class="feather feather-eye"
                                        >
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg>
                                    </div>
                                    <div class="d-sm-flex justify-content-between">
                                        <div class="field-wrapper">
                                            <button type="submit" class="btn btn-primary">Zaloguj</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "../../../assets/sass/authentication/auth-boxed.scss";
import {Link, useForm} from "@inertiajs/vue3";

export default {
    components: {Link},

    props: {
      errors: Object
    },

    setup() {
        const loginForm = useForm({
            email: '',
            password: '',
            remember: false
        })

        return { loginForm }
    },

    methods: {
        showPassword: () => {
            let pwd = $('#password');
            if (pwd.attr('type') === "password") {
                pwd.attr('type','text');
            } else {
                pwd.attr('type','password');
            }
        }
    }
}

</script>
