<template>
  <div>
    <label class="me-1">{{ label }}: </label>
    <button type="button" class="btn btn-xs btn-outline-primary mb-1" @click="toggleVisibility('div_'+modelName)">
      <i class="bi" :class="{'bi-eye-fill':hiddenEditor, 'bi-eye-slash':!hiddenEditor}" style="font-size: 14px"></i>
    </button>

    <div :id="'div_'+modelName">
      <textarea :id="'textarea_'+modelName" :name="modelName"
                :class="{'d-none':hiddenEditor}">{{ this.localModelText }}</textarea>
    </div>

  </div>
</template>

<script>
import TinyMCEHelper from "../../../../library/js/tinymce/tinyMCEHelper.js";
import tinymce from "tinymce";

export default {
  props: {
    label: String,
    modelName: String,
    modelText: String,
    hidden: Boolean,
    language: String,
  },

  data() {
    return {
      localModelText: this.modelText,
      localLabel: this.label,
      // odwrotność this.hidden, bo po inicjacji komponentu od razu leci toggleVisibility()
      hiddenEditor: !this.hidden
    }
  },

  beforeUnmount() {
    tinymce.get('textarea_' + this.modelName).destroy();
  },

  mounted() {
    this.initTinyMCE();
    this.toggleVisibility('div_' + this.modelName);
  },

  methods: {
    initTinyMCE() {
      let additionalStyles = '';
      if(this.modelName === 'technical_data'){
        additionalStyles = 'padding-top:40px';
      }
      TinyMCEHelper.initEditor('#textarea_' + this.modelName, this, additionalStyles);
      let editor = tinymce.get('textarea_' + this.modelName);
      editor.on('change', () => {
        this.localModelText = editor.getContent();
      });
    },

    onCtrlS(event) {
      // jeśli został naciśnięty ctrl+s bezpośrednio w tinyMCE
      if (event && event.ctrlKey && event.key === 's') {
        this.$emit('ctrl-s', event);
      }
    },

    toggleVisibility(el) {
      let elem = document.getElementById(el);
      this.hiddenEditor = !this.hiddenEditor;
      if (this.hiddenEditor)
        elem.classList.add('d-none')
      else
        elem.classList.remove('d-none')
    }
  },

  watch: {
    modelText(newVal) {
      // this.localModelText = newVal;
      // tinymce.get('textarea_' + this.modelName).setContent(newVal);
    },

    localModelText() {
      let textEditor = tinymce.get('textarea_' + this.modelName).getContent();

      this.$emit('updateModel', {
        modelName: this.modelName,
        value: textEditor,
      });
    }
  }
}
</script>
