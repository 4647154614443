<template>
    <AppLayout>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-six">
                <div class="widget-heading">
                    <div class="row">
                        <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
                            <h4 class="me-3 my-0">Lista użytkowników</h4>
                            <Link :href="route('users.create')" class="btn btn-outline-primary">
                                <i class="bi bi-plus-circle"></i>&nbsp;Dodaj użytkownika
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="widget-content">
                    <div class="custom-table">
                        <div class="table-responsive">
                            <table ref="dataTables" class="table table-hover"/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import {usePage} from "@inertiajs/vue3";

export default {
    components: {AppLayout},
    name: 'DataTable',
    props: {
        users: Object,
    },

    data(){
        return {
            table: null
        }
    },

    mounted() {
        const {data} = usePage().props;
        this.user = usePage().props.user;
        this.initDataTable(data);
        this.initFilters();
    },
    beforeUnmount() {
        this.table.destroy();
    },

    methods: {
        navigateToShow(userId) {
            // Use Inertia.js to navigate to the user's show route
            this.$inertia.visit(route('users.show', {user: userId}));
        },
        initDataTable() {
            const self = this;
            const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
            this.table = $(this.$refs.dataTables).DataTable({
                colReorder: true,
                stateSave: true,
                serverSide: true,
                processing: true,
                ajax: {
                    url: '/get-users-datatables', // endpoint do pobierania danych z serwera
                    type: 'GET',
                    data: function (params) {
                        params.additional_param = 'value'; // Dodaj dodatkowe parametry, jeśli są potrzebne
                        return params;
                    },
                    dataSrc: 'data',
                },
                stateSaveCallback: function (settings, data) {
                    $.ajax({
                        url: '/save-column-data?table=users',
                        data: data,
                        type: 'POST',
                        dataType: 'json',
                        headers: {
                            'X-CSRF-TOKEN': csrfToken, // Include the CSRF token in the headers
                        },
                        "success": function () {
                            const datTab = $('.dataTable');
                            // generuj link do wersji oferty
                            let link = datTab.find('a[data-edit]');
                            link.on('click', function (event) {
                                event.preventDefault();
                                const id = $(this).data('edit');
                                self.generateEditCustomerLink(id);
                            });

                        }
                    });
                },
                stateLoadCallback: function (settings) {
                    var o;
                    $.ajax({
                        url: '/get-table-data?table=users',
                        async: false,
                        dataType: 'json',
                        success: function (json) {
                            o = json;
                        }
                    });
                    let elem = this;
                    self.initColumnSearch(elem, o)

                    return o;
                },
                columns: [
                    {
                        width: "3%",
                        data: 'id',
                        title: '#',
                        name: 'id',
                        isUnique: true,
                        render: function (data) {

                            return '<a class="text-primary" href="#" data-edit="' + data + '">' + data + '</a>';
                        },
                    },
                    {
                        data: 'first_name',
                        name: 'first_name',
                        title: 'Imię Nazwisko',
                        render: function (data, idx, row) {
                            const first_name = (row.first_name !== null && row.first_name !== undefined) ? row.first_name : '';
                            const last_name = (row.last_name !== null && row.last_name !== undefined) ? row.last_name : '';

                            return '<a class="text-primary" href="#" data-edit="' + row.id + '">' + first_name + ' ' + last_name + '</a>';
                        },
                    },
                    {
                        data: 'position',
                        name: 'position',
                        title: 'Stanowisko'
                    },
                    {
                        data: 'department.name',
                        name: 'department.name',
                        title: 'Dział'
                    },
                    {
                        data: 'email',
                        name: 'email',
                        title: 'Email'
                    },
                    {
                        data: 'user_all_roles',
                        name: 'user_all_roles',
                        title: 'Rola',
                        render: function (data, idx, row) {
                            if (data.length > 0) {

                                return data;
                            } else {
                                return ''; // Return an empty string if roles array is empty
                            }
                        },
                    },
                    {
                        data: 'phone',
                        name: 'phone',
                        title: 'Telefon'
                    },
                    {
                        data: 'is_active',
                        name: 'is_active',
                        title: 'Aktywny',
                        render: function (data, idx, row) {
                            return data ? '<i style="color:green" class="bi bi-check-circle"></i>' : '<i style="color:orangered" class="bi bi-x-circle"></i>' ;
                        },
                    },
                ],
                createdRow: function (row, data, dataIndex) {
                    // po kliknieciu w 'td' przenies do edycji customera
                    const link = $(row).find('a[data-edit]');
                    link.on('click', function (event) {
                        event.preventDefault();
                        const id = $(this).data('edit');
                        self.generateEditCustomerLink(id);
                    });
                },
            });
        },
        generateEditCustomerLink(id) {
            this.$inertia.visit(route('users.show', {user: id}));
        },
        initColumnSearch(elem, o) {
            setTimeout(() => {
                var api = elem.api();
                api.columns()
                    .eq(0)
                    .each(function (colIdx) {
                        var cell = $('.filters td').eq(
                            $(api.column(colIdx).header()).index()
                        );
                        var title_cell = $('th').eq(
                            $(api.column(colIdx).header()).index()
                        );

                        let searchText = o.columns[colIdx].search.search
                        if (searchText !== null && searchText !== undefined && typeof searchText === 'string') {
                            var cleanSearchText = searchText.replace(/\(|\)/g, '');
                        }

                        if (cleanSearchText) {
                            $(cell).html('<input type="text" value=' + cleanSearchText + ' />');
                        } else {
                            let title = $(title_cell).text();
                            $(cell).html('<input type="text" placeholder="' + title + '" />');
                        }

                        $('input',
                            $('.filters td').eq($(api.column(colIdx).header()).index()))
                            .off('keyup change')
                            .on('change', function (e) {
                                $(this).attr('title', $(this).val());
                                api.column($(this).parent().index()).search(this.value).draw();
                            })
                            .on('keyup', function (e) {
                                e.stopPropagation();
                                $(this).trigger('change');
                                $(this)
                                    .focus()[0]
                                    .setSelectionRange(this.selectionStart, this.selectionStart);
                            });
                    });
            }, 50);
        },
        initFilters() {
            const headerRow = $(this.$refs.dataTables).find('thead tr');
            const inputRow = document.createElement('tr');

            headerRow.find('th').each(function (index) {
                const inputCell = document.createElement('td');
                const input = document.createElement('input');
                input.type = 'text';
                input.placeholder = 'Filtr...';

                $(inputCell).addClass('filters').css('max-width', '30px');
                $(inputCell).addClass('filters').append(input);
                $(inputRow).addClass('filters').append(inputCell);
            });

            headerRow.after(inputRow);
        },

    },
}
</script>
