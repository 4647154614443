<template>
  <div id="preview mt-5" style="margin-top:50px;" v-if="selectedArticle">
    <h5>Szczegóły artykułu {{ selectedArticle.catalog_number }} - {{ selectedArticle.model }}</h5>

    <div class="widget-content" v-if="series.length > 0">
      <VueApexCharts height="400" type="line" :options="chartOptions" :series="series"></VueApexCharts>
    </div>
    <div class="widget-content" v-else>
      Brak historycznych cen dla tego produktu.
    </div>

  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {VueApexCharts},
  props: {
    selectedArticle: Object
  },

  data() {
    return {
      articleHistory: null,
      chartOptions: {},
      series: []
    }
  },

  watch: {
    selectedArticle: {
      immediate: true,
      handler(newArticle) {
        if (newArticle) {
          this.getHistoryData(newArticle.id);
        }
      }
    }
  },

  methods: {
    getHistoryData() {
      axios.get(route('articles.getHistory', {article: this.selectedArticle.id})).then(response => {

        // dodaj selected Article do danych
        this.articleHistory = [this.selectedArticle];

        // dodaj dane historyczne
        this.articleHistory.push(...response.data.history);

        if (this.articleHistory.length > 0) {
          this.preparePrices();
        }
      }).catch(error => {
        console.log(error);
      });
    },

    preparePrices() {
      const pricesNettoZl = this.articleHistory.map(item => isNaN(parseFloat(item.price_netto_zl)) ? 0 : parseFloat(item.price_netto_zl)).reverse();
      const pricesBruttoZl = this.articleHistory.map(item => isNaN(parseFloat(item.price_brutto_zl)) ? 0 : parseFloat(item.price_brutto_zl)).reverse();
      const pricesNettoEur = this.articleHistory.map(item => isNaN(parseFloat(item.price_netto_eur)) ? 0 : parseFloat(item.price_netto_eur)).reverse();
      const pricesBruttoEur = this.articleHistory.map(item => isNaN(parseFloat(item.price_brutto_eur)) ? 0 : parseFloat(item.price_brutto_eur)).reverse();
      const createdDates = this.articleHistory.map(item => this.formatDate(item.updated_at)).reverse();


      this.series = [
        {
          name: 'Netto PLN',
          data: pricesNettoZl,
          color: '#0070e5',
        },
        {
          name: 'Brutto PLN',
          data: pricesBruttoZl,
          color: '#95bcf1'
        },
        {
          name: 'Netto EUR',
          data: pricesNettoEur,
          color: '#ef0202'
        },
        {
          name: 'Brutto EUR',
          data: pricesBruttoEur,
          color: '#f8a5a5'
        }
      ];

      this.chartOptions = {
        chart: {
          type: 'line',
        },
        stroke: {
          width: 2,

        },
        xaxis: {
          categories: createdDates,
          title: {
            text: 'Data'
          },
        },
        yaxis: {
          title: {
            text: 'Cena'
          }
        },
        title: {
          text: 'Ceny historyczne',
          align: 'center'
        }
      };
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są od 0 do 11, więc dodajemy 1
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    }
  }
}
</script>

<style scoped>

</style>