<template>
    <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <template v-for="(breadcrumb, index) in breadcrumbs">
                <template v-if="breadcrumb.parents && breadcrumb.parents.length > 0 && shouldDisplayBreadcrumb(breadcrumb)">
                    <li v-for="(parent, idx) in breadcrumb.parents" class="breadcrumb-item" :key="index">
                        <a href="javascript:">{{ parent }}</a>
                    </li>
                </template>
                    <li class="breadcrumb-item active" aria-current="page" v-if="shouldDisplayBreadcrumb(breadcrumb)">
                        <span>{{ breadcrumb.label }}</span>
                    </li>
            </template>
        </ol>
    </nav>
</template>

<script>
import {reactive, watch} from 'vue';
import {usePage} from "@inertiajs/vue3";

export default {
    name: 'Breadcrumbs',
    setup() {
        const breadcrumbs = reactive([]);

        watch(
            () => usePage().props.breadcrumbs,
            (newBreadcrumbs) => {
                breadcrumbs.splice(0, breadcrumbs.length, ...newBreadcrumbs);
            }
            , {
                immediate: true,
                deep: true
            }
        );

        return {breadcrumbs};
    },

    methods: {
        shouldDisplayBreadcrumb(breadcrumb) {
            const currentUrl = window.location.origin + window.location.pathname;
            return breadcrumb.url === currentUrl;
        },
    }
};
</script>
