<template>
  <button type="button" class="btn btn-sm btn-outline-info" data-bs-toggle="modal" data-bs-target="#modalDeliverers">
    <i class="bi bi-truck"></i>&nbsp; Dostawcy
  </button>


  <!-- Modal -->

  <div class="modal fade" id="modalDeliverers" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
       aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Dostawcy</h5>
          <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <form class="pb-5" style="border-bottom: 1px solid #ddd" @submit.prevent="createNewSupplier()">
            <div class="row mb-3">
              <div v-if="!showAddNewCategorySupplier" class="col">
                <label class="form-label">Kategoria*</label>
                <Vueform>
                  <SelectElement :native="false" :object="true" :items="customerCategories" name="category"
                                 :search="true" label-prop="name" value-prop="id" autocomplete="false" type="search"
                                 @change="changeSelectCategory" required>
                  </SelectElement>
                </Vueform>
              </div>

              <div class="col">
                <label class="form-label">Dostawca*</label>
                <select class="form-select form-select-sm" v-model="newSupplier.deliverer_no" required>
                  <option value="1">Dostawca 1</option>
                  <option value="2">Dostawca 2</option>
                  <option value="3">Dostawca 3</option>
                </select>
              </div>

              <div class="col">
                <label class="form-label">Nazwa dostawcy*</label>
                <Vueform>
                  <SelectElement :native="false" :object="true" :items="filteredCustomersDeliverers" name="deliverer"
                                 :search="true" label-prop="short_name" value-prop="id"
                                 @change="changeSelectSupplier">
                  </SelectElement>
                </Vueform>
              </div>

              <div class="col">
                <label class="form-label">&nbsp;</label>
                <button type="submit" class="d-block btn btn-sm btn-primary">Zapisz</button>
              </div>
            </div>
            <div id="errorMessageCreateNewSupplier" class="text-danger" style="display: none">Wypełnij wszystkie pola
            </div>
          </form>

          <table class="mt-5 table table-bordered">
            <thead>
            <tr>
              <th>Kategoria</th>
              <th>Dostawca 1</th>
              <th>Dostawca 2</th>
              <th>Dostawca 3</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(deliverers, category) in projectSuppliers">
              <td> {{ category }}</td>
              <td v-for="key in [1, 2, 3]" :key="key">
                <template v-if="deliverers[key]">
                  <div v-for="delivererData in deliverers[key]" :key="delivererData.id">
                    <form :id="'deliverer_'+delivererData.id" class="mb-2"
                          @submit.prevent="deleteProjectSupplier(delivererData.customer.short_name, delivererData.id)">
                      {{ delivererData.customer.short_name }}
                      <button type="submit" class="float-end ms-1 btn btn-xs btn-danger" :value="delivererData.id">
                        <i class="bi bi-x-square"></i>
                      </button>
                    </form>
                  </div>
                </template>

                <template v-else>
                  <td></td>
                </template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";

export default {
  emits: ['projectSuppliersUpdated'],
  props: {
    project: Object,
    projectSuppliers: Object,
    customerCategories: Object,
    customersDeliverers: Object,
    updateProjectSuppliers: {
      type: Function
    }
  },
  data() {

    return {
      showAddNewCategorySupplier: false,
      filteredCustomersDeliverers: this.customersDeliverers,
      newSupplier: {
        category: null,
        deliverer_no: null,
        supplier: null,
      },
    }
  },

  methods: {
    f_showAddNewCategorySupplier() {
      this.showAddNewCategorySupplier = this.showAddNewCategorySupplier === false;
    },

    changeSelectCategory(newValue) {
      this.newSupplier.category = newValue.id;

      if (!newValue) {
        this.filteredCustomersDeliverers = this.customersDeliverers;
      } else {
        this.filteredCustomersDeliverers = this.customersDeliverers.filter(customer =>
            customer.customer_categories.some(category => category.id === newValue.id)
        )
      }
    },

    changeSelectSupplier(newValue) {
      this.newSupplier.supplier = newValue.id;
    },

    deleteProjectSupplier(delivererName, delivererId) {
      const self = this;
      Toast.fire({
        toast: false,
        icon: 'warning',
        title: 'Usuwanie dostawcy',
        html: 'Jesteś pewny że chcesz usunąć ' + delivererName + ' z dostawców?',
        showDenyButton: true,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        position: 'center',
        showConfirmButton: true,
        denyButtonText: 'Nie',
        confirmButtonText: 'Tak',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(route('project.project-supplier.softDelete', {projectSupplier: delivererId})).then(response => {
            Toast.fire({
              toast: true,
              icon: response.data.icon,
              title: response.data.title,
              html: response.data.message,
              showClass: {popup: 'animate__animated animate__fadeInDown'},
              hideClass: {popup: 'animate__animated animate__fadeOutUp'},
              timer: 2500,
            })

            document.getElementById('deliverer_' + delivererId).remove();

            self.$emit('projectSuppliersUpdated');

          }).catch(error => {
            console.log(error);
          })
        }
      })
    },

    createNewSupplier() {
      const self = this;
      if (!this.newSupplier.category && !this.newSupplier.deliverer ) {
        $('#errorMessageCreateNewSupplier').show();
        return;
      }

      axios.post(route('project.project-supplier.add', {
        project: this.project,
        category: this.newSupplier.category,
        deliverer_no: this.newSupplier.deliverer_no,
        supplier: this.newSupplier.supplier
      })).then(response => {
        Toast.fire({
          toast: true,
          icon: response.data.icon,
          title: response.data.title,
          html: response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })

        self.$emit('projectSuppliersUpdated');

      }).catch(error => {
        console.log(error);
      })
    },
  }
}
</script>
