import $ from 'jquery';

import 'datatables.net';
import {sortable} from "v-tables-3/compiled/mixins/methods.js";
import "../assets/sass/tables/datatables.scss";
import 'datatables.net-autofill-dt';
// import 'datatables.net-select';
import 'datatables.net-buttons-dt';
import 'datatables.net-colreorder-dt';
import 'datatables.net-fixedcolumns-dt';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-keytable-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-rowreorder-dt';

$.extend(true, $.fn.dataTable.defaults, {
    language: {
        processing: 'Wczytywanie...',
        search: 'Szukaj:',
        lengthMenu: 'Pokaż _MENU_ pozycji',
        info: 'Wyświetlono _START_-_END_ z _TOTAL_ pozycji',
        infoEmpty: 'Wyświetlono 0 z 0 pozycji',
        infoFiltered: '(filtrowanie spośród _MAX_ dostępnych pozycji)',
        infoPostFix: '',
        loadingRecords: 'Ładowanie...',
        zeroRecords: 'Nie znaleziono pasujących pozycji',
        emptyTable: 'Brak dostępnych danych',
        paginate: {
            first: 'Pierwsza',
            previous: 'Poprzednia',
            next: 'Następna',
            last: 'Ostatnia'
        },
        aria: {
            sortAscending: ': aktywuj, by posortować kolumnę rosnąco',
            sortDescending: ': aktywuj, by posortować kolumnę malejąco'
        }
    }
});
