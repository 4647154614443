<template>
  <div class="widget widget-sales-category" style="min-height: 460px;">
    <div class="widget-heading">
      <h5>Progres - plik projektu</h5>
      <div>
        <ejs-daterangepicker
            id="projectFileProgressId"
            placeholder="Wybierz zakres dat"
            :value="dateRange"
            :format="dateFormat"
            @change="onDateChange"
        ></ejs-daterangepicker>
      </div>
    </div>

    <template v-if="isLoading">
      <div class="text-center my-3">
        <div class="spinner-border spinner-border-reverse align-self-center loader-sm text-danger">Ładowanie...</div>
      </div>
    </template>

    <template v-else>
      <div class="widget-content" v-if="statusesCounts.length > 0">
        <vue-apexcharts height="650" type="donut" :options="sales_options" :series="statusesCounts"></vue-apexcharts>
      </div>
    </template>

  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import store from "../../../store";
import axios from "axios";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import vueApexcharts from "vue3-apexcharts";
import {DateRangePickerComponent} from "@syncfusion/ej2-vue-calendars";

export default {
  components: {'ejs-daterangepicker': DateRangePickerComponent, vueApexcharts},
  props: {
    projectId: Number,
  },

  data() {
    const today = new Date();
    const _startDate = new Date(today);
    _startDate.setMonth(_startDate.getMonth() - 1); // Ustaw na jeden miesiąc przed dzisiejszą datą
    const _endDate = new Date(today); // Ustaw dzisiejszą datę

    return {
      isLoading: false,
      dateRange: [_startDate, _endDate], // Początkowy zakres dat
      dateFormat: 'dd/MM/yyyy', // Format daty
      sales_options: {},
      statusesCounts: [],
      statusesNames: [],
      statusesColors: [],
      sumValues: 0,
      is_dark: store.state.is_dark_mode
    }
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    onDateChange(args) {
      this.dateRange = args.value;
      this.fetchData();
    },

    fetchData() {
      this.isLoading = true;
      let self = this;
      axios.post(route("graphs.projectFileProgress"), {projectId: this.projectId, dates: this.dateRange})
          .then(response => {
            this.statusesCounts = response.data.statusesCounts;
            this.statusesNames = response.data.statusesNames;
            this.statusesColors = response.data.statusesColors;
            this.sumValues = response.data.sum;

            this.sales_options = {
              chart: {},
              dataLabels: {
                enabled: true,
                textAnchor: 'start',
                formatter: function (val, opt) {
                  let label = opt.w.globals.labels[opt.seriesIndex];
                  let value = val.toFixed(2) + "%";
                  return label + ': ' + value;
                },
              },
              legend: {
                position: "right",
                horizontalAlign: "center",
                fontSize: "30px",
                markers: {width: 25, height: 25},
                height: 600,
                offsetY: 20,
                itemMargin: {horizontal: 20, vertical: 10},
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: "55%",
                    background: "transparent",
                    labels: {
                      show: true,
                      name: {
                        show: true,
                        fontSize: "25px",
                        offsetY: -10,
                      },
                      value: {
                        show: true,
                        fontSize: "30px",
                        fontFamily: "Arimo, sans-serif",
                        color: this.is_dark ? "#bfc9d4" : undefined,
                        offsetY: 16,
                        formatter: function (val) {
                          if (self.sumValues === 0) {
                            return 'B/D';
                          } else {
                            const percentValue = (val / self.sumValues) * 100;
                            return `${val} (${percentValue.toFixed(2)}%)`;
                          }
                        },
                      },
                      total: {
                        show: true,
                        label: "Suma",
                        color: "#888ea8",
                        fontSize: "40px",
                        formatter: function (w) {
                          return w.globals.seriesTotals.reduce(function (a, b) {
                            return a + b;
                          }, 0);
                        },
                      },
                    },
                  },
                },
              },
              expandOnClick: this.is_dark,
              colors: this.statusesColors,
              labels: this.statusesNames,
              responsive: [],
            };
          })
          .catch(error => {
            console.error("Error:", error);
          }).finally(() => {
        this.isLoading = false;
      });
    }
  },
};
</script>
