<script setup xmlns="http://www.w3.org/1999/html">
import {useForm} from "@inertiajs/vue3";
import AppLayout from "../../../Layouts/App.vue";
import {ref} from "vue";

const {
    offersTemplatePL,
    offersTemplateEN,
    offersTemplateDE,
    errors
} =
    defineProps({offersTemplatePL: Object, offersTemplateEN: Object, offersTemplateDE: Object, errors: Object})

let formPL = useForm({
    ...offersTemplatePL,
})
let formEN = useForm({
    ...offersTemplateEN,
});
let formDE = useForm({
    ...offersTemplateDE,
});
</script>

<template>
    <AppLayout>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-six">
                <div class="widget-heading">
                    <div class="row">
                        <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
                        </div>
                    </div>
                </div>

                <div class="widget-content">

                    <div v-if="$page.props.errors">
                        <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
                            <li class="alert alert-danger">{{ error }}</li>
                        </ul>
                    </div>

                    <div class="float-end">
                        <select class="form-select form-select-sm" v-model="templateLang">
                            <option value="pl">PL</option>
                            <option value="en">EN</option>
                            <option value="de">DE</option>
                        </select>
                    </div>

                    <h4 class="me-3 my-0">Edycja szablonu oferty {{ templateLang.toUpperCase() }}</h4>

                    <OfferTemplatePL v-if="templateLang === 'pl'" :form="formPL" :offerTemplate="offersTemplate" :error="error" :errors="errors"></OfferTemplatePL>
                    <OfferTemplateEN v-if="templateLang === 'en'" :form="formEN" :offerTemplate="offersTemplate" :error="error" :errors="errors"></OfferTemplateEN>
                    <OfferTemplateDE v-if="templateLang === 'de'" :form="formDE" :offerTemplate="offersTemplate" :error="error" :errors="errors"></OfferTemplateDE>

                </div>
            </div>
        </div>
    </AppLayout>
</template>

<script>
import {ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import OfferTemplatePL from "@/Pages/Offers/Templates/OfferTemplatePL.vue";
import OfferTemplateEN from "@/Pages/Offers/Templates/OfferTemplateEN.vue";
import OfferTemplateDE from "@/Pages/Offers/Templates/OfferTemplateDE.vue";


export default {
    components: {OfferTemplatePL, OfferTemplateEN, OfferTemplateDE},
    props: {
        offersTemplate: Object,
        error: Object,
        errors: Object
    },
    data() {
        return {
            templateLang: 'pl',
            loading: false
        }
    }
}
</script>
