<template>

  <button type="button" class="btn btn-xs btn-outline-success" data-bs-toggle="modal"
          :data-bs-target="'#addSupplierModal_'+elemId">
    <span class="me-1">Dodaj</span> <i class="bi bi-plus-circle"></i>
  </button>

  <!-- Modal -->
  <div class="modal fade" :id="'addSupplierModal_'+elemId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <form class="modal-content" @submit.prevent="saveHandle">
        <div class="modal-header">
          <h4 class="modal-title" id="modelTitleId">{{ category.name }}</h4>
          <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group mb-3">
            <Vueform>
              <SelectElement :label="'Dostawca ' + delivererNo" :items="filteredCustomers" :native="false"
                             name="customer"
                             label-prop="short_name"
                             @change="changeDeliverer"
                             value-prop="id" class="w-100">
              </SelectElement>
            </Vueform>
          </div>
          <span class="text-danger" v-if="form.errors?.deliverer">{{ form.errors.deliverer }}</span>
        </div>
        <div class="modal-footer">
          <button type="button" :id="'btnClose_'+elemId" class="btn btn-secondary me-auto" data-bs-dismiss="modal">
            Zamknij
          </button>
          <button type="submit" class="btn btn-primary" :disabled="form.processing || !form.deliverer">Zapisz</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
.w-100 {
  width: 100%;
}
</style>
<script>

import {useForm} from "@inertiajs/vue3";

export default {
  props: {
    customers: Object,
    category: Object,
    delivererNo: Number,
    elemId: Number
  },

  data() {
    const filteredCustomers = this.customers.filter(customer =>
        customer.customer_categories.some(category => category.id === this.category.id)
    );
    return {
      filteredCustomers,
      form: useForm({
        deliverer: null,
        category: this.category,
        delivererNo: this.delivererNo
      })
    }
  },

  methods: {
    changeDeliverer(newVal) {
      this.form.deliverer = newVal;
    },

    saveHandle() {
      this.form.post(route('projects.default-suppliers.store'), {
        onSuccess: (resp) => {
          this.form.deliverer = null;
          this.successMsg(resp);
          const btnCloseId = 'btnClose_' + this.elemId;
          let elem = document.getElementById(btnCloseId);
          if (elem) elem.click();
        },
        onError: (error) => {
          console.log(error);
        }
      });
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp.props.flash.success,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    }
  }
}

</script>
