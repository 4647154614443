<template>

  <button type="button" class="btn btn-sm btn-outline-info" data-bs-toggle="modal"
          :data-bs-target="'#modalHistory_'+model" @click="openModal">
    <i class="bi bi-clock-history"></i>&nbsp; Historia zmian
  </button>

  <!-- Modal -->
  <div class="modal fade" :id="'modalHistory_'+model" tabindex="-1" role="dialog" @click.self="closeModal"
       aria-labelledby="modelTitleId" aria-hidden="true" ref="modalHistory">
    <div class="modal-dialog modal-full-width" role="document">
      <div class="modal-content" :ref="'modalContent_'+model">
        <div class="modal-header">
          <h5 class="modal-title">Ostatnie zmiany</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body table-responsive text-start">
          <ejs-grid gridLines="Both" height='100%'
                    :allowTextWrap='true'
                    :dataSource="histories"
                    :allowPaging="true"
                    :pageSettings='{pageSize: 15}'
                    :allowSorting='true'
                    :allowFiltering='true'
                    :filterSettings="{type: 'Excel'}"
                    :allowReordering='true'
                    :allowResizing='true'
                    :showColumnChooser='true'
                    :allowSelection='true'
                    :enablePersistence='false'
          >
            <e-columns>
              <e-column field='original_id' headerText='ID' width="85" type="numeric"></e-column>
              <e-column field="column" headerText="Kolumna" type="string"></e-column>
              <e-column field="old_value" headerText="Stara wartość" type="string"></e-column>
              <e-column field="new_value" headerText="Nowa wartość" type="string"></e-column>
              <e-column field="created_at" headerText="Data aktualizacji" type="string"></e-column>
              <e-column field="created_by_name" headerText="Aktualizaował" type="string"></e-column>

            </e-columns>
          </ejs-grid>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">Zamknij</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";

import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
} from '@syncfusion/ej2-vue-grids';
import {ref} from "vue";

export default {
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },
  props: {
    project: Object,
    model: String
  },

  setup() {
    let isModalOpen = ref(false);

    const openModal = () => {
      isModalOpen.value = true;
    }

    return {isModalOpen}
  },

  data() {
    return {
      isLoading: false,
      infiniteId: 1,
      histories: [],
      params: [],
    };
  },

  provide: {
    grid: [Page, Sort, Filter, Reorder, Resize, ColumnChooser]
  },

  watch: {
    isModalOpen(newValue) {
      if (newValue === true) {
        this.getHistoryForProjects()
      }
    }
  },

  methods: {

    getHistoryForProjects() {
      const self = this;
      self.isLoading = true;

      axios.post(route("projects.get-history-for-model"), {
        project: this.project,
        stringModel: this.model,
        params: this.params,
      })
          .then((response) => {
            self.histories = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    openModal() {
      this.isModalOpen = true;
    },

    closeModal(model) {
      this.isModalOpen = false;
      this.histories = [];
      // Usunięcie nasłuchiwania zdarzenia hidden.bs.modal
      let refModal = 'modalHistory_' + model;
      $(this.$refs[refModal]).off("hidden.bs.modal", () => this.closeModal(model));
    },
  },
};
</script>
