import store from "@/store/index.js";

// ustawienie globalnej funkcji makeDirty
// potrzebne aby można było blokować przy niezapisanych danych przejście do innej strony

export default {
    install(app) {
        // Globalna funkcja makeDirty
        app.config.globalProperties.$makeDirty = function () {
            store.commit('setDirty', true);
        };

        // Globalna funkcja makeClean
        app.config.globalProperties.$makeClean = function () {
            store.commit('setDirty', false);
        };
    }
};
