<script setup>

</script>

<template>
  <button type="button" class="btn btn-sm btn-outline-info" data-bs-toggle="modal" data-bs-target="#modalDecisions">
    <i class="bi bi-truck"></i>&nbsp; Decyzje
  </button>


  <!-- Modal -->

  <div class="modal fade" id="modalDecisions" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
       aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Decyzje</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <button v-if="!showAddNewCategoryDecision" type="button" class="btn btn-outline-primary"
                  @click="f_showAddNewCategoryDecision()">Dodaj kategorię
          </button>
          <button v-else type="button" class="btn btn-outline-primary" @click="f_showAddNewCategoryDecision()">Użyj
            istniejącej
          </button>

          <form class="pb-5" style="border-bottom: 1px solid #ddd" @submit.prevent="createNewDecision()">
            <div class="row mb-3">
              <div v-if="!showAddNewCategoryDecision" class="col">
                <label class="form-label">Kategoria*</label>
                <select id="decisionKategory" class="form-select form-select-sm" v-model="newDecision.category"
                        @change="changeCategory()" required>
                  <option v-for="(category, index) in decisions" :value="index">{{ index }}</option>
                </select>
              </div>
              <div v-else class="col">
                <label class="form-label">Nowa kategoria*</label>
                <input class="form-control form-control-sm" type="text" v-model="newDecision.newDecisionCategory"
                       placeholder="Wpisz nową kategorię">
              </div>
              <div class="col">
                <label class="form-label">Nazwa decyzji*</label>
                <input class="form-control form-control-sm" type="text" v-model="newDecision.name" required>
              </div>

              <div class="col">
                <label class="form-label">Rodzic (opcjonalne)</label>
                <select id="decisionParent" class="form-select form-select-sm" v-model="newDecision.parent">
                  <option v-for="decision in selParents" :value="decision.id">{{ decision.name }}</option>
                </select>
              </div>

              <div class="col">
                <label class="form-label">&nbsp;</label>
                <button type="submit" class="d-block btn btn-sm btn-primary">Zapisz nową kategorię</button>
              </div>
            </div>
            <div id="errorMessageCreateNewDecision" class="text-danger" style="display: none">Wypełnij wszystkie pola
            </div>
          </form>

          <table class="mt-5 table table-bordered">
            <thead>
            <tr>
              <th>Kategoria</th>
              <th>Decyzja</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(decisions) in projectDecisions">
              <td>
                {{ decisions[Object.keys(decisions)[0]][0].categoryDisplayName }}
              </td>
              <td v-for="decisionData in decisions">
                <div v-for="decision in decisionData">
                  <div v-if="!decision.parent">
                    <form :id="'f_decision_'+decision.id" class="mb-2"
                          @submit.prevent="deleteProjectDecision(decision.name, decision.id)">
                      {{ decision.name }}
                      <button type="submit" class="float-end btn btn-xs btn-danger" :value="decision.id">
                        <i class="bi bi-x-square"></i>
                      </button>
                    </form>
                  </div>

                  <div v-for="decisionChild in decisionData">
                    <div v-if="decisionChild.parent && decisionChild.parent === decision.id">
                      <form :id="'f_decision_'+decisionChild.id" class="ps-4 mb-2"
                            @submit.prevent="deleteProjectDecision(decisionChild.name, decisionChild.id)">
                        - {{ decisionChild.name }}
                        <button type="submit" class="float-end btn btn-xs btn-danger" :value="decisionChild.id">
                          <i class="bi bi-x-square"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";

export default {
  props: {
    projectDecisions: Object,
    updateProjectDecisions: {type: Function}
  },
  data() {
    let decisions = this.projectDecisions;
    return {
      decisions,
      showAddNewCategoryDecision: false,
      newDecision: {
        newDecisionCategory: null,
        category: '',
        decision: '',
        name: '',
        parent: '',
      },
      selParents: []
    }
  },

  methods: {

    f_showAddNewCategoryDecision() {
      this.showAddNewCategoryDecision = this.showAddNewCategoryDecision === false;
    },

    changeCategory() {
      this.newDecision.newDecisionCategory = null;
      this.selParents = Object(this.decisions[event.target.value][""]).map((arr) => ({
        id: arr['id'],
        name: arr['name'],
      }));
    },

    deleteProjectDecision(decisionName, decisionId) {
      const self = this;
      Toast.fire({
        toast: false,
        icon: 'warning',
        title: 'Usuwanie decyzję',
        html: 'Jesteś pewny że chcesz usunąć ' + decisionName + ' z decyzji?',
        showDenyButton: true,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        position: 'center',
        showConfirmButton: true,
        denyButtonText: 'Nie',
        confirmButtonText: 'Tak',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(route('project.project-decision.softDelete', {projectDecision: decisionId})).then(response => {
            Toast.fire({
              toast: true,
              icon: response.data.icon,
              title: response.data.title,
              html: response.data.message,
              showClass: {popup: 'animate__animated animate__fadeInDown'},
              hideClass: {popup: 'animate__animated animate__fadeOutUp'},
              timer: 2500,
            })

            document.getElementById('decision_' + decisionId).remove();

            self.updateProjectDecisions();

          }).catch(error => {
            console.log(error);
          })
        }
      })
    },

    createNewDecision() {
      const self = this;
      if ((!this.newDecision.category && !this.newDecision.newDecisionCategory) || !this.newDecision.name) {
        $('#errorMessageCreateNewDecision').show();
        return;
      }

      axios.post(route('project.project-decision.add', {
        newDecisionCategory: this.newDecision.newDecisionCategory,
        category: this.newDecision.category,
        name: this.newDecision.name,
        parent: this.newDecision.parent,
      })).then(response => {
        Toast.fire({
          toast: true,
          icon: response.data.icon,
          title: response.data.title,
          html: response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })

        self.updateProjectDecisions();

      }).catch(error => {
        console.log(error);
      })
    },
  }
}
</script>
