<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">

        <projectMenu :project="project"></projectMenu>

        <div class="widget-content">
          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="text-end mb-3">
            <ProjectsHistory
                :project="project"
                :model="'CostHistory'">
            </ProjectsHistory>
          </div>


          <div class="card-body">
            <div class="row mt-3">
              <div class="" v-if="costsData">
                <h4>Koszty</h4>

                <div v-if="isLoading">
                  <div class="position-absolute w-100 h-100 text-center" style="z-index: 9">
                    <div class="spinner-border text-danger me-2 align-self-center loader-lg" style="top:20%"></div>
                  </div>
                </div>

                <div :style="{ opacity: isLoading ? '0.4' : '1' }">
                  <ejs-grid id="GridCosts" ref="gridCosts" :key="costsGridKey" gridLines="Both" height='100%'
                            class="top-margin"
                            :keyPressed="onKeyDown"
                            :dataSource="costsData" :editSettings="editSettings"
                            :allowPaging="true" :pageSettings='pageSettings'
                            :allowSorting='true' :allowFiltering='true'
                            :filterSettings="filterOptions" :toolbar='toolbar'
                            :allowReordering='true' :allowResizing='true'
                            :showColumnChooser='true' :selectionSettings='selectionOptions'
                            :allowSelection='true'
                            :enablePersistence="false"
                            :actionComplete="actionCostsComplete"
                            :cellSave="onCostsCellSave"
                            :toolbarClick="toolbarCostsClickE"
                            :batchCancel="onCancelCostsAction"
                            :dataBound="dataBound">
                    <e-columns>
                      <e-column field='id' headerText='ID' width="85" :allowEditing="false"
                                isPrimaryKey="true"></e-column>
                      <e-column field="number" headerText="Numer" type="string"></e-column>
                      <e-column field="quantity" headerText="Ilość" type="number"></e-column>
                      <e-column field="description" headerText="Opis" type="string"></e-column>
                      <e-column field="supplier" headerText="Dostawca" type="string"></e-column>
                      <e-column field="price" headerText="Cena" type="number"></e-column>
                      <e-column field="currency" headerText="Waluta" type="string" editType='dropdownedit'
                                :edit="currencyParams"></e-column>
                      <e-column field="price_item_pln" headerText="Cena jedn. PLN" type="string" editType='numericedit'
                                :edit='priceParams' format="C2"></e-column>
                      <e-column field="price_item_eur" headerText="Cena jedn. EUR" type="string" editType='numericedit'
                                :edit='priceParams'></e-column>
                      <e-column field="total_pln" headerText="Wartość PLN" :allowEditing="false"
                                type="string"></e-column>
                      <e-column field="total_eur" headerText="Wartość EUR" :allowEditing="false"
                                type="string"></e-column>
                      <e-column field="order" headerText="Zamówienie" type="string"></e-column>
                      <e-column field="team" headerText="Zespół" type="string"></e-column>
                      <e-column field="category" autocomplete headerText="Kategoria" type="string"></e-column>
                      <e-column field="created_by_name" headerText="Stworzył" :allowEditing="false"></e-column>
                    </e-columns>
                  </ejs-grid>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="mb-2">
                <ProjectsHistory
                    :project="project"
                    :model="'BudgetHistory'">
                </ProjectsHistory>
              </div>

              <div class="col-sm-12 col-md-6" v-if="budgetData">
                <h4>Przeznaczony budżet</h4>

                <div v-if="isLoadingBudget">
                  <div class="position-absolute w-100 h-100 text-center" style="z-index: 9">
                    <div class="spinner-border text-danger me-2 align-self-center loader-lg" style="top:20%"></div>
                  </div>
                </div>

                <div :style="{ opacity: isLoadingBudget ? '0.4' : '1' }">
                  <ejs-grid id="GridBudgetProject" ref="gridBudget" :key="budgetProjectGridKey" gridLines="Both"
                            class="top-margin"
                            height='200px'
                            :keyPressed="onKeyDownBudget"
                            :dataSource="budgetData" :editSettings="editSettings"
                            :allowPaging="true" :pageSettings='pageSettings'
                            :allowSorting='true' :allowFiltering='true'
                            :filterSettings="filterOptions" :toolbar='toolbar'
                            :allowReordering='true' :allowResizing='true'
                            :showColumnChooser='true' :selectionSettings='selectionOptions'
                            :allowSelection='true'
                            :enablePersistence="true"
                            :actionComplete="actionBudgetComplete"
                            :dataBound="dataBound"
                            :cellSave="onBudgetCellSave"
                            :toolbarClick="toolbarBudgetClickE"
                            :batchCancel="onCancelBudgetAction">
                    <e-columns>
                      <e-column field='id' headerText='ID' width="85" :allowEditing="false"
                                isPrimaryKey="true"></e-column>
                      <e-column field="budget" headerText="Budżet" type="number"></e-column>
                      <e-column field="category" autocomplete headerText="Kategoria" type="string"></e-column>
                      <e-column field="created_by_name" headerText="Stworzył" :allowEditing="false"
                                type="string"></e-column>
                    </e-columns>
                  </ejs-grid>
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <h4>Koszty łącznie dla kategorii</h4>
                <table class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>Koszt</th>
                    <th>Kategoria</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="overallCosts" v-for="category in overallCosts">
                    <td class="text-end">{{ category.costs }} PLN</td>
                    <td>{{ category.category }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row mt-5">
              <h4>Podsumowanie</h4>
            </div>

            <GraphProjectBudgetsCosts :budgetData="budgetData" :overallCosts="overallCosts"></GraphProjectBudgetsCosts>
          </div>
        </div>
      </div>
    </div>

  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import projectMenu from "@/Pages/Projects/parts/projectMenu.vue";

import ProjectsHistory from "@/Pages/Projects/Components/ProjectsHistory.vue";

import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Edit, ExcelExport,
  Filter,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar
} from '@syncfusion/ej2-vue-grids';

import {MultiSelect} from '@syncfusion/ej2-vue-dropdowns';

import GraphProjectBudgetsCosts from "@/Pages/Projects/Components/graphs/GraphProjectBudgetsCosts.vue";

export default {
  components: {
    GraphProjectBudgetsCosts,
    ProjectsHistory, AppLayout, projectMenu, MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },
  props: {
    project: Object,
    users: Object,
    costs: Object,
    budget: Object
  },
  setup(page) {
    let costsData = page.costs;
    if (costsData.length === 0)
      costsData.push({});

    return {
      costsData
    }
  },

  data() {
    let overallCosts = this.calculateOverallCosts()
    return {
      isLoading: false,
      isLoadingBudget: false,
      budgetData: this.budget,
      overallCosts,
      costsGridKey: 0,
      budgetProjectGridKey: 0,
      pageSettings: this.getPageSettings(),
      filterOptions: this.getFilterOptions(),
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: this.getSelectionOptions(),
      currencyParams: this.getCurrencyParams(),
      priceParams: {params: {decimals: 2, value: 0}}
    }
  },

  provide: {
    grid: [Page, Edit, Toolbar, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport]
  },

  methods: {
    route,
    isRouteActive(routeName) {
      return route().current() === routeName;
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        // zapisz
        let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
        let buttons = toolbar.querySelectorAll('.e-toolbar-item');
        let updateButton = buttons[2].querySelector('button');
        if (updateButton) {
          updateButton.click();
        }
      }
    },

    onKeyDownBudget: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        // zapisz
        let toolbar = document.getElementsByClassName('e-toolbar-left')[1];
        let buttons = toolbar.querySelectorAll('.e-toolbar-item');
        let updateButton = buttons[2].querySelector('button');
        if (updateButton) {
          updateButton.click();
        }
      }
    },

    getToolbar() {
      return [
        "Add",
        {text: 'Usuń', prefixIcon: 'e-delete', id: 'delete_btn'},
        "Update",
        "Cancel",
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    deleteCostsRow() {
      let self = this;
      this.isLoading = true;
      const scrollPosition = window.scrollY;
      let gridInstance = document.getElementById('GridCosts').ej2_instances[0];
      const selectedIndexes = gridInstance.getSelectedRowCellIndexes();
      let deletedRows = selectedIndexes.map(o => gridInstance.currentViewData[o.rowIndex]);
      deletedRows = deletedRows.map(obj => obj.id); // zmiana na array tylko z id

      selectedIndexes.forEach(o => {
        gridInstance.deleteRecord('id', gridInstance.currentViewData[o.rowIndex]);
      });

      axios.delete(route('projects.show.budget.deleteCosts', {
        project: this.project,
        costs: JSON.stringify(deletedRows)
      })).then(response => {
        if (response.status === 200) {
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
          });
        } else {
          console.log(response);
          Toast.fire({
            position: 'center',
            toast: false,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
            timer: false,
            showCloseButton: true
          });
        }
        self.updateCostsRows();
        this.$nextTick(() => {
          window.scrollTo(0, scrollPosition);
        });
      }).catch(error => {
        console.log(error);
      });
    },


    dataBound() {
      let gridInstance = document.getElementById('GridCosts').ej2_instances[0];
      let gridInstance2 = document.getElementById('GridBudgetProject').ej2_instances[0];
      gridInstance.hideScroll();
      gridInstance2.hideScroll();
    },

    calculateOverallCosts() {
      let overallCosts = {};
      this.costs.forEach(function (cost, idx) {
        if (overallCosts[cost.category]) {
          // Jeśli kategoria już istnieje, dodaj koszt
          overallCosts[cost.category].costs += cost.total_pln;
        } else {
          // Jeśli kategoria nie istnieje, utwórz nowy wpis
          overallCosts[cost.category] = {
            category: cost.category,
            costs: cost.total_pln,
          };
        }
      })
      return overallCosts;
    },

    onCostsCellSave(args) {
      if (args.previousValue !== args.value) {
        this.$makeDirty();
      }
    },

    onCancelCostsAction() {
      this.$makeClean();
    },

    toolbarCostsClickE(args) {
      if (args.item.id === 'delete_btn') {
        Toast.fire({
          toast: false,
          icon: 'warning',
          title: 'Usuwanie Wiersza',
          html: 'Na pewno chcesz usunąć wybrane wiersze?',
          showDenyButton: true,
          timer: false,
          position: 'center',
          showConfirmButton: true,
          confirmButtonText: 'Tak, usuń!',
          denyButtonText: 'Nie',
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteCostsRow();
          }
        });
        return false;
      }

      if (args.item.id === 'excelexport') {
        this.$refs.gridCosts.excelExport();
      }
    },

    actionCostsComplete(args) {
      if (args.requestType === "batchsave") {
        this.isLoading = true;

        let newData = this.parseJsonRows(args.rows);
        this.updateCostsRows(newData);
      }
    },

    updateCostsRows(rows) {
      const self = this;
      axios.post(route('projects.show.budget.updateCosts', self.project.id), {
        'rows': rows
      }).then(response => {
        this.costsData = response.data;
        self.isLoading = false;
        self.reloadCostsGrid();
      }).catch(error => {
        console.error('Błąd podczas aktualizacji zadań:', error.message);
        Toast.fire({
          position: 'top-end',
          toast: true,
          icon: 'error',
          title: 'Błąd',
          html: 'Błąd podczas aktualizacji zadań: ' + error.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true
        });
      });
    },

    deleteBudgetRow() {
      let self = this;
      this.isLoadingBudget = true;
      const scrollPosition = window.scrollY;
      let gridInstance = document.getElementById('GridBudgetProject').ej2_instances[0];
      const selectedIndexes = gridInstance.getSelectedRowCellIndexes();
      let deletedRows = selectedIndexes.map(o => gridInstance.currentViewData[o.rowIndex]);
      deletedRows = deletedRows.map(obj => obj.id); // zmiana na array tylko z id

      selectedIndexes.forEach(o => {
        gridInstance.deleteRecord('id', gridInstance.currentViewData[o.rowIndex]);
      });

      axios.delete(route('projects.show.budget.deleteBudgets', {
        project: this.project,
        budgets: JSON.stringify(deletedRows)
      })).then(response => {
        if (response.status === 200) {
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
          });
        } else {
          console.log(response);
          Toast.fire({
            position: 'center',
            toast: false,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
            timer: false,
            showCloseButton: true
          });
        }
        self.updateBudgetRow();
        this.$nextTick(() => {
          window.scrollTo(0, scrollPosition);
        });
      }).catch(error => {
        console.log(error);
      });
    },

    actionBudgetComplete(args) {
      if (args.requestType === "batchsave") {
        this.isLoadingBudget = true;

        let newData = this.parseJsonRows(args.rows);
        this.updateBudgetRow(newData);
      }
    },

    onBudgetCellSave(args) {
      if (args.previousValue !== args.value) {
        this.$makeDirty();
      }
    },
    toolbarBudgetClickE(args) {
      if (args.item.id === 'delete_btn') {
        Toast.fire({
          toast: false,
          icon: 'warning',
          title: 'Usuwanie Wiersza',
          html: 'Na pewno chcesz usunąć wybrane wiersze?',
          showDenyButton: true,
          timer: false,
          position: 'center',
          showConfirmButton: true,
          confirmButtonText: 'Tak, usuń!',
          denyButtonText: 'Nie',
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteBudgetRow();
          }
        });
        return false;
      }

      if (args.item.id === 'excelexport') {
        this.$refs.gridBudget.excelExport();
      }
    },
    onCancelBudgetAction() {
      this.$makeClean();
    },

    updateBudgetRow(rows) {
      const self = this;
      axios.post(route('projects.show.budget.updateBudgets', self.project.id), {
        'rows': rows
      }).then(response => {
        this.budgetData = response.data;
        self.isLoadingBudget = false;
        self.reloadBudgetGrid();
        this.$makeClean();
      }).catch(error => {
        console.error('Błąd podczas aktualizacji zadań:', error.message);
        Toast.fire({
          position: 'top-end',
          toast: true,
          icon: 'error',
          title: 'Błąd',
          html: 'Błąd podczas aktualizacji zadań: ' + error.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true
        });
      });
    },

    parseJsonRows(rows) {
      let jsonRows = [];
      rows.forEach(function (row) {
        jsonRows.push(row.data);
      });

      return jsonRows;
    },

    reloadCostsGrid() {
      this.costsGridKey += 1;
    },

    reloadBudgetGrid() {
      this.budgetProjectGridKey += 1;
    },

    getPageSettings() {
      return {pageSize: 15};
    },

    getFilterOptions() {
      return {type: "Excel"};
    },

    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
      };
    },

    getSelectionOptions() {
      return {type: 'Multiple', cellSelectionMode: 'Box', mode: 'Cell'};
    },

    getCurrencyParams() {
      return {
        params: {
          dataSource: [
            {currency: 'PLN'},
            {currency: 'USD'},
            {currency: 'w EUR'},
          ],
          fields: {text: "currency", value: "currency"},
        },
      };
    },
  },
}
</script>
