<template>
  <button type="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#gusModal">
    Pobierz dane z GUS
  </button>

  <!-- Modal -->
  <div class="modal fade" id="gusModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="modelTitleId">Wyszukaj w GUS</h4>
          <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group align-items-center">
            <label for="searchNip" class="form-label m-0 me-2">Wprowadź NIP</label>
            <input type="number" v-model="searchNip" id="searchNip" class="form-control" placeholder="Bez myślników"
                   aria-describedby="helpId">
          </div>
          <div class="text-center mt-3 mb-3">
            <button type="button" class="btn btn-primary" @click="handleSearch" :disabled="isLoading">
              {{ isLoading ? 'Pobieram...' : 'Szukaj' }}
            </button>
          </div>

          <ul v-if="Array.isArray(responseFromGus.data) && responseFromGus.data.length > 0">
            <li v-for="item in responseFromGus.data" :key="item.nip" @click="sendDataToForm(item)"
                class="btn btn-sm btn-outline-default">
              {{ item.company_name }} - {{ item.street }} {{ item.building_number }}/{{ item.apartment_number }},
              {{ item.zip_code }} {{ item.city }}
            </li>
          </ul>
          <p v-else>Brak danych</p>

        </div>
        <div class="modal-footer">
          <button id="closeModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import axios from 'axios';

const emit = defineEmits(['getFromGus']);

const searchNip = ref(null);
const responseFromGus = ref({data: []});
const isLoading = ref(false);

const handleSearch = async () => {
  isLoading.value = true;
  if (searchNip.value) {
    try {
      const response = await axios.get(route('customer.get-from-gus-by-nip', {nip: searchNip.value}));

      // Sprawdzenie typu odpowiedzi
      if (typeof response.data === 'string') {
        responseFromGus.value = {data: []}; // Ustaw pustą tablicę, gdy odpowiedź jest stringiem
      } else if (Array.isArray(response.data)) {
        responseFromGus.value = {data: response.data}; // Ustaw dane, gdy odpowiedź jest tablicą
      } else {
        responseFromGus.value = {data: []}; // Ustaw pustą tablicę, gdy odpowiedź jest obiektem, ale nie tablicą
      }

    } catch (error) {
      console.error(error);
      responseFromGus.value = {data: []};
    } finally {
      isLoading.value = false;
    }
  }
}

const sendDataToForm = (data) => {
  emit('getFromGus', data);
  $('#closeModal').click();
}
</script>