<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">

        <projectMenu :project="project"></projectMenu>

        <div class="widget-content">
          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="text-end mb-3">
            <ProjectsHistory
                :project="project"
                :model="'RiskAssessmentHistory'">
            </ProjectsHistory>
          </div>

          <div class="card-body">
            <div class="row mt-3">
              <div class="col-sm-12" v-if="risksData">
                <h4>Ocena ryzyka</h4>

                <div v-if="isLoading">
                  <div class="position-absolute w-100 h-100 text-center" style="z-index: 9">
                    <div class="spinner-border text-danger me-2 align-self-center loader-lg" style="top:20%"></div>
                  </div>
                </div>

                <div :style="{ opacity: isLoading ? '0.4' : '1' }">
                  <ejs-grid id="GridRiskAssessment" ref="Grid" :key="gridKey" gridLines="Both" height='100%'
                            class="top-margin"
                            :keyPressed="onKeyDown"
                            :dataSource="risksData" :editSettings="editSettings"
                            :allowPaging="true" :pageSettings='pageSettings'
                            :allowSorting='true' :allowFiltering='true'
                            :filterSettings="filterOptions" :toolbar='toolbar'
                            :allowReordering='true' :allowResizing='true'
                            :showColumnChooser='true' :selectionSettings='selectionOptions'
                            :allowSelection='true'
                            :enablePersistence="true"
                            :actionComplete="actionComplete"
                            :cellSave="onCellSave"
                            :toolbarClick="toolbarClickE"
                            :batchCancel="onCancelAction"
                            :dataBound="dataBound">
                    <e-columns>
                      <e-column field='id' headerText='ID' width="85" :allowEditing="false"
                                isPrimaryKey="true"></e-column>
                      <e-column field='risk' type='string' headerText='Ryzyko' :validationRules='riskRules'></e-column>
                      <e-column field='impact' editType='dropdownedit' :edit='impactParams' headerText='Wpływ'
                                type="string"></e-column>
                      <e-column field='probability' editType='dropdownedit' :edit='probabilityParams'
                                headerText='Prawdopodobieństwo' type="string"></e-column>
                      <e-column field='strategy' headerText='Strategia' type='string' :edit='strategyParams'></e-column>
                      <e-column field='strategy_description' type='string' headerText='Opis Strategii'></e-column>
                      <e-column field='trigger' type='string' headerText='Wyzwalacz'></e-column>
                      <e-column field='created_by_name' headerText='Stworzył' :allowEditing="false"
                                type="string"></e-column>
                    </e-columns>
                  </ejs-grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </AppLayout>
</template>

<script>

import AppLayout from "@/Layouts/App.vue";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import projectMenu from "@/Pages/Projects/parts/projectMenu.vue";

import ProjectsHistory from "@/Pages/Projects/Components/ProjectsHistory.vue";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Edit, ExcelExport,
  Filter,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar
} from '@syncfusion/ej2-vue-grids';

import {MultiSelect} from '@syncfusion/ej2-vue-dropdowns';

export default {

  components: {
    ProjectsHistory, AppLayout, projectMenu, MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },
  props: {
    project: Object,
    risks: Object,
  },

  setup(page) {
    let risksData = page.risks;
    if (risksData.length === 0)
      risksData.push({});

    return {
      risksData
    }
  },

  data() {
    let ddElem;
    let multiSelectObj;

    function createStrategyFn() {
      ddElem = document.createElement('input');
      return ddElem;
    }

    function readStrategyFn() {
      return multiSelectObj.value.join(",");
    }

    function destroyStrategyFn() {
      multiSelectObj.destroy();
    }

    function writeStrategyFn(args) {
      {
        let multiSelectVal = args.rowData[args.column.field]
            ? args.rowData[args.column.field].split(",")
            : [];
        multiSelectObj = new MultiSelect({
          value: multiSelectVal,
          dataSource: [
            {strategy: 'Wykorzystanie'},
            {strategy: 'Wzmacnianie'},
            {strategy: 'Odrzucenie'},
            {strategy: 'Unikanie'},
            {strategy: 'Zapobieganie'},
            {strategy: 'Minimalizowanie'},
            {strategy: 'Transfer'},
            {strategy: 'Akceptacja'},
          ],
          fields: {value: "strategy", text: "strategy"},
          floatLabelType: "Never",
          mode: "Box",
        });
        multiSelectObj.appendTo(ddElem);
      }
    }

    return {
      isLoading: false,
      gridKey: 0,
      pageSettings: {pageSize: 15},
      filterOptions: {type: "Excel"},
      toolbar: this.getToolbar(),
      riskRules: {required: true},
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch'
      },
      selectionOptions: {type: 'Multiple', cellSelectionMode: 'Box', mode: 'Cell'},
      strategyParams: {
        create: createStrategyFn,
        destroy: destroyStrategyFn,
        read: readStrategyFn,
        write: writeStrategyFn
      },
      impactParams: {
        params: {
          dataSource: [
            {impact: 'M'},
            {impact: 'Ś'},
            {impact: 'D'},
          ],
          fields: {text: "impact", value: "impact"},
        }
      },
      probabilityParams: {
        params: {
          dataSource: [
            {probability: 'M'},
            {probability: 'Ś'},
            {probability: 'D'},
          ],
          fields: {text: "probability", value: "probability"},
        }
      }
    }
  },
  provide:
      {
        grid: [Page, Edit, Toolbar, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport]
      },

  mounted() {
    window.addEventListener('keydown', this.saveGridData);
  },
  methods: {
    route,
    isRouteActive(routeName) {
      return route().current() === routeName;
    },

    dataBound() {
      let gridInstance = document.getElementById('GridRiskAssessment').ej2_instances[0];
      gridInstance.hideScroll();
    },

    onCellSave(args) {
      if (args.previousValue !== args.value) {
        this.$makeDirty();
      }
    },

    onCancelAction() {
      this.$makeClean();
    },

    getToolbar() {
      return [
        "Add",
        {text: 'Usuń', prefixIcon: 'e-delete', id: 'delete_btn'},
        "Update",
        "Cancel",
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    toolbarClickE(args) {
      if (args.item.id === 'delete_btn') {
        Toast.fire({
          toast: false,
          icon: 'warning',
          title: 'Usuwanie Wiersza',
          html: 'Na pewno chcesz usunąć wybrane wiersze?',
          showDenyButton: true,
          timer: false,
          position: 'center',
          showConfirmButton: true,
          confirmButtonText: 'Tak, usuń!',
          denyButtonText: 'Nie',
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRow();
          }
        });
        return false;
      }

      if (args.item.id === 'excelexport') {
        this.$refs.Grid.excelExport();
      }
    },

    actionComplete(args) {
      if (args.requestType === "batchsave") {
        this.isLoading = true;

        let newData = this.parseJsonRows(args.rows);
        this.updateRiskRows(newData);
      }
    },

    deleteRow() {
      let self = this;
      this.isLoading = true;
      const scrollPosition = window.scrollY;
      let gridInstance = document.getElementById('GridRiskAssessment').ej2_instances[0];
      const selectedIndexes = gridInstance.getSelectedRowCellIndexes();
      let deletedRows = selectedIndexes.map(o => gridInstance.currentViewData[o.rowIndex]);
      deletedRows = deletedRows.map(obj => obj.id); // zmiana na array tylko z id

      selectedIndexes.forEach(o => {
        gridInstance.deleteRecord('id', gridInstance.currentViewData[o.rowIndex]);
      });

      axios.delete(route('projects.show.risk-assessment.deleteRisks', {
        project: this.project,
        risks: JSON.stringify(deletedRows)
      })).then(response => {
        if (response.status === 200) {
          Toast.fire({
            position: 'top-end',
            toast: true,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
          });
        } else {
          console.log(response);
          Toast.fire({
            position: 'center',
            toast: false,
            icon: response.data.icon,
            title: response.data.title,
            html: response.data.message,
            timer: false,
            showCloseButton: true
          });
        }
        self.updateRiskRows();
        this.$nextTick(() => {
          window.scrollTo(0, scrollPosition);
        });
      }).catch(error => {
        console.log(error);
      });
    },


    updateRiskRows(rows) {
      const self = this;
      axios.post(route('projects.show.risk-assessment.updateRisks', self.project.id), {
        'rows': rows
      }).then(response => {
        self.reloadGrid();
        this.risksData = response.data;
        self.isLoading = false;
        this.$makeClean();
      }).catch(error => {
        console.log(error);
      })
    },

    parseJsonRows(rows) {
      let jsonRows = [];
      rows.forEach(function (row) {
        jsonRows.push(row.data);
      });

      return jsonRows;
    },

    reloadGrid() {
      this.gridKey += 1;
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        // zapisz
        let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
        let buttons = toolbar.querySelectorAll('.e-toolbar-item');
        let updateButton = buttons[2].querySelector('button');
        if (updateButton) {
          updateButton.click();
        }
      }
    }
  }
}
</script>

<style>
@import "@syncfusion/ej2-vue-grids/styles/material.css";
@import "@syncfusion/ej2-base/styles/material.css";
@import "@syncfusion/ej2-buttons/styles/material.css";
@import "@syncfusion/ej2-calendars/styles/material.css";
@import "@syncfusion/ej2-dropdowns/styles/material.css";
@import "@syncfusion/ej2-inputs/styles/material.css";
@import "@syncfusion/ej2-navigations/styles/material.css";
@import "@syncfusion/ej2-popups/styles/material.css";
@import "@syncfusion/ej2-splitbuttons/styles/material.css";
</style>