<script setup>
import {useForm} from "@inertiajs/vue3";
import AppLayout from "@/Layouts/App.vue";

const form = useForm({
    name: null,
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    password_confirmation: null,
    phone: null,
    roles: null
})

</script>

<template>
    <AppLayout>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-six">
                <div class="widget-heading">
                    <div class="row">
                        <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
                            <h4 class="me-3 my-0">Utwórz nowego użytkownika</h4>
                            <Link :href="route('users.index')" class="btn btn-outline-primary">
                                <i class="bi bi-people"></i> &nbsp; Lista użytkowników
                            </Link>
                        </div>
                    </div>
                </div>

                <div class="widget-content">

                    <div v-if="$page.props.errors">
                        <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
                            <li class="alert alert-danger">{{ error }}</li>
                        </ul>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <form @submit.prevent="form.post(route('users.store'))">
                                <label class="form-label" for="name">Imię:</label>
                                <div v-if="form.errors.first_name">{{ form.errors.first_name }}</div>
                                <input class="form-control" id="first_name" v-model="form.first_name"/>

                                <label class="form-label" for="name">Nazwisko</label>
                                <div v-if="form.errors.last_name">{{ form.errors.last_name }}</div>
                                <input class="form-control" id="last_name" v-model="form.last_name"/>

                                <label class="form-label" for="short_name">Email:</label>
                                <div v-if="form.errors.email">{{ form.errors.short_name }}</div>
                                <input class="form-control" id="email" v-model="form.email"/>

                                <label class="form-label" for="phone">Nr. tel:</label>
                                <div v-if="form.errors.email">{{ form.errors.phone }}</div>
                                <input class="form-control" id="email" v-model="form.phone"/>

                                <label class="form-label" for="password">Hasło:</label>
                                <div v-if="form.errors.password">{{ form.errors.password }}</div>
                                <input class="form-control" type="password" id="password" v-model="form.password"/>

                                <label class="form-label" for="password_confirmation">Powtórz hasło:</label>
                                <div v-if="form.errors.password_confirmation">{{ form.errors.password_confirmation }}
                                </div>
                                <input class="form-control" type="password" id="password_confirmation"
                                    v-model="form.password_confirmation"/>

                                <label class="form-label" for="roles">Rola:</label>
                                <div v-if="form.errors.role">{{ form.errors.role }}</div>
                                <multiselect
                                    id="roles"
                                    open-direction="bottom"
                                    v-model="form.roles"
                                    tag-placeholder=""
                                    placeholder="Szukaj roli"
                                    label="name"
                                    track-by="name"
                                    :options="options"
                                    :multiple="true"
                                    :taggable="true"
                                    @tag="addTag">
                                </multiselect>


                                <button class="btn btn-primary mt-3" type="submit" :disabled="form.processing">Zapisz
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<script>
import Multiselect from '@suadelabs/vue3-multiselect';

export default {
    components: {Multiselect},
    props: {
        roles: Array,
    },
    data() {
        return {
            options: this.roles,
            roles: [], // Initialize value as an empty array
        };
    },
    methods: {
        addTag(newTag) {
            const tag = {
                name: newTag.name,
            };
            this.options.push(tag);
            this.value.push(tag);
        },
    },
};
</script>
<style src="@suadelabs/vue3-multiselect/dist/vue3-multiselect.css"></style>
