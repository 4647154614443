<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="row layout-spacing">
        <!-- Content -->
        <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
          <div class="user-profile layout-spacing">
            <div class="panel">
              <div class="panel-body">
                <div class="d-flex justify-content-between">
                  <h3 class="">Profil {{ currentUser.first_name ?? '' }} {{ currentUser.last_name ?? '' }}</h3>
                  <Link
                      :href="route('users.showResetPasswordForm', [this.currentUser.id])"
                      class="mt-2 edit-profile btn btn-outline-primary">
                    <i class="bi bi-lock"></i>&nbsp;Zmień hasło
                  </Link>
                </div>
                <div class="user-info-list">

                  <div class="d-flex mt-1">
                    <div>Przypisana rola: <b>{{ roles.join(', ') }}</b></div>
                    <div class="ms-auto">Utworzone: {{ formatDate(currentUser.created_at) }}</div>
                  </div>
                  <div class="mt-1">Email: <b><a :href="`mailto:${currentUser.email}`">{{ currentUser.email }}</a></b>
                  </div>

                  <form class="form-container mt-4" @submit.prevent="submitForm">
                    <label for="first_name">Imię</label>
                    <input id="first_name" class="form-control form-control-sm" v-model="currentUser.first_name">

                    <label class="mt-3" for="last_name">Nazwisko</label>
                    <input id="last_name" class="form-control form-control-sm" v-model="currentUser.last_name">

                    <label class="mt-3" for="phone">Telefon</label>
                    <input id="phone" class="form-control form-control-sm" v-model="currentUser.phone">

                    <button class="mt-4 btn btn-primary" type="submit" :disabled="isSubmitDisabled">Uaktualnij</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "../../Layouts/App.vue";
import route from "../../../../vendor/tightenco/ziggy/src/js/index.js";

export default {
  components: {AppLayout},
  props: {
    currentUser: Object,
    roles: Object,
    permissions: Object
  },
  data() {
    return {
      isSubmitDisabled: false
    }
  },
  methods: {
    route,
    async submitForm() {
      this.isSubmitDisabled = true;
      await this.$inertia
          .post(route('profile.update', this.currentUser.id), this.currentUser)

      this.isSubmitDisabled = false;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
};
</script>

<style>
/* Add your custom styles here */
</style>
