<template>
    <AppLayout>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-six">
                <div class="widget-heading">
                    <div class="mb-3">
                        <Link :href="route('profile.show')" class="btn btn-outline-primary">
                            <i class="bi bi-arrow-left"></i> Powrót
                        </Link>
                    </div>
                </div>

                <div class="widget-content">
                    <div v-if="$page.props.errors">
                        <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
                            <li class="alert alert-danger">{{ error }}</li>
                        </ul>
                    </div>

                    <div class="card-header">Zresetuj hasło </div>
                    <div class="card-body">
                        <form @submit.prevent="form.put(route('users.resetPassword', form.id))">
                            <label class="form-label" for="old_password">Obecne hasło:</label>
                            <div v-if="form.errors.old_password">{{ form.errors.old_password }}</div>
                            <input class="form-control" type="password" id="old_password" v-model="form.old_password"/>

                            <label class="form-label" for="password">Nowe hasło:</label>
                            <div v-if="form.errors.password">{{ form.errors.password }}</div>
                            <input class="form-control" type="password" id="password" v-model="form.password"/>

                            <label class="form-label" for="password_confirmation">Powtórz nowe hasło:</label>
                            <div v-if="form.errors.password_confirmation">{{ form.errors.password_confirmation }}</div>
                            <input class="form-control" type="password" id="password_confirmation"
                                v-model="form.password_confirmation"/>

                            <button class="btn btn-primary mt-3" type="submit" :disabled="form.processing">Zresetuj hasło</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<script>
import { Link, useForm } from "@inertiajs/vue3";
import AppLayout from "@/Layouts/App.vue";

export default {
    components: { AppLayout, Link },
    props: {
        currentUser: Object,
    },
    setup(props) {
        const data = {
            id: props.currentUser.id,
            old_password: null,
            password: null,
            password_confirmation: null,
        };
        const form = useForm(data);

        return { form };
    },
};
</script>
