import tinymce from 'tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/models/dom';
import '/resources/library/js/tinymce/langs/pl.js';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/media';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/accordion';
import 'tinymce/plugins/table';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';


function generateCss(additionalStyles) {
    return `
    @import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

    body {
        font-family: arimo;
        font-size: 8pt;
        max-width: 179mm;
        margin: -1px auto 0 auto;
        border: 1px solid #ddd;
        padding: 0 15.5mm;
        position: relative;
        background-image: url('/images/tinymce/background.png');
        ${additionalStyles}
    }
    
    table {
        max-width: 148mm;
    }
    
    tr, td {
        height: 16px;
    }
    
    p, span, ul, li {
        line-height: 1.2;
        letter-spacing: .0312rem;
    }
    
    p {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
    
     ul, li {
            margin: 0 0 0 5pt;
            padding: 0;
        }

    ul {
        margin-left: 24pt;
    }
    
    img {
        max-width: 670px;
        height: auto;
    }        
`
}

class TinyMCEHelper {
    static initEditor(selector, vueComponentInstance, additionalStyles = '') {
        tinymce.init({
            height: 500,
            resize: 'both',
            selector: selector,
            paste_data_images: true,
            plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'wordcount', 'pagebreak'],
            content_css: 'https://cdn.tiny.cloud/1/no-api-key/tinymce/6.0.1-3/skins/ui/oxide/content.min.css',
            skin: false,
            browser_spellcheck: true,
            content_style: generateCss(additionalStyles),
            entity_encoding: "raw",
            remove_linebreaks: false,
            toolbar: 'fullscreen | undo redo | fontsize fontfamily | bold italic underline strikethrough | forecolor backcolor | bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | removeformat | link image | styles | table | numberedList',
            font_family_formats:
                "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Arimo=arimo; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            language: 'pl',
            init_instance_callback: function () {
                $('.tox-promotion, .tox-statusbar__branding').hide(); // usunięcie przycisku do upgrade
            },
            table_default_styles: {
                width: '100%'
            },
            automatic_uploads: true,
            relative_urls: false,
            remove_script_host: false,
            convert_urls: true,
            document_base_url: process.env.APP_URL,
            file_picker_types: 'image',
            images_upload_url: route('upload-image'),
            file_picker_callback: function (cb, value, meta) {
                let input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.onchange = function () {
                    let file = this.files[0];

                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        let id = 'blobid' + (new Date()).getTime();
                        let blobCache = tinymce.activeEditor.editorUpload.blobCache;
                        let base64 = reader.result.split(',')[1];
                        let blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        cb(blobInfo.blobUri(), {title: file.name});
                    };
                };
                input.click();
            },
            setup: function (editor) {
                // pokaż numbered List tylko dla opisu oferty
                if (editor.id === 'textarea_description') {
                    editor.ui.registry.addButton('numberedList', {
                        text: 'Tag/Numeracja',
                        tooltip: 'Dodaj tag numerowany',
                        onAction: function (_) {
                            addNumberedList(editor);
                        }
                    });
                }

                editor.on('init NodeChange', function () {
                    // updateHorizontalLines(editor);
                });


                // nasłuchuje ctrl+s
                editor.on('keydown', function (event) {
                    if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                        // jeśli znajdzie metodę onCtrlS to ma ją wykonać
                        // (znajduje się ona np. w RichTextEditorComponent)
                        if (vueComponentInstance && typeof vueComponentInstance.onCtrlS === 'function') {
                            event.preventDefault();
                            vueComponentInstance.onCtrlS(event);
                        }
                    }
                });
            }
        });
    }
}

function addNumberedList(editor) {
    editor.undoManager.transact(function () {
        let counter = 1; // Inicjalizujemy licznik
        let cursorPosition = editor.selection.getBookmark(2); // Pobieramy aktualną pozycję kursora

        // Wstawiamy tekst z odpowiednim numerem na miejscu kursora
        editor.insertContent(`[NUMERACJA-${counter}]`);

        // Pobieramy aktualną zawartość edytora
        let content = editor.getContent();

        // Pobieramy wszystkie tagi NUMERACJA-n
        let matches = content.match(/\[NUMERACJA-\d+\]/g);

        // Jeśli znaleziono jakieś tagi, numerujemy je od nowa
        if (matches) {
            matches.forEach((match, index) => {
                return match + index + 1;
            });
        }

        // Ustawiamy nową zawartość edytora z zaktualizowaną numeryzacją
        editor.setContent(content.replace(/\[NUMERACJA-\d+\]/g, () => `[NUMERACJA-${counter++}]`));
        cursorPosition.start[0] += `[NUMERACJA-${counter}]`.length;
        editor.selection.moveToBookmark(cursorPosition); // ustawiamy starą pozycję kursora
    });
}


function updateHorizontalLines(editor) {
    let domHtml = $(editor.getDoc().getElementsByTagName('HTML')[0]);
    let pageNo = 'Str ';

    let dpi = 96
    let mm = dpi / 2.54;

    let mmPerInch = 25.4;
    let a4HeightMm = 297; // wysokość A4 w mm
    let headerFooterMarginPt = 125; // marginesy nagłówka i stopki w punktach
    let headerFooterMarginPx = headerFooterMarginPt * 1.373; // przeliczenie punktów na piksele
    let a4HeightPx = (a4HeightMm / mmPerInch) * dpi; // wysokość A4 w pikselach
    let a4px = a4HeightPx - (2 * headerFooterMarginPx); // wysokość A4 w pikselach minus marginesy


    // start linii pomocniczej
    let startMargin = 0;

    // max size (px) = document size + extra
    let imgH = domHtml.height() + a4px * 2;

    // rysujemy linie pomocniczą
    let s = '';
    s += '<svg width="100%" height="' + imgH + '" xmlns="http://www.w3.org/2000/svg">';

    s += '<style>';
    s += '.pageNumber{font-size:18px;font-family:Verdana;}';
    s += '</style>';

    let pages = Math.ceil(imgH / a4px);

    let i, curY = startMargin;
    for (i = 0; i < pages; i++) {
        let blockH = a4px;

        // dodaj linię podziału
        s += '<line x1="0" y1="' + curY + '" x2="100%" y2="' + curY + '" stroke-width="1" stroke="#e03e2d"/>';

        // dodaj numer strony
        s += '<text x="10" y="' + (curY + 19 + 5) + '" class="pageNumber" fill="#e03e2d">' + pageNo + (i + 1) + '</text>';

        curY += blockH;
    }

    s += '</svg>';
    domHtml.css('background-image', 'url("data:image/svg+xml;utf8,' + encodeURIComponent(s) + '")');
}

export default TinyMCEHelper;
