<template>
  <div>
    <h4 class="me-3 my-0 mb-4">Osoby kontaktowe</h4>
    <div class="table-responsive w-100">
      <table class="table">
        <thead>
        <tr>
          <th>Zwrot grzecz.</th>
          <th>Imię</th>
          <th>Nazwisko</th>
          <th>Stanowisko</th>
          <th>Telefon</th>
          <th>Email</th>
          <th class="text-center">Domyślny</th>
          <th class="text-end">Akcje</th>
        </tr>
        </thead>
        <tbody>
        <template v-if="contactList.length > 0">
          <tr v-for="(contactPerson, index) in contactList" :key="index">
            <td v-if="!contactPerson.isEditing" style="width: 10%">{{ contactPerson.salutation }}</td>
            <td v-else style="width: 10%">
              <input class="form-control" v-model="contactPerson.salutation"/>
            </td>
            <td v-if="!contactPerson.isEditing" style="width: 15%">{{ contactPerson.first_name }}</td>
            <td v-else style="width: 15%">
              <input class="form-control" v-model="contactPerson.first_name"/>
            </td>
            <td v-if="!contactPerson.isEditing" style="width: 15%">{{ contactPerson.last_name }}</td>
            <td v-else style="width: 15%">
              <input class="form-control" v-model="contactPerson.last_name"/>
            </td>
            <td v-if="!contactPerson.isEditing" style="width: 15%">{{ contactPerson.position }}</td>
            <td v-else style="width: 15%">
              <input class="form-control" v-model="contactPerson.position"/>
            </td>
            <td v-if="!contactPerson.isEditing" style="width: 15%">{{ contactPerson.phone }}</td>
            <td v-else style="width: 15%">
              <input class="form-control" v-model="contactPerson.phone"/>
            </td>
            <td v-if="!contactPerson.isEditing" style="width: 15%">{{ contactPerson.email }}</td>
            <td v-else style="width: 15%">
              <input class="form-control" v-model="contactPerson.email"/>
            </td>
            <td v-if="!contactPerson.isEditing" class="text-center" style="width: 5%">
          <span v-if="contactPerson.default">
            <i style="font-size: 20px" class="text-success bi bi-check-circle-fill"></i>
          </span>
            </td>
            <td v-else class="text-center" style="width: 5%">
              <div class="checkbox-success custom-control custom-checkbox text-color">
                <input type="checkbox" class="custom-control-input" :id="'defaultSwitch_'+contactPerson.id"
                       v-model="contactPerson.default"/>
                <label class="custom-control-label" :for="'defaultSwitch_'+contactPerson.id"></label>
              </div>
            </td>
            <td class="text-end w-25">
              <button type="button" v-if="contactPerson.isEditing" class="mb-1 me-1 btn btn-sm"
                      :class="contactPerson.isEditing ? 'btn-primary' : 'btn-warning'"
                      @click="updateContact(contactPerson)">
                Zapisz
              </button>
              <button type="button" class="mb-1 btn btn-sm" :class="contactPerson.isEditing ? 'btn-outline-warning' : 'btn-warning'"
                      @click="toggleEdit(contactPerson)">
                <span v-if="contactPerson.isEditing">Anuluj</span> <span v-else>
                            <i class="bi bi-pen"></i>
                          </span>
              </button>
              <button type="button" v-if="!contactPerson.isEditing" class="ms-1 mb-1 btn btn-sm btn-danger"
                      @click="confirmDeleteContact(index, contactPerson)">
                <i class="bi bi-trash"></i></button>
            </td>
          </tr>
        </template>
        <tr v-if="isAdding">
          <td style="width: 14%;">
            <input autocomplete="off" class="form-control"
                   v-model="newContact.salutation" placeholder="Zwrot grzecznościowy" name="salutation" type="text"/>
          </td>
          <td style="width: 14%;">
            <input autocomplete="off" class="form-control"
                   :class="!isInputValid(newContact.first_name) ? 'is-invalid' : ''"
                   v-model="newContact.first_name" placeholder="Imię" name="first_name" type="text"/>
          </td>
          <td style="width: 14%;">
            <input autocomplete="off" class="form-control"
                   :class="!isInputValid(newContact.last_name) ? 'is-invalid' : ''"
                   v-model="newContact.last_name" placeholder="Nazwisko"/>
          </td>
          <td style="width: 14%;">
            <input autocomplete="off" class="form-control" v-model="newContact.position" placeholder="Stanowisko"/>
          </td>
          <td style="width: 14%;">
            <input autocomplete="off" class="form-control" v-model="newContact.phone" placeholder="Telefon"/>
          </td>
          <td style="width: 14%;">
            <input autocomplete="off" class="form-control" v-model="newContact.email" placeholder="Email"/>
          </td>
          <td class="text-center">
            <div class="checkbox-success custom-control custom-checkbox text-color">
              <input type="checkbox" class="custom-control-input" id="defaultSwitch" v-model="newContact.default"/>
              <label class="custom-control-label" for="defaultSwitch"></label>
            </div>
          </td>
          <td class="text-end w-25">
            <button type="button" class="mb-1 btn btn-sm btn-outline-primary" @click="addContact(newContact)"
                    :disabled="!isContactFormValid(newContact) || isProcessing">Zapisz
            </button>
            <button type="button" class="ms-1 mb-1 btn btn-sm btn-outline-warning" :disabled="isProcessing" @click="cancelAddContact">
              Anuluj
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <button type="button" class="btn btn-primary mt-3" @click="toggleAddingContact" :class="{'d-none': isEditing}">
        Dodaj osobę kontaktową
      </button>
    </div>
  </div>
</template>

<script>
import {useForm} from "@inertiajs/vue3";
import {ref} from "vue";

export default {
  props: {
    customerId: Number,
    contacts: Object
  },

  data() {
    let contactList = this.contacts || [];
    // dodanie nowego itemu do obiektu
    if (contactList.length > 0) {
      for (let contact of contactList) {
        contact.isEditing = false;
      }
    }

    let emptyAttributesList = {
      salutation: null,
      first_name: null,
      last_name: null,
      position: null,
      phone: null,
      email: null,
      default: null
    };

    return {
      isEditing: false,
      isAdding: false,
      isProcessing: false,
      contactList,
      emptyAttributesList,
      contactForm: emptyAttributesList,
      newContact: emptyAttributesList
    }
  },

  methods: {

    toggleEdit(contact) {
      if (contact.isEditing === false) {
        this.contactForm.salutation = contact.salutation;
        this.contactForm.first_name = contact.first_name;
        this.contactForm.last_name = contact.last_name;
        this.contactForm.position = contact.position;
        this.contactForm.phone = contact.phone;
        this.contactForm.email = contact.email;
        this.contactForm.default = contact.default;
        contact.isEditing = true;
        this.isEditing = true;
      } else {
        this.contactForm.salutation = null;
        this.contactForm.first_name = null;
        this.contactForm.last_name = null;
        this.contactForm.position = null;
        this.contactForm.phone = null;
        this.contactForm.email = null;
        this.contactForm.default = null;
        contact.isEditing = false;
        this.isEditing = false;
      }
    },

    toggleAddingContact() {
      this.isAdding = !this.isAdding;
    },
    isInputValid(item) {
      return item !== null && item.trim() !== '';
    },

    isContactFormValid(contactPerson) {
      return contactPerson.first_name !== null
          && contactPerson.first_name.trim() !== ''
          && contactPerson.last_name !== null
          && contactPerson.last_name.trim() !== '';
    },

    addContact(newContact) {
      // Sprawdź, czy wymagane pola są uzupełnione
      if (!this.isContactFormValid(newContact)) {
        return;
      }

      this.isProcessing = true;
      axios.post(route('customer.contact-person.add', this.customerId), newContact)
          .then(response => {
            Toast.fire({
              toast: true,
              icon: response.data.icon,
              title: response.data.title,
              html: response.data.message,
              showClass: {popup: 'animate__animated animate__fadeInDown'},
              hideClass: {popup: 'animate__animated animate__fadeOutUp'},
              timer: 2500,
            })

            newContact.id = response.data.id;
            // dodaj do listy istniejących
            this.contactList.push({...newContact});
            // usuń przypisane dane, żeby formularz się wyczyścił
            newContact = this.emptyAttributesList;
          })
          .catch(error => {
            // Obsługa błędu
            console.error('Błąd podczas dodawania kontaktu:', error);
          })
          .finally(() => {
            this.isProcessing = false;
            this.isAdding = false;
          });
    },

    cancelAddContact() {
      this.isAdding = false;
    },

    confirmDeleteContact(index, contactPerson) {
      Toast.fire({
        toast: false,
        icon: 'warning',
        title: 'Usuwanie kontaktu',
        html: 'Czy na pewno chcesz usunąć ten kontakt?',
        showDenyButton: true,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        position: 'center',
        showConfirmButton: true,
        confirmButtonText: 'Tak',
        denyButtonText: 'Nie',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteContact(index, contactPerson);
        }
      })
    },

    deleteContact(index, contactPerson) {
      this.isProcessing = true;

      axios.post(route('customer.contact-person.delete', contactPerson))
          .then(response => {
            Toast.fire({
              toast: true,
              icon: response.data.icon,
              title: response.data.title,
              html: response.data.message,
              showClass: {popup: 'animate__animated animate__fadeInDown'},
              hideClass: {popup: 'animate__animated animate__fadeOutUp'},
              timer: 2500,
            })

            // Aktualizuj dane kontaktu w form.contacts
            const index = this.contactList.indexOf(contactPerson);
            if (index !== -1) {
              this.contactList.splice(index, 1);
            }

          })
          .catch(error => {
            // Obsługa błędu
            console.error('Błąd podczas usuwania kontaktu:', error);
          })
          .finally(() => {
            this.isAdding = false;
            this.isProcessing = false;
          });
    },

    updateContact(contactPerson) {
      axios.post(route('customer.contact-person.update', contactPerson.id), contactPerson)
          .then(response => {
            Toast.fire({
              toast: true,
              icon: response.data.icon,
              title: response.data.title,
              html: response.data.message,
              showClass: {popup: 'animate__animated animate__fadeInDown'},
              hideClass: {popup: 'animate__animated animate__fadeOutUp'},
              timer: 2500,
            });

            // Aktualizuj dane kontaktu w form.contacts
            const index = this.contactList.findIndex(c => c.id === contactPerson.id);
            if (index !== -1) {
              contactPerson.isEditing = false;
              this.contactList[index] = {...contactPerson}; // Sklonuj obiekt
            }

          })
          .catch(error => {
            // Obsługa błędu
            console.error('Błąd podczas dodawania kontaktu:', error);
          })
          .finally(() => {
            this.isAdding = false;
            this.isEditing = false;
            this.isProcessing = false;
          });
    },
  }
}
</script>

<style scoped>

</style>