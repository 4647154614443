<template>
    <AppLayout>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-six">

                <div class="widget-heading">
                    <div class="row">
                        <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
                            <h4 class="me-3 my-0">Gwarancja</h4>
                        </div>
                    </div>
                </div>

                <div class="widget-content">

                    <div class="mt-4">

                        <div class="custom-table">
                            <div class="table-responsive">
                                <table id="table-projects-warranty" class="table table-hover">
                                    <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Nazwa projektu</th>
                                        <th>Klient</th>
                                        <th>Numer</th>
                                        <th>Data zakończenia SAT</th>
                                        <th>Odbiór warunkowy</th>
                                        <th>Pkt otwarte z odbioru</th>
                                        <th>Data zamknięcia pkt otwartych</th>
                                        <th>Kierownik projektu</th>
                                        <th>Długość gwarancji</th>
                                        <th>Projekt na gwarancji</th>
                                        <th>Miejsce dostawy</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="modalOpenPoints" tabindex="-1" role="dialog" aria-labelledby="OtwartePunkty" aria-hidden="true">
            <div class="modal-dialog modal-full-width" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Otwarte punkty <span class="d-block small text-muted">Przypisane zadania do projektu ze statusem innym niż <i>zrobiono</i></span>
                        </h5>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body pt-1">
                        <div v-if="selectedProject">
                            <Link class="text-success" :href="route('projects.show.tasks', selectedProject)"> Przejdź do wszystkich zadań</Link>
                            <div class="mt-4">
                                <h4>Zadania</h4>
                                <ProjectsTasksGrid
                                    :notDoneOnly="true"
                                    :projectId="selectedProject"
                                    @updateTasks="handleUpdateTasks">
                                </ProjectsTasksGrid>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
                    </div>
                </div>
            </div>
        </div>

    </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import {usePage} from "@inertiajs/vue3";
import moment from 'moment';
import ProjectsTasksGrid from "@/Pages/Projects/Components/ProjectsTasksGrid.vue";
import Modal from "bootstrap/js/dist/modal.js";

export default {
    components: {ProjectsTasksGrid, AppLayout},

    data() {
        return {
            table: null,
            projects: usePage().props.projects,
            selectedProject: null
        }
    },

    mounted() {
        if (!this.table) {
            this.initDataTable();
            this.initFilters();
        }

        let self = this;
        let tab = document.getElementById('table-projects-warranty');
        $(tab).on('click', '.modal-openTask', async function () {
            self.selectedProject = $(this).data('project');
            let modalTasks = new Modal(document.getElementById('modalOpenPoints'));
            modalTasks.show();
        });
    },
    beforeDestroy() {
        this.table.destroy();
    },

    methods: {

        handleUpdateTasks(r) {
            if (r === true) {
                this.table.clear().draw();
            }
        },

        initDataTable() {
            const self = this;
            const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;

            this.table = $(document.getElementById('table-projects-warranty')).DataTable({
                    colReorder: true,
                    stateSave: true,
                    serverSide: true,
                    processing: true,
                    ajax: {
                        url: '/projects/projects-warranty/get-warranties', // endpoint do pobierania danych z serwera
                        type: 'GET',
                        data: function (params) {
                            params.additional_param = 'value'; // Dodaj dodatkowe parametry, jeśli są potrzebne
                            return params;
                        },
                        dataSrc: 'data',
                    },
                    stateSaveCallback: function (settings, data) {
                        $.ajax({
                            url: '/save-column-data?table=table-projects-warranty',
                            data: data,
                            type: 'POST',
                            dataType: 'json',
                            headers: {
                                'X-CSRF-TOKEN': csrfToken, // Include the CSRF token in the headers
                            },
                            "success": function () {
                                const datTab = $('.dataTable');
                                // generuj link do wersji oferty
                                let link = datTab.find('a[data-edit]');
                                link.on('click', function (event) {
                                    event.preventDefault();
                                    const id = $(this).data('edit');
                                    self.generateEditProjectLink(id);
                                });

                            }
                        });
                    },
                    stateLoadCallback: function (settings) {
                        let o;
                        $.ajax({
                            url: '/get-table-data?table=table-projects-warranty',
                            async: false,
                            dataType: 'json',
                            success: function (json) {
                                o = json;
                            }
                        });
                        let elem = this;
                        self.initColumnSearch(elem, o)
                        return o;
                    },
                    columns: [
                        {
                            title: '#',
                            name: 'id',
                            data: function (d) {
                                return '<a class="text-primary" href="#" data-edit="' + d.id + '">' + d.id + '</a>';
                            },
                        },
                        {
                            name: 'name',
                            data: function (d) {
                                return '<a class="text-primary" href="#" data-edit="' + d.id + '">' + d.name + '</a>';
                            },
                        },
                        {data: 'customer'},
                        {data: 'number'},
                        {data: 'planned_completion_date'},
                        {
                            name: 'conditional_acceptance',
                            class: 'text-center',
                            data: function (d) {
                                if (d.conditional_acceptance !== 1) {
                                    return '<span class="text-success">NIE</span';
                                }

                                return '<span class="text-warning">TAK</span';
                            }
                        },
                        {
                            name: 'open_points',
                            data: function (d) {
                                if (!d.open_points || d.open_points.length === 0) {
                                    return 'brak';
                                }

                                if (d.conditional_acceptance !== 1) {
                                    return '-';
                                }

                                let tasks = '';
                                for (let i = 0; i < Math.min(3, d.open_points.length); i++) {
                                    if (d.open_points[i].status !== 'zrobiono') {
                                        let t = '<span class="font-weight-bolder">' + (d.open_points[i].task ?? 'B/D') + '</span>';
                                        let p = d.open_points[i].person_email ?? 'B/D';

                                        tasks += '<div class="text-nowrap">' + t + ' - ' + p + '</div>';
                                    }
                                }
                                let showAll = '<div>' +
                                    '<button type="button" class="modal-openTask btn btn-xs btn-light-default" data-project="' + d.id + '">' +
                                    '<i class="bi bi-three-dots"></i>' +
                                    '</button>' +
                                    '</div>';

                                return tasks + showAll;
                            }
                        },
                        {
                            name: 'date_closed_open_points',
                            data: function (d) {
                                if (!d.open_points || d.open_points.length === 0) {
                                    return '-';
                                }

                                let openPoints = [];
                                let hasMoreOpenPoints = false;
                                for (let i = 0; i < Math.min(3, d.open_points.length); i++) {
                                    if (d.open_points[i].status !== 'zrobiono') {
                                        hasMoreOpenPoints = true;
                                    } else {
                                        openPoints.push(d.open_points[i].updated_at);
                                    }
                                }

                                if (hasMoreOpenPoints === true) {
                                    // istnieją jeszcze punkty otwarte
                                    return '-';
                                }


                                if (openPoints.length > 0) {
                                    // już nie ma punktów otwartych
                                    openPoints.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
                                    let lastUpdatedAt = openPoints.length > 0 ? openPoints[0] : null;

                                    if (lastUpdatedAt)
                                        return moment(lastUpdatedAt).format('YYYY-MM-DD');
                                }

                                return '';
                            }
                        },
                        {data: 'project_manager'},
                        {data: 'warranty_length'},
                        {
                            name: 'warranty_to_date',
                            class: 'text-center text-nowrap',
                            data: function (d) {
                                let warrantyDate = new Date(d.warranty_to_date);
                                if (warrantyDate >= new Date()) {
                                    return moment(warrantyDate).format('YYYY-MM-DD');
                                }

                                return '<i class="bi bi-x" style="font-size:20pt;color: orangered"></i>'
                            }
                        },
                        {data: 'delivery_address'}


                    ],

                    createdRow: function (row, data, dataIndex) {
                        // po kliknieciu w 'td' przenies do edycji customera
                        const link = $(row).find('a[data-edit]');
                        link.on('click', function (event) {
                            event.preventDefault();
                            const id = $(this).data('edit');
                            self.generateEditProjectLink(id);
                        });
                    },
                },
            );
        },

        initColumnSearch(elem, o) {
            setTimeout(() => {
                let api = elem.api();
                api.columns().eq(0).each((colIdx) => {
                    let cell = document.querySelectorAll('.filters td')[api.column(colIdx).header().cellIndex];
                    let titleCell = document.querySelectorAll('th')[api.column(colIdx).header().cellIndex];
                    let searchText = o.columns[colIdx].search.search;
                    let cleanSearchText = (typeof searchText === 'string') ? searchText.replace(/[()]/g, '') : '';

                    if (cleanSearchText) {
                        cell.innerHTML = `<input type="text" value="${cleanSearchText}" />`;
                    } else {
                        let title = titleCell.textContent;
                        cell.innerHTML = `<input type="text" placeholder="${title}" />`;
                    }

                    // Dodaj klasy z nagłówka kolumny do komórki
                    cell.classList.add(...titleCell.className.split(' ').filter(cls => cls !== 'sorting'));

                    let input = cell.querySelector('input');
                    input.removeEventListener('change', handleInputChange);
                    input.addEventListener('change', handleInputChange);
                    input.removeEventListener('keyup', handleInputChange);
                    input.addEventListener('keyup', handleInputChange);
                });

                function handleInputChange(e) {
                    e.target.setAttribute('title', e.target.value);
                    api.column(e.target.parentNode.cellIndex).search(e.target.value).draw();
                }
            }, 500);
        },

        initFilters() {
            const headerRow = document.querySelector('#table-projects-warranty thead tr');
            const inputRow = document.createElement('tr');

            Array.from(headerRow.querySelectorAll('th')).forEach(() => {
                const inputCell = document.createElement('td');
                const input = document.createElement('input');
                input.type = 'text';
                input.placeholder = 'Filter...';

                inputCell.classList.add('filters');
                inputCell.appendChild(input);
                inputRow.classList.add('filters');
                inputRow.appendChild(inputCell);
            });

            headerRow.after(inputRow);
        },


        generateEditProjectLink(id) {
            this.$inertia.visit(route('projects.show', {project: id}));
        }
    }
}
</script>

<style>
#table-projects-warranty tbody tr td {
    background-color: transparent !important;
}
</style>

