<script setup>
import AppLayout from "@/Layouts/App.vue";
import {router, useForm} from "@inertiajs/vue3";
import {nextTick, onMounted, ref} from "vue";

const props = defineProps({
  'category': Object
})

const form = useForm({
  id: props.category.id,
  name: props.category.name
})

const dataTable = ref(null);
let hasCustomers = ref(null);

onMounted(async () => {
  await nextTick();

  $(dataTable.value).DataTable({
    colReorder: true,
    stateSave: true,
    serverSide: true,
    processing: true,
    ajax: {
      url: '/get-customers-datatables', // endpoint do pobierania danych z serwera
      type: 'GET',
      data: function (params) {
        params.categoryId = props.category.id; // Dodaj dodatkowe parametry, jeśli są potrzebne
        return params;
      },
      dataSrc: function (json) {
        // Sprawdzamy liczbę wierszy w odpowiedzi
        hasCustomers.value = json.data.length > 0 ? true : false;
        return json.data;
      }
    },

    columns: [
      {
        data: 'id',
        title: '#',
        name: 'id',
        width: "5%",
        isUnique: true,
        render: function (data) {
          return '<a class="text-primary" href="#" data-edit="' + data + '">' + data + '</a>';
        },
      },
      {
        data: 'name',
        name: 'name',
        title: 'Nazwa',
        width: "25%",
        render: function (data, idx, row) {
          return '<a class="text-primary" href="#" data-edit="' + row.id + '">' + data + '</a>';
        },
      },
      {data: 'short_name', title: 'Nazwa skrócona', width: "15%"},
      {
        data: 'recipient',
        title: 'Odbiorca',
        width: "5%",
        render: function (d) {
          if (d === 1) {
            return '<span class="d-none">1</span><i class="bi bi-check text-success fs-2"></i>';
          }

          return '<span class="d-none">0</span><i class="bi bi-x text-danger fs-2"></i>';
        }
      },
      {
        data: 'supplier',
        title: 'Dostawca',
        width: "5%",
        render: function (d) {
          if (d === 1) {
            return '<span class="d-none">1</span><i class="bi bi-check text-success fs-2"></i>';
          }

          return '<span class="d-none">0</span><i class="bi bi-x text-danger fs-2"></i>';
        }
      },
      {data: 'nip', title: 'Nip', "type": "number", width: "5%"},
      {
        data: function (row) {
          return row.customers_data?.zip_code ?? ''
        },
        name: 'customers_data.zip_code',
        title: 'Kod pocztowy',
        width: "10%",
      },
      {
        data: function (row) {
          let address = row.customers_data?.address ?? ''
          let address2 = row.customers_data?.address2 ?? ''
          return address + ' ' + address2;
        },
        name: "customers_data.concatAddress",
        title: 'Adres',
        width: "25%",
      },
    ],

    createdRow: function (row, data, dataIndex) {
      // po kliknieciu w 'td' przenieś do edycji customera / customer-category
      let link = $(row).find('a[data-edit]');
      let linkCategory = $(row).find('a[data-edit-category]');

      if (link) {
        link.on('click', function (event) {
          event.preventDefault();
          const id = $(this).data('edit');
          generateEditCustomerLink(id);
        });
      }
      if (linkCategory) {
        linkCategory.on('click', function (event) {
          event.preventDefault();
          const id = $(this).data('edit-category');
          generateEditCustomerCategoryLink(id);
        });
      }
    },
  });

  const generateEditCustomerLink = (id) => {
    router.visit(route('customers.show', {customer: id}));
  };
  const generateEditCustomerCategoryLink = (id) => {
    router.visit(route('customers-categories.show', {category: id}));
  };

  const headerRow = $(dataTable.value).find('thead tr');
  const inputRow = document.createElement('tr');

  headerRow.find('th').each(function (index) {
    const inputCell = document.createElement('td');
    const input = document.createElement('input');
    input.type = 'text';
    input.placeholder = 'Filter...';

    $(inputCell).addClass('filters').css('max-width', '30px');
    $(inputCell).addClass('filters').append(input);
    $(inputRow).addClass('filters').append(inputCell);
  });

  headerRow.after(inputRow);
})

</script>

<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <div class="row w-100">
            <div class="col-12 d-inline-flex align-items-center">

              <h4 class="me-3 my-0">kategoria: {{ category.name }}</h4>
              <form class="ms-auto" @submit.prevent="form.delete(route('customers-categories.delete', form))">
                <button class="btn btn-sm btn-danger" :disabled="hasCustomers">
                  <span
                      v-if="hasCustomers">Nie można usunąć kategorii z przypisanymi kontrahentami</span>
                  <span v-else>Usuń kategorię</span>
                </button>
              </form>

            </div>
          </div>
        </div>

        <div class="widget-content">
          <div class="row">
            <div class="col-md-12 col-lg-3">
              <form @submit.prevent="form.put(route('customers-categories.update', form))">
                <div class="input-group align-items-center">
                  <label for="categoryName">Nazwa: &nbsp;</label>
                  <input type="text" class="form-control form-control-sm" v-model="form.name">
                </div>

                <div class="text-center mt-2">
                  <button type="submit" class="btn btn-primary btn-sm" :disabled="!form.isDirty">Aktualizuj</button>
                </div>
              </form>
            </div>

            <div class="row mt-5">
              <div class="col-md-12">
                <h3>Spis kontrahentów przypisanych do tej kategorii</h3>
                <div class="custom-table table-responsive">
                  <table ref="dataTable" class="table table-striped"></table>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </AppLayout>
</template>