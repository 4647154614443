<template>
    <AppLayout>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-six">

                <projectMenu :project="project"></projectMenu>

                <div class="widget-content">
                    <div v-if="$page.props.errors">
                        <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
                            <li class="alert alert-danger">{{ error }}</li>
                        </ul>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 col-lg-12">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import projectMenu from "@/Pages/Projects/parts/projectMenu.vue";


export default {
    components: {AppLayout, projectMenu},
    props: {
        project: Object
    },
    setup() {
        // console.log(props.project);
    },
    methods: {
        route,
        isRouteActive(routeName) {
            return route().current() === routeName;
        },
    },
}
</script>
