<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">

        <projectMenu :project="project"></projectMenu>

        <div class="widget-content">
          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <!-- TABELA Dostawców -->

          <div class="card-body">
            <div class="mb-3 position-relative">
              <div class="text-end">
                <ProjectsHistory
                    :project="project"
                    :model="'SelectedSupplierHistory'"
                ></ProjectsHistory>
              </div>
              <div class="mb-1">
                <ProjectsSuppliers v-if="hasPermission('canEditAdvancedProject')"
                                   :project='project'
                                   :projectSuppliers="projectSuppliers"
                                   @projectSuppliersUpdated="updateProjectSuppliers"
                                   :customerCategories="customerCategories"
                                   :customersDeliverers="customersDeliverers"
                ></ProjectsSuppliers>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-lg-12">
                <ProjectSuppliersTable
                    :project="project"
                    :projectSuppliers="projectSuppliers"
                    :shouldApprovalSelectedSuppliers="shouldApprovalSelectedSuppliers"
                    :authUser="authUser"
                ></ProjectSuppliersTable>
              </div>
            </div>
          </div>

          <!-- TABELA Decyzji -->

          <div class="card-body mt-5">

            <div class="mb-3 position-relative">
              <div class="mb-3 text-end">
                <ProjectsHistory
                    :project="project"
                    :model="'SelectedDecisionHistory'"
                ></ProjectsHistory>
              </div>
              <div class="mb-1" v-if="hasPermission('canEditAdvancedProject')">
                <ProjectsDecisions :projectDecisions="projectDecisions"
                                   :updateProjectDecisions="updateProjectDecisions"></ProjectsDecisions>
              </div>
            </div>
            <div>
              <ProjectsDecisionsTable
                  :authUser="authUser"
                  :project="project"
                  :projectDecisions="projectDecisions"
                  :selectedDecisions="selectedDecisions"
                  :selectedDecisionsByCategory="selectedDecisionsByCategory"
                  :shouldApprovalSelectedDecisions="shouldApprovalSelectedDecisions"
              ></ProjectsDecisionsTable>
            </div>
          </div>
        </div>
      </div>
    </div>

  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import projectMenu from "@/Pages/Projects/parts/projectMenu.vue";
import ProjectSuppliersTable from '@/Pages/Projects/Components/ProjectSuppliersTable.vue';
import ProjectsSuppliers from '@/Pages/Projects/Components/ProjectsSuppliers.vue';
import ProjectsDecisionsTable from '@/Pages/Projects/Components/ProjectsDecisionsTable.vue';
import ProjectsDecisions from '@/Pages/Projects/Components/ProjectsDecisions.vue';
import ProjectsHistory from "@/Pages/Projects/Components/ProjectsHistory.vue";
import {usePermission} from "@/composables/resources/js/composables/permissions.js";

const {hasPermission} = usePermission();
export default {
  components: {
    hasPermission,
    AppLayout,
    projectMenu,
    ProjectsSuppliers,
    ProjectsDecisions,
    ProjectsHistory,
    ProjectSuppliersTable,
    ProjectsDecisionsTable
  },
  props: {
    project: Object,
    groupedSuppliers: Object,
    shouldApprovalSelectedSuppliers: Object,
    customerCategories: Object,
    customersDeliverers: Object
  },
  data() {

    return {
      hasPermission,
      projectSuppliers: this.groupedSuppliers,

      defaultValues: this.$page.props.selectedValues,
      authUser: this.$page.props.authUser,
      // selectedSuppliers: this.$page.props.selectedSuppliers,
      // selectedSuppliersByCategory: this.$page.props.selectedSuppliersByCategory,
      // shouldApprovalSelectedSuppliers: this.$page.props.shouldApprovalSelectedSuppliers,
      shouldApprovalSelectedDecisions: this.$page.props.shouldApprovalSelectedDecisions,
      // projectSuppliers: this.$page.props.projectSuppliers,
      projectDecisions: this.$page.props.projectDecisions,
      selectedDecisions: this.$page.props.selectedDecisions,
      selectedDecisionsByCategory: this.$page.props.selectedDecisionsByCategory,
      editing: {},
      editedValues: {},
      isDirty: false,
      isProcessing: false,
    };
  },

  methods: {
    route,
    isRouteActive(routeName) {
      return route().current() === routeName;
    },
    updateProjectSuppliers() {
      // przy aktualizacji projectSuppliers oraz onLoad
      const self = this;
      axios.post(route('getProjectSuppliersGrouped', {project: this.project.id})).then(response => {
        self.projectSuppliers = response.data;
      });
    },

    updateProjectDecisions() {
      // przy aktualizacji projectDecisions oraz onLoad
      const self = this;
      axios.post(route('getProjectDecisionsGrouped')).then(response => {
        self.projectDecisions = response.data;
      });
    },
  },
}
</script>
