import pl from './pl.js'
import bootstrap from '@vueform/vueform/dist/bootstrap'
import { defineConfig } from '@vueform/vueform'
import PluginMask from '@vueform/plugin-mask'

export default defineConfig({
    theme: bootstrap,
    locales: { pl },
    locale: 'pl',
    plugins:[
        PluginMask
    ]
})