<template>
  <button type="button" class="btn btn-sm btn-outline-primary"
          data-bs-toggle="modal"
          data-bs-target="#customTaskModal">
    Niestandardowe zadania
  </button>


  <div class="modal fade" id="customTaskModal" tabindex="-1" aria-labelledby="customTaskModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="customTaskModalLabel">Niestandardowe zadania</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="checkForm()">
          <div class="modal-body">

            <div>
              <label class="form-label">Nazwa Projektu*</label>
              <input class="form-control form-control-sm" v-model="newCustomProject.ProjectName">
              <div id="newCustomProjectName" class="text-danger">
                {{ $page.props.errors.ProjectName }}
              </div>
            </div>
            <div>
              <label class="form-label me-2">Aktywny</label>
              <input type="checkbox" class="checkbox-default" v-model="newCustomProject.active">
              <div class="text-danger" v-if="$page.props.errors.active">
                {{ $page.props.errors.active }}
              </div>
            </div>
            <div>
              <label class="form-label">Kolor tła</label>
              <ejs-colorpicker v-model="newCustomProject.bg_color"></ejs-colorpicker>
              <div class="text-danger" v-if="$page.props.errors.bg_color">
                {{ $page.props.errors.bg_color }}
              </div>
            </div>
            <div>
              <label class="form-label">Kolor czcionki</label>
              <ejs-colorpicker v-model="newCustomProject.color"></ejs-colorpicker>
              <div class="text-danger" v-if="$page.props.errors.color">
                {{ $page.props.errors.color }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" :disabled="newCustomProject.processing" class="btn btn-sm btn-primary"><i
                class="bi bi-save me-2"></i>Dodaj nowy
            </button>
          </div>
        </form>

        <div class="table-responsive px-4 py-2">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th>Nazwa</th>
              <th>Aktywny</th>
              <th>Kolor tła</th>
              <th>Kolor czcionki</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="project in projects" :key="project.id">
              <td class="p-2">
                <input v-if="isEditing(project)" v-model="project.TaskName" class="form-control form-control-sm"/>
                <span v-else>{{ project.TaskName }}</span>
              </td>
              <td class="p-2">
                <div v-if="isEditing(project)">
                  <input type="checkbox" :checked="project.active === true || project.active === 1"
                         @change="project.active = $event.target.checked"/>
                </div>
                <div v-else>
                  <template v-if="project.active === true || project.active === 1">
                    <i class="bi bi-check-circle text-success"></i>
                  </template>
                  <template v-else>
                    <i class="bi bi-x-circle text-danger"></i>
                  </template>
                </div>
              </td>
              <td class="p-2">
                <div v-if="isEditing(project)">
                  <ejs-colorpicker v-model="project.bg_color"></ejs-colorpicker>
                </div>
                <span v-else>{{ project.bg_color }}</span>
              </td>
              <td class="p-2">
                <div v-if="isEditing(project)">
                  <ejs-colorpicker v-if="isEditing(project)" v-model="project.color"></ejs-colorpicker>
                </div>
                <span v-else>{{ project.color }}</span>
              </td>
              <td class="text-center">
                <button v-if="isEditing(project)" type="button" class="btn btn-sm btn-outline-primary"
                        @click="saveProject(project)" :disabled="isProcessing">
                  <i class="bi bi-save me-2"></i> Zapisz
                </button>
                <button v-else type="button" class="btn btn-sm btn-outline-warning" @click="editProject(project)">
                  <i class="bi bi-pencil-square"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useForm} from "@inertiajs/vue3";
import {ColorPickerComponent as EjsColorpicker} from "@syncfusion/ej2-vue-inputs";

export default {
  components: {EjsColorpicker},
  props: {
    customProjects: Object
  },

  data() {
    return {
      projects: this.customProjects,
      newCustomProject: new useForm({
        ProjectName: null,
        bg_color: '#fff',
        color: '#000',
        active: true
      }),
      editProjectId: null,
      isProcessing: false,
    }
  },

  methods: {
    checkForm() {
      if (this.newCustomProject.ProjectName === '' || this.newCustomProject.ProjectName === null) {
        document.getElementById('newCustomProjectName').innerHTML = 'Nazwa projektu nie może być pusta';
        return false;
      }

      this.newCustomProject.post(route('resource-schedule-custom-projects.store'), {
        only: ['availableCustomProjectsArr'],
        onSuccess: () => {
          this.projects = this.customProjects;
          this.newCustomProject.reset();
        }
      });
    },

    editProject(project) {
      this.editProjectId = project.id;
    },

    saveProject(project) {
      this.isProcessing = true;
      const form = useForm({
        name: project.TaskName,
        bg_color: project.bg_color,
        color: project.color,
        active: project.active
      });

      form.put(route('resource-schedule-custom-projects.update', project.TaskID), {
        only: ['availableCustomProjectsArr'],
        onSuccess: () => {
          this.projects = this.customProjects;
          this.editProjectId = null;
        },
        onFinish: () => {
          this.isProcessing = false;
        }
      });
    },

    isEditing(project) {
      return this.editProjectId === project.id;
    }
  }
}

</script>