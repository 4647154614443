<template></template>

<script setup>
import {watch} from "vue";
import {usePage} from "@inertiajs/vue3";
import 'sweetalert2/dist/sweetalert2.min.css'

watch(() => usePage().props.flash, async (messages) => {
    let flash = messages;
    if (flash.success) {
        showAlert('success', 'Sukces!', flash.success);
    }
    if (flash.error) {
        showAlert('error', 'Błąd!', flash.error);
    }
    if (flash.info) {
        showAlert('info', 'Informacja', flash.info);
    }

}, {
    immediate: true,
    deep: true
})

function showAlert(icon, title, message, timer = 2500) {
    Toast.fire({
        position: 'top-end',
        toast: true,
        icon: icon,
        title: title,
        html: message,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: timer,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
    });
}

</script>
