<template>
    <div class="form auth-boxed">
        <div class="form-container outer">
            <div class="form-form">
                <div class="form-form-wrap">
                    <div class="form-container">
                        <div class="form-content">
                            <h1 class="">Resetowanie hasła</h1>

                            <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
                            <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>


                            <form class="text-start" @submit.prevent="resetPassword">

                                <div class="form">
                                    <div id="password-field">
                                        <label for="password">Nowe hasło</label>
                                        <input v-model="formData.password" type="password" id="password" class="form-control"/>
                                    </div>
                                    <div id="password-confirmation-field" class="mt-3">
                                        <label for="password-confirmation">Powtórz hasło</label>
                                        <input v-model="formData.password_confirmation" type="password" id="password-confirmation" class="form-control"/>
                                    </div>

                                    <div class="d-sm-flex justify-content-between mt-4">
                                        <div class="field-wrapper">
                                            <button type="submit" :disabled="processing" class="btn btn-primary">Zaktualizuj hasło</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Link} from "@inertiajs/vue3";
import route from "../../../../vendor/tightenco/ziggy/src/js/index.js";

export default {
    components: {Link},
    props: {
        email: String,
        token: String,
    },
    data() {
        return {
            processing: false,
            successMessage: '',
            errorMessage: '',
            formData: {
                email: this.email,
                token: this.token,
                password: '',
                password_confirmation: ''
            },
        };
    },
    methods: {
        async resetPassword() {
            this.successMessage = '';
            this.errorMessage = '';
            this.processing = true;
            let self = this;

            if (this.formData.password !== this.formData.password_confirmation) {
                this.successMessage = '';
                this.errorMessage = 'Hasła muszą być takie same';

                return false;
            }

            try {
                axios.post(route('password.store-new-password'), this.formData).then(response => {
                    if (response.data.error)
                        this.errorMessage = response.data.error;
                    else {
                        this.successMessage = response.data.success;
                        setTimeout(function (){
                            self.$inertia.visit('/');
                        }, 2000)
                    }
                }).catch(error => {
                    this.successMessage = '';
                    this.errorMessage = error.response.data.message;
                    console.log(error.response);
                }).finally(() => {
                    this.processing = false;
                });
            } catch (error) {
                // Obsługa błędów
                console.error(error);
                this.successMessage = '';
                this.errorMessage = 'Wystąpił błąd podczas wysyłania żądania resetowania hasła.';
                this.processing = false;
            }
        }
    }
};
</script>
