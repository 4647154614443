<template>
    <div>
        <label class="me-1 form-label">{{ label }}: </label>
        <button type="button" class="btn btn-xs btn-outline-primary mb-1" @click="toggleVisibility('select_'+modelName)">
            <i class="bi" :class="{'bi-eye-fill':hiddenEditor, 'bi-eye-slash':!hiddenEditor}" style="font-size: 14px"></i>
        </button>

        <select :id="'select_'+modelName" v-model="localModelText" class="form-select form-select-sm" v-bind="attributes">
            <option v-for="option in options" :key="Object.keys(option)[0]" :value="Object.keys(option)[0]">
                {{ Object.values(option)[0] }}
            </option>
        </select>

    </div>
</template>

<script>

export default {
    props: {
        label: String,
        modelName: String,
        modelText: String | Number,
        language: String,
        hidden: Boolean,
        options: Object,
        attributes: Object
    },

    data() {

        return {
            localModelName: this.modelName,
            localModelText: this.modelText,
            localLabel: this.label,
            hiddenEditor: this.hidden
        }
    },

    methods: {
        toggleVisibility(el) {
            let elem = document.getElementById(el);
            this.hiddenEditor = !this.hiddenEditor;
            if (this.hiddenEditor)
                elem.classList.add('d-none')
            else
                elem.classList.remove('d-none')
        },
    },

    watch: {
        localModelText(newVal) {
            this.$emit('updateModel', {
                modelName: this.modelName,
                value: newVal,
            });
        }
    }
}
</script>
