<template>
  <div class="btn-group align-bottom">
    <button type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-download"></i> Pobierz&nbsp;<i class="bi bi-chevron-down"></i>
    </button>
    <ul class="dropdown-menu">
      <li><button type="button" class="dropdown-item" @click="generatePDF('pl')">PL</button></li>
      <li><button type="button" class="dropdown-item" @click="generatePDF('en')">EN</button></li>
      <li><button type="button" class="dropdown-item" @click="generatePDF('de')">DE</button></li>
    </ul>
  </div>
</template>

<script>
import route from "../../../vendor/tightenco/ziggy/src/js/index.js";
import tinymce from "tinymce";

export default {
    props: {
        formData: Object,
    },
    data() {
        return {
            generatingPDF: false,
        };
    },
    methods: {
        async generatePDF(lang) {
            if (this.generatingPDF) {
                return; // aby uniknąć wielu requestów
            }
            this.generatingPDF = true;

            try {
                const data = this.prepareData(lang);
                const response = await this.postPDFData(data);

                this.downloadPDF(response, data);
            } catch (error) {
                console.error('Błąd podczas generowania pliku PDF:', error);
            } finally {
                this.generatingPDF = false;
            }
        },
        prepareData(lang) {
            // przygotowanie danych do wysłania
            return {
                ...this.formData,
                lang,
            };
        },
        async postPDFData(data) {
            return await axios.post(route('orderCardPdf.download'), data, {
                responseType: 'arraybuffer',
            });
        },
        downloadPDF(response, data) {
            // przygotowanie PDF do pobrania
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const downloadLink = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            const customerCompany = data.customer_idy !== null ? data.customer_id : '00';
            downloadLink.href = url;
            downloadLink.download = `karta-zlecenia-${customerCompany}_${data.lang ?? 'pl'}.pdf`;
            document.body.appendChild(downloadLink);
            downloadLink.click();

            window.URL.revokeObjectURL(url);
        },
    },
};
</script>
