<template>
  <div>
    <label class="me-1 form-label">{{ label }}: </label>
    <button type="button" class="btn btn-xs btn-outline-primary mb-1" @click="toggleVisibility">
      <i class="bi" :class="{'bi-eye-fill': hiddenEditor, 'bi-eye-slash': !hiddenEditor}" style="font-size: 14px"></i>
    </button>

    <Vueform>
      <TextElement
          ref="textInput"
          :id="'input_' + modelName"
          :name="modelName"
          v-model="localModelText"
          :default="localModelText"
          :input-type="localType"
          v-bind="attributes"
          :mask="type === 'price' ? { mask: 'number', thousandsSeparator: '.', scale: 2 } : null"
          @change="changeValue"
      />
    </Vueform>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    label: String,
    modelName: String,
    modelText: String | Number | Date,
    type: String,
    language: String,
    hidden: Boolean,
    attributes: Object
  },

  data() {

    return {
      localModelName: this.modelName,
      localModelText: this.type === 'price' ? this.formatPrice(this.modelText) : this.modelText,
      localLabel: this.label,
      localType: this.type === 'price' ? 'text' : this.type,
      hiddenEditor: this.hidden,
      initialLoad: true
    }
  },

  methods: {
    toggleVisibility() {
      this.hiddenEditor = !this.hiddenEditor;
      const inputElement = this.$refs.textInput.$el;

      if (this.hiddenEditor) {
        inputElement.classList.add('d-none');
      } else {
        inputElement.classList.remove('d-none');
      }
    },

    changeValue(newVal) {
      this.localModelText = newVal.replace(/\./g, '');
    },

    formatPrice(price) {
      if (typeof price === 'number' && !isNaN(price) || !price)
        return price;

      return price.replace('.', ',');
    }
  },

  watch: {
    modelText(newVal) {
      this.localModelText = newVal;
    },

    localModelText(newVal) {
      // Sprawdzamy, czy to nie jest początkowe ustawienie dla price
      // trzeba tak zrobić, price zmienia się onload (przez maskę) i od razu jest emitowany
      if (this.initialLoad && this.type === 'price') {
        this.initialLoad = false;
      } else {
        const newValue = typeof newVal === 'string' ? newVal.replace(/\./g, '') : newVal;

        this.$emit('updateModel', {
          modelName: this.modelName,
          value: newValue,
        });
      }
    }
  }
}
</script>
