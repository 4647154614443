<template>
  <form @submit.prevent="submitForm2" ref="projectForm">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>Zagadnienie</th>
        <th>Decyzja</th>
        <th>Uwagi</th>
        <th>Zatwierdzenia</th>
        <th>Data zatwierdzenia</th>
        <th>Ostatnia aktualizacja</th>
      </tr>
      </thead>
      <tbody>

      <tr v-for="(decisions) in projectDecisions">
        <td>
          {{ decisions[Object.keys(decisions)[0]][0].categoryDisplayName }}
        </td>
        <td v-for="decisionData in decisions">
          <div v-for="decision in decisionData">
            <div class="checkbox-success custom-control custom-checkbox" v-if="!decision.parent">
              <input type="checkbox"
                     class="custom-control-input"
                     ref="decisionId"
                     :id="'decision_'+decision.id"
                     :value="decision.id"
                     :data-category="decision.category"
                     @change="changeFormData2(decision.category, decision.id)">
              <label class="custom-control-label" :for="'decision_'+decision.id">{{ decision.name }}</label>
            </div>

            <div v-for="decisionChild in decisionData">
              <div class="ms-3 checkbox-success custom-control custom-checkbox custom-checkbox-sm"
                   v-if="decisionChild.parent && decisionChild.parent === decision.id">
                <input type="checkbox"
                       class="custom-control-input"
                       ref="decisionId"
                       :id="'decision_'+decisionChild.id"
                       :value="decisionChild.id"
                       :data-category="decisionChild.category"
                       @change="changeFormData2(decisionChild.category, decisionChild.id)">
                <label class="custom-control-label custom-control-label-sm small"
                       :for="'decision_'+decisionChild.id">{{ decisionChild.name }}</label>
              </div>
            </div>

          </div>
        </td>

        <td @dblclick="editComment2(selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0]?.comments)"
            @change="changeFormData2(decisions[Object.keys(decisions)[0]][0].category, 'comments', 'comments')">
          {{ selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0]?.comments }}
        </td>

        <td>
          <div v-for="(name, position) in loggedUserNeedApprove">
            <div class="checkbox-success custom-control custom-checkbox text-color">
              <input type="checkbox" class="custom-control-input"
                     :id="'defaultSwitch_'+position+selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0].id"
                     :checked="isApproved2(selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0], position)"
                     @change="changeFormData2(decisions[Object.keys(decisions)[0]][0].category, position, 'approvals')">
              <label class="custom-control-label"
                     :for="'defaultSwitch_'+position+selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0].id">{{
                  name
                }}</label>
            </div>
          </div>

          <div class="text-muted small" v-for="(position, approvalUser) in shouldApprovalSelectedDecisions">
            <div class="text-success"
                 v-if="isApproved2(selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0], approvalUser) === true">
              <i class="bi bi-check-circle"></i> {{ position }}
            </div>
            <div class="text-danger" v-else>
              <i class="bi bi-x-circle"></i> {{ position }}
            </div>
          </div>

        </td>

        <td @dblclick="editApprovalDate2(selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0]?.approval_date)"
            @change="changeFormData2(decisions[Object.keys(decisions)[0]][0].category, 'approval_date', 'approval_date')">
          {{ selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0]?.approval_date }}
          <br> {{
            selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0]?.approval_by?.first_name
          }}&nbsp;{{
            selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0]?.approval_by?.last_name
          }}

        </td>

        <td>
          {{ selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0]?.updated_at }}<br> {{
            selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0]?.updated_by?.first_name
          }}&nbsp;{{
            selectedDecisionsByCategory[decisions[Object.keys(decisions)[0]][0]?.category]?.[0]?.updated_by?.last_name
          }}
        </td>
      </tr>
      </tbody>
    </table>

    <button type="submit" v-if="this.shouldApprovalSelectedDecisions && isDirty2" :disabled="isProcessing2"
            class="btn btn-primary">Zaktualizuj
    </button>
  </form>

</template>


<script>


import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import {usePermission} from "@/composables/resources/js/composables/permissions.js";

const {hasPermission} = usePermission();
export default {
  props: {
    selectedDecisions: Object,
    selectedDecisionsByCategory: Object,
    projectDecisions: Object,
    project: Object,
    authUser: Object,
    shouldApprovalSelectedDecisions: Object
  },
  data() {
    let loggedUserNeedApprove = {};
    // sprawdzenie, czy użytkownik jest przypisany do projektu jako project_manager
    if (this.authUser.id === this.project.project_manager_id) {
      loggedUserNeedApprove['project_manager'] = this.shouldApprovalSelectedDecisions['project_manager'];
      delete this.shouldApprovalSelectedDecisions['project_manager'];
    }

    // sprawdzenie, czy użytkownik jest przypisany do projektu jako kierownik działu automatyki i programowania
    if (this.authUser.id === this.project.automation_manager_id) {
      loggedUserNeedApprove['automation_manager'] = this.shouldApprovalSelectedDecisions['automation_manager'];
      delete this.shouldApprovalSelectedDecisions['automation_manager'];
    }

    // sprawdzenie, czy użytkownik jest przypisany do projektu jako kierownik działu planowania
    if (this.authUser.id === this.project.planning_manager_id) {
      loggedUserNeedApprove['planning_manager'] = this.shouldApprovalSelectedDecisions['planning_manager'];
      delete this.shouldApprovalSelectedDecisions['planning_manager'];
    }

    // sprawdzenie, czy użytkownik jest przypisany do projektu jako inżynier projektu
    if (this.authUser.id === this.project.engineer_id) {
      loggedUserNeedApprove['engineer'] = this.shouldApprovalSelectedDecisions['engineer'];
      delete this.shouldApprovalSelectedDecisions['engineer'];
    }

    return {
      hasPermission,
      isDirty2: false,
      isProcessing2: false,
      loggedUserNeedApprove,
      formData2: {}
    }
  },

  mounted() {
    this.loadInputStatus2(this.$refs.decisionId);
  },

  methods: {
    shouldNotApprove() {
      let userId = this.authUser.id;
      let projectManagerId = this.project.project_manager_id;

      return userId !== projectManagerId;

    },

    isApproved2(decision, position) {
      let selectedDecisionApprovals = decision?.selected_decisions_approvals;

      if (!selectedDecisionApprovals) {
        return false;
      }

      for (const deliverer of selectedDecisionApprovals) {
        if (deliverer.position === position) {
          if (deliverer.approved === 1) {
            return true;
          }
        }
      }

      return false;
    },

    formIsDirty2() {
      this.isDirty2 = true;
    },

    formIsNotDirty2() {
      this.isDirty2 = false;
      this.formData2 = {};
    },

    loadInputStatus2(decisionButtons) {
      const self = this;
      decisionButtons.forEach(function (checkbox) {
        self.selectedDecisions.forEach(function (decision) {
          let buttonCategory = checkbox.dataset.category;
          if (buttonCategory === decision.category) {
            if (decision.decisions === null || decision.decisions.length === 0) {
              return;
            }

            if (decision.decisions.length > 0) {
              decision.decisions.forEach(function (id) {
                if (checkbox.id === 'decision_' + id) {
                  checkbox.checked = true;
                }
              })
            }
          }
        });
      })
    },

    changeFormData2(category, id, decision = 'decisions') {
      this.formIsDirty2();
      let inputValue;
      if (event.target.type === 'checkbox')
        inputValue = event.target.checked;
      else
        inputValue = event.target.value;

      if (!this.formData2[category])
        this.formData2[category] = [];

      if (!this.formData2[category][decision])
        this.formData2[category][decision] = [];

      if (!this.formData2[category][decision][id])
        this.formData2[category][decision][id] = inputValue;
      else
        this.formData2[category][decision][id] = inputValue;

      return this.formData2;
    },

    editComment2(value) {
      this.formIsDirty2();
      let val = value === null || value === undefined ? '' : value;
      event.target.innerHTML = '<input class="additionalInput2 form-control form-control-sm" type="text" name="comments" value="' + val + '">';

    },

    editApprovalDate2(value) {
      this.formIsDirty2();
      let val = value === null ? this.getToday2() : value;
      event.target.innerHTML = '<input class="additionalInput2 form-control form-control-sm" type="date" name="approval_date" value="' + val + '">';
    },

    submitForm2() {
      this.isProcessing2 = true;
      const self = this;
      const data = this.formData2;

      axios.post(route('project.selected-decisions.update', {project: self.project.id, data})).then(response => {

        this.formIsNotDirty2();
        this.isProcessing2 = false;

        this.changeInputsToDiv2()

        Toast.fire({
          toast: true,
          icon: response.data.icon,
          title: response.data.title,
          html: response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })

      }).catch(error => {
        console.log(error);
        Toast.fire({
          toast: true,
          icon: error.data.icon,
          title: error.data.title,
          html: error.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
        })
      })
    },

    changeInputsToDiv2() {
      $.each($('.additionalInput2'), function (idx, input) {
        $(input).parent().html($(input).val());
        $(input).remove();
      })
    },

    getToday2() {
      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1; // Miesiące są indeksowane od 0, więc dodajemy 1
      let day = date.getDate();

      return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }
  }
}
</script>
