<template>
    <AppLayout>
        <!-- Analytics -->

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-six">
                <div class="widget-heading">
                    <h6 class="">Witaj, {{$page.props.user.first_name ?? ''}} {{$page.props.user.last_name ?? ''}}</h6>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<script>
import AppLayout from "../Layouts/App.vue"

export default {
    components: {AppLayout},
    props: {}
}
</script>
