<style>
#contactTable thead tr th {
    writing-mode: vertical-lr;
    transform: scale(-1, -1);
    padding: 0.5rem;
}

#contactTable tbody tr td {
    padding: 0.5rem 1rem;
}

#contactTable tbody tr td:not(:first-child) {
    text-align: center;
    font-weight: 700;
}

</style>
<template>
    <AppLayout>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-six">

                <projectMenu :project="project"></projectMenu>

                <div class="widget-content">
                    <div v-if="$page.props.errors">
                        <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
                            <li class="alert alert-danger">{{ error }}</li>
                        </ul>
                    </div>

                    <div class="float-end ms-1 mb-3">
                        <!--<button type="button" class="btn btn-sm btn-outline-secondary" @click="printContent">-->
                        <!--    <i class="bi bi-printer"></i>&nbsp;Drukuj-->
                        <!--</button>-->
                    </div>

                    <div class="float-end mb-3">
                        <ProjectsHistory
                            :project="project"
                            :model="'ProjectContactHistory'"
                        ></ProjectsHistory>
                    </div>

                    <div class="card-body">

                        <div class="d-inline-flex align-items-center mb-4">
                            <h4 class="me-2 mb-0">Osoby kontaktowe</h4>
                            <a v-if="hasPermission('canEditAdvancedProject')" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modalAddContactUser"><i class="bi bi-person-add me-2"></i> Dodaj do projektu</a>
                        </div>

                        <div ref="toPrint" class="row mt-3">
                            <div class="col-md-12 col-lg-6">

                                <form id="editContactsForm" @submit.prevent="submitEditContracts">
                                    <table id="contactTable" class="table-bordered">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th v-for="contact in contacts">
                                                <div class="d-inline-flex align-items-center">
                                                    {{ contact.user.first_name }} {{ contact.user.last_name }}
                                                    <form v-if="hasPermission('canEditAdvancedProject')" :id="'formRemoveContact_'+contact.id" method="post" :action="route('projects.show.contacts.removeContact', contact.id)">
                                                        <input :form="'formRemoveContact_'+contact.id" name="_token" type="hidden" class="tokenCsrf" v-model="csrfToken">
                                                        <button :form="'formRemoveContact_'+contact.id" style="rotate: 90deg;padding-left: 5px" class="btn-danger mt-2" type="submit">
                                                            <i class="bi bi-x-square"></i></button>
                                                    </form>
                                                </div>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr style="height: 38px;">
                                            <td></td>
                                            <td v-for="contact in contacts" @click="changeCellXDW('overall', contact)">
                                                <div :data-contact="contact.id" data-action="overall" :data-value="contact.overall">{{ contact.overall }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Harmonogram prac</td>
                                            <td v-for="contact in contacts" @click="changeCellXDW('work_schedule', contact)">
                                                <div :data-contact="contact.id" data-action="work_schedule" :data-value="contact.work_schedule">{{ contact.work_schedule }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Projekt 3D</td>
                                            <td v-for="contact in contacts" @click="changeCellXDW('design3d', contact)">
                                                <div :data-contact="contact.id" data-action="design3d" :data-value="contact.design3d">{{ contact.design3d }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Przepływ materiału</td>
                                            <td v-for="contact in contacts" @click="changeCellXDW('material_flow', contact)">
                                                <div :data-contact="contact.id" data-action="material_flow" :data-value="contact.material_flow">{{ contact.material_flow }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Schemat elektryczny</td>
                                            <td v-for="contact in contacts" @click="changeCellXDW('electrical_diagram', contact)">
                                                <div :data-contact="contact.id" data-action="electrical_diagram" :data-value="contact.electrical_diagram">{{ contact.electrical_diagram }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Program PLC</td>
                                            <td v-for="contact in contacts" @click="changeCellXDW('plc_program', contact)">
                                                <div :data-contact="contact.id" data-action="plc_program" :data-value="contact.plc_program">{{ contact.plc_program }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Dokumentacja, analiza ryzyka</td>
                                            <td v-for="contact in contacts" @click="changeCellXDW('documentation', contact)">
                                                <div :data-contact="contact.id" data-action="documentation" :data-value="contact.documentation">{{ contact.documentation }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Layout</td>
                                            <td v-for="contact in contacts" @click="changeCellXDW('layout', contact)">
                                                <div :data-contact="contact.id" data-action="layout" :data-value="contact.layout">{{ contact.layout }}</div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <button v-if="hasPermission('canEditAdvancedProject')" form="editContactsForm" type="submit" id="editContactsSaveBtn" class="mt-3 btn btn-sm btn-success" style="display: none">
                                        <i class="bi bi-save"></i> Zapisz zmiany
                                    </button>
                                </form>
                            </div>

                            <div class="col-md-12 col-lg-6">

                                <table class="table table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Osoba</th>
                                        <th>Dział</th>
                                        <th>Kontakt</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-if="project.sales_representative">
                                        <td>{{ project.sales_representative.first_name }} {{ project.sales_representative.last_name }}</td>
                                        <td></td>
                                        <td>
                                            email:{{ project.sales_representative.email ?? '' }}<br> telefon:{{ project.sales_representative.phone ?? '' }}
                                        </td>
                                    </tr>
                                    <tr v-if="project.planning_manager">
                                        <td>{{ project.planning_manager.first_name }} {{ project.planning_manager.last_name }}</td>
                                        <td>Kierownik działu planowania</td>
                                        <td>
                                            email:{{ project.planning_manager.email ?? '' }}<br> telefon:{{ project.planning_manager.phone ?? '' }}
                                        </td>
                                    </tr>
                                    <tr v-if="project.project_manager">
                                        <td>{{ project.project_manager.first_name }} {{ project.project_manager.last_name }}</td>
                                        <td>Kierownik Projektu</td>
                                        <td>
                                            email:{{ project.project_manager.email ?? '' }}<br> telefon:{{ project.project_manager.phone ?? '' }}
                                        </td>
                                    </tr>
                                    <tr v-if="project.engineering_manager">
                                        <td>{{ project.engineering_manager.first_name }} {{ project.engineering_manager.last_name }}</td>
                                        <td>Kierownik działu konstrukcyjnego</td>
                                        <td>
                                            email:{{ project.engineering_manager.email ?? '' }}<br> telefon:{{ project.engineering_manager.phone ?? '' }}
                                        </td>
                                    </tr>
                                    <tr v-if="project.engineer">
                                        <td>{{ project.engineer.first_name }} {{ project.engineer.last_name }}</td>
                                        <td>Inżynier Projektu-konstruktor</td>
                                        <td>
                                            email:{{ project.engineer.email ?? '' }}<br> telefon:{{ project.engineer.phone ?? '' }}
                                        </td>
                                    </tr>
                                    <tr v-if="project.automation_manager">
                                        <td>{{ project.automation_manager.first_name }} {{ project.automation_manager.last_name }}</td>
                                        <td>Kierownik działu automatyki<br> i programowania</td>
                                        <td>
                                            email:{{ project.automation_manager.email ?? '' }}<br> telefon:{{ project.automation_manager.phone ?? '' }}
                                        </td>
                                    </tr>
                                    <tr v-if="project.programmer">
                                        <td>{{ project.programmer.first_name }} {{ project.programmer.last_name }}</td>
                                        <td>Programista</td>
                                        <td>
                                            email:{{ project.programmer.email ?? '' }}<br> telefon:{{ project.programmer.phone ?? '' }}
                                        </td>
                                    </tr>
                                    <tr v-if="project.documentation_manager">
                                        <td>{{ project.documentation_manager.first_name }} {{ project.documentation_manager.last_name }}</td>
                                        <td>Kierownik działu dokumentacji<br> technicznej i certyfikacji maszyn</td>
                                        <td>
                                            email:{{ project.documentation_manager.email ?? '' }}<br> telefon:{{ project.documentation_manager.phone ?? '' }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>

                            <div class="row mt-5">
                                <div class="col-12">
                                    <span class="pe-5">X - osoba kontaktowa</span> <span>DW - do wiadomości</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body mt-5">
                        <hr>
                        <div class="row">
                            <h4 class="mb-4">Osoby kontaktowe po stronie klienta:</h4>
                            <ol class="list" style="padding-left: 18px">
                                <li class="h6 mb-3" v-for="contact in project.offer?.offer_customer_contacts">
                                    <b>{{ contact.first_name ?? '' }} {{ contact.last_name ?? '' }}</b> {{ contact.position ? '- ' + contact.position : '' }}
                                    <ul>
                                        <li class="mt-1 mb-1" style="list-style: none; margin-left: -23px">Email:{{ contact.email ?? '' }}</li>
                                        <li class="mb-1" style="list-style: none; margin-left: -23px">Telefon:{{ contact.phone ?? '' }}</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="modalAddContactUser" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <form id="addContactForm" class="modal-content" method="post" :action="route('projects.show.contacts.addContact', project)">
                    <input name="_token" type="hidden" id="tokenCsrf" v-model="csrfToken">
                    <div class="modal-header">
                        <h5 class="modal-title">Dodaj osobę kontaktową do projektu</h5>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>
                            Projekt: <b>{{ project.name }}</b>
                        </div>
                        <div>
                            <label for="addPersonToProject" class="mt-3 mb-1">Osoba kontaktowa:</label>
                            <select id="addPersonToProject" class="form-control form-select form-select-sm" name="user">
                                <option v-for="user in users" :value="user.id">
                                    {{ user.first_name ?? '' }} {{ user.last_name ?? '' }} - {{ user.position ?? '' }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Zamknij</button>
                        <button type="submit" class="btn btn-primary" @click="formOnSubmitNewContact" :disabled="formSubmitting">{{ formSubmitting ? 'Zapisywanie...' : 'Zapisz' }}</button>
                    </div>
                </form>
            </div>
        </div>
    </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import ProjectsHistory from "@/Pages/Projects/Components/ProjectsHistory.vue";
import projectMenu from "@/Pages/Projects/parts/projectMenu.vue";
import {usePermission} from "@/composables/resources/js/composables/permissions.js";
const {hasPermission} = usePermission();
export default {
    components: {ProjectsHistory, AppLayout, projectMenu, hasPermission},
    props: {
        users: Object,
        project: Object,
        contacts: Object
    },
    data() {
        return {
            hasPermission,
            formSubmitting: false,
            csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    },

    methods: {
        route,
        isRouteActive(routeName) {
            return route().current() === routeName;
        },
        generateCsrfToken(elem) {
            elem.val($('meta[name="csrf-token"]').attr('content'));
        },
        async formOnSubmitNewContact() {
            this.formSubmitting = true;
            await new Promise(resolve => setTimeout(resolve, 100));
            $('#addContactForm').submit();
        },

        changeCellXDW(action, contact) {
            if (contact[action] === null) {
                contact[action] = 'X';
            } else if (contact[action] === 'X') {
                contact[action] = 'DW';
            } else {
                contact[action] = null;
            }

            $('#editContactsSaveBtn').show();
        },

        async submitEditContracts() {
            let form = {};
            form.contacts = {};
            $('#editContactsForm table tbody td').each(function (i, td) {
                let div = $(td).find('div');
                if (div.data('contact')) {
                    if (!form.contacts[div.data('contact')]) {
                        form.contacts[div.data('contact')] = {};
                    }
                    if (!form.contacts[div.data('contact')][div.data('action')]) {
                        form.contacts[div.data('contact')][div.data('action')] = {};
                    }

                    form.contacts[div.data('contact')][div.data('action')] = div.data('value');
                }
            })

            try {
                $('#editContactsSaveBtn').hide();
                axios.post(route('projects.show.contacts.update-contacts-actions', {form})).then(response => {
                    Toast.fire({
                        toast: true,
                        icon: response.data.icon,
                        title: response.data.title,
                        html: response.data.message,
                        showClass: {popup: 'animate__animated animate__fadeInDown'},
                        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
                        timer: 2500,
                    })
                });
            } catch (e) {
                console.log(e);
                $('#editContactsSaveBtn').show();
            }
        },
    },
}
</script>
