<template>
    <div class="widget-content">
        <vue-apexcharts height="500" type="line" :options="chartOptions" :series="chartSeries"></vue-apexcharts>
    </div>
</template>


<script>
import vueApexcharts from "vue3-apexcharts";

export default {
    components: {vueApexcharts},
    props: {
        budgetData: Object,
        overallCosts: Object
    },

    computed: {
        chartOptions() {
            return {
                chart: {
                    stacked: false,
                },
                xaxis: {
                    categories: this.getUniqueCategories(),
                },
                dataLabels: {
                    enabled: true,
                },
                colors: ['#ed7d31', '#4361ee'],
            };
        },
        chartSeries() {
            const series = [
                {
                    name: "Budżet",
                    type: "line",
                    data: this.budgetData.map(item => item.budget),
                },
                {
                    name: "Koszty",
                    type: "bar",
                    data: this.getCostsSeriesData(),
                },
            ];

            return series;
        },
    },
    methods: {
        getUniqueCategories() {
            const budgetCategories = this.budgetData.map(item => item.category?.toLowerCase());
            const costCategories = Object.keys(this.overallCosts).map(category => category.toLowerCase());

            // Łącz kategorie z obu źródeł, zachowując unikalność
            const uniqueCategories = [...new Set([...budgetCategories, ...costCategories])];
            return uniqueCategories;
        },
        getCostsSeriesData() {
            return this.getUniqueCategories().map(normalizedCategory => {
                const costItem = Object.values(this.overallCosts).find(item => item.category?.toLowerCase() === normalizedCategory);
                return costItem ? costItem.costs : 0;
            });
        },
    },
}
</script>
