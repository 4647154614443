<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
              <h4 class="me-3 my-0">Lista kategorii kontrahentów</h4>
              <AddCategory @categoryAdded="onCategoryAdded"></AddCategory>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <div class="custom-table table-responsive">
            <table ref="dataTables" class="table table-hover"/>
          </div>
        </div>

      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import {usePage} from "@inertiajs/vue3";
import AddCategory from "./AddCategory.vue";

export default {
  components: {AddCategory, AppLayout},
  name: 'DataTable',

  mounted() {
    const {data} = usePage().props;
    this.user = usePage().props.user;
    this.initDataTable(data);
    this.initFilters();
  },
  methods: {
    initDataTable() {
      const self = this;
      const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
      this.table = $(this.$refs.dataTables).DataTable({
        colReorder: true,
        stateSave: true,
        serverSide: true,
        processing: true,
        ajax: {
          url: route('customers-categories.get-table'), // endpoint do pobierania danych z serwera
          type: 'GET',
          data: function (params) {
            params.additional_param = 'value'; // Dodaj dodatkowe parametry, jeśli są potrzebne
            return params;
          },
          dataSrc: 'data',
        },
        stateSaveCallback: function (settings, data) {
          $.ajax({
            url: '/save-column-data?table=customers-categories',
            data: data,
            type: 'POST',
            dataType: 'json',
            headers: {
              'X-CSRF-TOKEN': csrfToken, // Include the CSRF token in the headers
            },
            "success": function () {
              const datTab = $('.dataTable');
              // generuj link do wersji oferty
              let link = datTab.find('a[data-edit]');
              link.on('click', function (event) {
                event.preventDefault();
                const id = $(this).data('edit');
                self.generateEditCustomerLink(id);
              });

            }
          });
        },
        stateLoadCallback: function (settings) {
          var o;
          $.ajax({
            url: '/get-table-data?table=customers-categories',
            async: false,
            dataType: 'json',
            success: function (json) {
              o = json;
            }
          });
          let elem = this;
          self.initColumnSearch(elem, o)

          return o;
        },
        columns: [
          {
            data: 'name',
            title: 'Nazwa',
            render: function (d, t, r) {
              return '<a class="text-primary" href="' + route('customers-categories.show', r.id) + '" data-edit="' + r.id + '">' + d + '</a>';
            }
          },

        ],
        createdRow: function (row, data, dataIndex) {
          // po kliknieciu w 'td' przenies do edycji customera
          const link = $(row).find('a[data-edit]');
          link.on('click', function (event) {
            event.preventDefault();
            const id = $(this).data('edit');
            self.generateEditCustomerLink(id);
          });
        },
      });
    },
    generateEditCustomerLink(id) {
      this.$inertia.visit(route('customers-categories.show', {category: id}));
    },
    initColumnSearch(elem, o) {
      setTimeout(() => {
        var api = elem.api();
        api.columns()
            .eq(0)
            .each(function (colIdx) {
              var cell = $('.filters td').eq(
                  $(api.column(colIdx).header()).index()
              );
              var title_cell = $('th').eq(
                  $(api.column(colIdx).header()).index()
              );

              let searchText = o.columns[colIdx].search.search
              if (searchText !== null && searchText !== undefined && typeof searchText === 'string') {
                var cleanSearchText = searchText.replace(/\(|\)/g, '');
              }

              if (cleanSearchText) {
                $(cell).html('<input type="text" value=' + cleanSearchText + ' />');
              } else {
                let title = $(title_cell).text();
                $(cell).html('<input type="text" placeholder="' + title + '" />');
              }

              $('input',
                  $('.filters td').eq($(api.column(colIdx).header()).index()))
                  .off('keyup change')
                  .on('change', function (e) {
                    $(this).attr('title', $(this).val());
                    api.column($(this).parent().index()).search(this.value).draw();
                  })
                  .on('keyup', function (e) {
                    e.stopPropagation();
                    $(this).trigger('change');
                    $(this)
                        .focus()[0]
                        .setSelectionRange(this.selectionStart, this.selectionStart);
                  });
            });
      }, 50);
    },

    // Dodawanie dodatkowych inputów pod headerem do filtrowania
    initFilters() {
      const headerRow = $(this.$refs.dataTables).find('thead tr');
      const inputRow = document.createElement('tr');

      headerRow.find('th').each(function (index) {
        const inputCell = document.createElement('td');
        const input = document.createElement('input');
        input.type = 'text';
        input.placeholder = 'Filter...';

        $(inputCell).addClass('filters').css('max-width', '30px');
        $(inputCell).addClass('filters').append(input);
        $(inputRow).addClass('filters').append(inputCell);
      });

      headerRow.after(inputRow);
    },

    onCategoryAdded() {
      this.table.destroy();
      this.initDataTable();
    }
  },
}

</script>
