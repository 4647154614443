<template>
  <div class="widget widget-recent-orders">
    <div class="widget-heading">
      <h5>Najwięcej zaakceptowanych ofert przez klienta</h5>
      <div>
        <ejs-daterangepicker
            id="mostOffersByCustomerId"
            placeholder="Wybierz zakres dat"
            :value="dateRange"
            :format="dateFormat"
            @change="onDateChange"
        ></ejs-daterangepicker>
      </div>
    </div>

    <template v-if="isLoading">
      <div class="text-center my-3">
        <div class="spinner-border spinner-border-reverse align-self-center loader-sm text-danger">Ładowanie...</div>
      </div>
    </template>

    <template v-else>
      <div class="widget-content table-responsive">
        <table v-if="customerData.length > 0" class="table table-bordered">
          <thead>
          <tr>
            <th>
              <div class="th-content">Klient</div>
            </th>
            <th>
              <div class="th-content">Wysłanych do klienta</div>
            </th>
            <th>
              <div class="th-content th-heading">Odrzuconych</div>
            </th>
            <th>
              <div class="th-content">Zaakceptowanych</div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in customerData" :key="index">
            <td>
              <div class="td-content"><span>{{ item.customer }}</span></div>
            </td>
            <td class="text-center">
              <div class="td-content text-primary">{{ item[2]?.total }}</div>
            </td>
            <td class="text-center">
              <div class="td-content">{{ item[3]?.total }}</div>
            </td>
            <td class="text-center">
              <div class="td-content"><span>{{ item[4]?.total }}</span></div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>

  </div>
</template>

<script>
import axios from "axios";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import {DateRangePickerComponent} from "@syncfusion/ej2-vue-calendars";

export default {
  components: {
    'ejs-daterangepicker': DateRangePickerComponent,
  },

  data() {
    const today = new Date();
    const _startDate = new Date(today);
    _startDate.setMonth(_startDate.getMonth() - 1); // Ustaw na jeden miesiąc przed dzisiejszą datą
    const _endDate = new Date(today); // Ustaw dzisiejszą datę

    return {
      isLoading: false,
      dateRange: [_startDate, _endDate], // Początkowy zakres dat
      dateFormat: 'dd/MM/yyyy',
      customerData: [],
      topCustomers: 5,
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    onDateChange(args) {
      this.dateRange = args.value;
      this.fetchData();
    },

    fetchData() {
      this.isLoading = true;
      axios.post(route('graphs.customer.most-accepted-offers', {
        'topCustomers': this.topCustomers,
        'dates': this.dateRange
      })).then(response => {
        this.customerData = response.data;
      }).catch(err => {
        console.error('Błąd podczas pobierania most accepted offers:', err);
      }).finally(() => {
        this.isLoading = false;
      })
    },
  },
};
</script>
