<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
              <h4 class="me-3 my-0">Lista działów dla użytkowników</h4>
              <button @click="addDepartment" class="btn btn-primary btn-sm"><i class="bi bi-plus-circle me-2"></i>Dodaj
                dział
              </button>
            </div>
          </div>
        </div>
        <div class="widget-content">

          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="custom-table">
            <div class="table-responsive">
              <table id="department-table" class="table table-hover">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Nazwa</th>
                  <th>Opis</th>
                  <th>Liczba użytkowników</th>
                  <th>Stworzony</th>
                  <th>Zaktualizowany</th>
                  <th>Akcje</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(department, depId) in departments" :key="depId">
                  <td>{{ department.id }}</td>
                  <td>
                    <input v-if="editingId === department.id" v-model="department.name" class="form-control"/>
                    <span v-else>{{ department.name }}</span>
                  </td>
                  <td>
                    <input v-if="editingId === department.id" v-model="department.description" class="form-control"/>
                    <span v-else>{{ department.description }}</span>
                  </td>
                  <td>{{ department.users_count }}</td>
                  <td>{{ formatDate(department.created_at) }}</td>
                  <td>{{ formatDate(department.updated_at) }}</td>
                  <td>
                    <button v-if="editingId === department.id" @click="updateDepartment(department)"
                            class="btn btn-sm btn-success me-2">Zapisz
                    </button>
                    <button v-else @click="editDepartment(department.id)" class="btn btn-sm btn-outline-warning me-2"><i
                        class="bi bi-pencil me-2"></i>Edytuj
                    </button>
                    <button @click="deleteDepartment(department.id)" class="btn btn-sm btn-danger"><i
                        class="bi bi-trash me-2"></i>Usuń
                    </button>
                  </td>
                </tr>
                <tr v-if="addingNew">
                  <td>Nowy</td>
                  <td><input v-model="newDepartment.name" class="form-control"/></td>
                  <td><input v-model="newDepartment.description" class="form-control"/></td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    <button @click="saveNewDepartment" class="btn btn-sm btn-success me-1"><i
                        class="bi bi-save me-2"></i>Zapisz
                    </button>
                    <button @click="cancelNewDepartment" class="btn btn-sm btn-danger"><i
                        class="bi bi-x-circle me-2"></i>Anuluj
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>


<script>
import AppLayout from "@/Layouts/App.vue";

export default {
  components: {AppLayout},
  props: {
    departments: Array
  },
  data() {
    return {
      editingId: null,
      addingNew: false,
      newDepartment: this.getEmptyForm()
    };
  },

  methods: {
    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są liczone od zera
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    getEmptyForm() {
      return {
        id: null,
        name: '',
        description: ''
      };
    },
    addDepartment() {
      this.addingNew = true;
      this.newDepartment = this.getEmptyForm();
    },
    cancelNewDepartment() {
      this.addingNew = false;
      this.newDepartment = this.getEmptyForm();
    },
    editDepartment(id) {
      this.editingId = id;
    },
    updateDepartment(department) {
      this.$inertia.put(route('users.departments.update', department.id), department, {
        onSuccess: () => {
          this.editingId = null;
        }
      });
    },
    saveNewDepartment() {
      this.$inertia.post(route('users.departments.store'), this.newDepartment, {
        onSuccess: () => {
          this.addingNew = false;
          this.newDepartment = this.getEmptyForm();
        }
      });
    },
    deleteDepartment(id) {
      Toast.fire({
        toast: false,
        icon: 'warning',
        title: 'Usuwanie działu',
        html: 'Czy na pewno chcesz usunąć ten dział?',
        showDenyButton: true,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        position: 'center',
        showConfirmButton: true,
        confirmButtonText: 'Tak',
        denyButtonText: 'Nie',
      }).then((result) => {
        if (result.isConfirmed) {
          this.$inertia.delete(route('users.departments.destroy', id));
        }
      })
    }
  }
}
</script>


<style>

</style>