<template>

    <div class="dropdown nav-item notification-dropdown btn-group">
        <a href="javascript:" id="ddlnotify" data-bs-toggle="dropdown" aria-expanded="true" class="btn dropdown-toggle btn-icon-only nav-link show"
            :class="{'notification-animation': isUnreadNotificationsCount > 0}">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>

            <!-- JEŻELI SĄ JAKIEŚ NOTYFIKACJE -->
            <span v-if="isUnreadNotificationsCount > 0" class="badge badge-success"></span>

        </a>

        <!-- SPIS NOTYFIKACJI DLA UŻYTKOWNIKA - NIEPRZECZYTANE -->
        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlnotify" data-bs-popper="none">
            <li role="presentation" v-for="(notification, idx) in notifications">
                <div class="server-log item">
                    <div class="media-aside align-self-start"></div>
                    <div class="media-body">
                        <div class="data-info">
                            <p class="pe-3"><small>{{ notification?.created_at }}</small></p>
                            <Link :href="notification?.link ?? ''">
                                <h6 :class="{ 'font-weight-bolder':!notification?.has_been_read }">{{ notification?.topic }}
                                    <span v-if="!notification?.has_been_read" class="badge badge-success">Nowy</span>
                                </h6>
                                <p class="">{{ notification?.message }}</p>
                            </Link>
                        </div>
                        <div class="icon-status" style="z-index: 1">
                            <i @click="markNotificationAsRead(idx, notification?.id)"
                                v-if="!notification?.has_been_read"
                                class="bi bi-check-circle"
                                title="Oznacz jako przeczytane"
                                style="color: #1abc9c"></i>

                            <i @click="deleteNotification(idx, notification?.id)"
                                v-if="notification?.has_been_read"
                                class="bi bi-x-circle"
                                title="Usuń"
                                style="color: grey"></i>
                        </div>
                    </div>
                </div>
                <hr v-if="idx < notifications.length - 1" role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider">
            </li>
        </ul>
    </div>
</template>

<script>
import axios from "axios";
import route from "../../../vendor/tightenco/ziggy/src/js/index.js";

export default {

    data() {
        return {
            intervalId: null,
            isUnreadNotificationsCount: 0,
            notifications: []
        }
    },

    created() {
        this.getNotificationsForUser();
        this.interval('start');
    },
    beforeUnmount() {
        this.interval('stop');
    },

    methods: {
        interval(action) {
            const self = this;
            if (action === 'start') {
                self.intervalId = setInterval(() => {
                    self.getNotificationsForUser();
                }, 30000);
            } else {
                clearInterval(self.intervalId);
            }
        },

        getNotificationsForUser() {
            axios.get(route('pushNotifications.get')).then((response) => {
                this.notifications = response.data.notifications;
                this.isUnreadNotificationsCount = response.data.isUnreadNotificationsCount;
            })
        },
        markNotificationAsRead(key, notificationId) {
            axios.post(route('pushNotifications.markAsRead', {notification: notificationId}))
                .then(() => {
                    this.getNotificationsForUser();
                })
        },
        deleteNotification(key, notificationId) {
            if (confirm('Jesteś pewny, że chcesz usunąć tę notyfikację?')) {
                axios.post(route('pushNotifications.delete', {notification: notificationId})).then(() => {
                    delete this.notifications[key];
                    this.notifications.length -= 1;
                })
            }
        }
    }
}
</script>

<style scoped>

.item:not(:last-child) {
    border-bottom: 1px solid #e0e6ed;
}

.item {
    padding: 12px 16px !important;
    cursor: pointer;
    border-radius: 0;
    background: transparent;
}


</style>
