export default {
    "vueform": {
        "elements": {
            "list": {
                "add": "+ Dodaj",
                "remove": "&times;"
            },
            "file": {
                "defaultName": "Plik",
                "dndTitle": "Wyślij plik",
                "dndDescription": "Upuść plik lub kliknij tutaj, aby przesłać",
                "removeConfirm": "Usunięcie pliku spowoduje jego trwałe usunięcie. Czy na pewno chcesz kontynuować?",
                "select": "Wybierz plik",
                "upload": "Prześlij"
            },
            "multifile": {
                "uploadButton": "Prześlij pliki",
                "dndTitle": "Prześlij pliki",
                "dndDescription": "Upuść pliki lub kliknij tutaj, aby przesłać"
            },
            "gallery": {
                "uploadButton": "Prześlij obrazy",
                "dndTitle": "Prześlij obrazy",
                "dndDescription": "Upuść obrazy lub kliknij tutaj, aby przesłać"
            }
        },
        "steps": {
            "finish": "Zakończ",
            "next": "Dalej",
            "previous": "Wstecz"
        },
        "editor": {
            "acceptedMimesError": "Dozwolone formaty plików: :mimes",
            "acceptedExtensionsError": "Dozwolone rozszerzenia plików: :extensions"
        },
        "datepicker": {
            "weekdays": {
                "shorthand": [
                    "Nie",
                    "Pon",
                    "Wt",
                    "Śr",
                    "Czw",
                    "Pt",
                    "Sob"
                ],
                "longhand": [
                    "Niedziela",
                    "Poniedziałek",
                    "Wtorek",
                    "Środa",
                    "Czwartek",
                    "Piątek",
                    "Sobota"
                ]
            },
            "months": {
                "shorthand": [
                    "Sty",
                    "Lut",
                    "Mar",
                    "Kwi",
                    "Maj",
                    "Cze",
                    "Lip",
                    "Sie",
                    "Wrz",
                    "Paź",
                    "Lis",
                    "Gru"
                ],
                "longhand": [
                    "Styczeń",
                    "Luty",
                    "Marzec",
                    "Kwiecień",
                    "Maj",
                    "Czerwiec",
                    "Lipiec",
                    "Sierpień",
                    "Wrzesień",
                    "Październik",
                    "Listopad",
                    "Grudzień"
                ]
            },
            "daysInMonth": [
                31,
                28,
                31,
                30,
                31,
                30,
                31,
                31,
                30,
                31,
                30,
                31
            ],
            "firstDayOfWeek": 0,
            "ordinal": function (nth) {
                var s = nth % 100;
                if (s > 3 && s < 21) return "th";
                switch (s % 10) {
                    case 1:
                        return "st";
                    case 2:
                        return "nd";
                    case 3:
                        return "rd";
                    default:
                        return "th";
                }
            },
            "rangeSeparator": " do ",
            "weekAbbreviation": "Tydzień",
            "scrollTitle": "Przewiń, aby zwiększyć",
            "toggleTitle": "Kliknij, aby przełączyć",
            "amPM": [
                "AM",
                "PM"
            ],
            "yearAriaLabel": "Rok",
            "monthAriaLabel": "Miesiąc",
            "hourAriaLabel": "Godzina",
            "minuteAriaLabel": "Minuta"
        },
        "dateFormats": {
            "datetimeSeconds24": "YYYY-MM-DD HH:mm:ss",
            "datetimeSeconds12": "YYYY-MM-DD hh:mm:ss a",
            "datetime24": "YYYY-MM-DD HH:mm",
            "datetime12": "YYYY-MM-DD hh:mm a",
            "timeSeconds24": "HH:mm:ss",
            "timeSeconds12": "hh:mm:ss a",
            "time24": "HH:mm",
            "time12": "hh:mm a",
            "date": "YYYY-MM-DD"
        },
        "multiselect": {
            "multipleLabelOne": "1 opcja wybrana",
            "multipleLabelMore": ":options opcji wybranych",
            "noResults": "Brak dostępnych opcji",
            "noOptions": "Lista jest pusta"
        },
        "defaultMessage": "Nieprawidłowe pole",
        "a11y": {
            "file": {
                "description": "Naciśnij Backspace, aby usunąć"
            },
            "list": {
                "remove": "Przycisk usuwania elementu"
            }
        }
    },
    "validation": {
        "accepted": "Pole :attribute musi zostać zaakceptowane.",
        "active_url": "Pole :attribute nie jest prawidłowym adresem URL.",
        "after": "Pole :attribute musi być datą późniejszą niż :date.",
        "after_or_equal": "Pole :attribute musi być datą późniejszą lub równą :date.",
        "alpha": "Pole :attribute może zawierać tylko litery.",
        "alpha_dash": "Pole :attribute może zawierać tylko litery, cyfry, myślniki i podkreślenia.",
        "alpha_num": "Pole :attribute może zawierać tylko litery i cyfry.",
        "array": "Pole :attribute musi być tablicą.",
        "before": "Pole :attribute musi być datą wcześniejszą niż :date.",
        "before_or_equal": "Pole :attribute musi być datą wcześniejszą lub równą :date.",
        "between": {
            "numeric": "Pole :attribute musi mieć wartość między :min a :max.",
            "file": "Pole :attribute musi mieć rozmiar między :min a :max kilobajtów.",
            "string": "Pole :attribute musi mieć od :min do :max znaków.",
            "array": "Pole :attribute musi mieć od :min do :max elementów."
        },
        "boolean": "Pole :attribute musi mieć wartość prawda lub fałsz.",
        "confirmed": "Potwierdzenie pola :attribute nie pasuje.",
        "date": "Pole :attribute nie jest prawidłową datą.",
        "date_format": "Pole :attribute nie pasuje do formatu :format.",
        "date_equals": "Pole :attribute musi być równe :date.",
        "different": "Pole :attribute i :other muszą być różne.",
        "digits": "Pole :attribute musi mieć :digits cyfr.",
        "digits_between": "Pole :attribute musi mieć od :min do :max cyfr.",
        "dimensions": "Pole :attribute ma nieprawidłowe wymiary obrazu.",
        "distinct": "Pole :attribute ma zduplikowaną wartość.",
        "email": "Pole :attribute musi być prawidłowym adresem e-mail.",
        "exists": "Wybrane pole :attribute jest nieprawidłowe.",
        "file": "Pole :attribute musi być plikiem.",
        "filled": "Pole :attribute musi mieć wartość.",
        "gt": {
            "numeric": "Pole :attribute musi być większe niż :value.",
            "file": "Pole :attribute musi mieć rozmiar większy niż :value kilobajtów.",
            "string": "Pole :attribute musi mieć więcej niż :value znaków.",
            "array": "Pole :attribute musi mieć więcej niż :value elementów."
        },
        "gte": {
            "numeric": "Pole :attribute musi być większe lub równe :value.",
            "file": "Pole :attribute musi mieć rozmiar większy lub równy :value kilobajtów.",
            "string": "Pole :attribute musi mieć :value lub więcej znaków.",
            "array": "Pole :attribute musi mieć :value elementów lub więcej."
        },
        "image": "Pole :attribute musi być obrazem.",
        "in": "Wybrane pole :attribute jest nieprawidłowe.",
        "in_array": "Pole :attribute nie istnieje w :other.",
        "integer": "Pole :attribute musi być liczbą całkowitą.",
        "ip": "Pole :attribute musi być prawidłowym adresem IP.",
        "ipv4": "Pole :attribute musi być prawidłowym adresem IPv4.",
        "ipv6": "Pole :attribute musi być prawidłowym adresem IPv6.",
        "json": "Pole :attribute musi być poprawnym łańcuchem JSON.",
        "lt": {
            "numeric": "Pole :attribute musi być mniejsze niż :value.",
            "file": "Pole :attribute musi mieć rozmiar mniejszy niż :value kilobajtów.",
            "string": "Pole :attribute musi mieć mniej niż :value znaków.",
            "array": "Pole :attribute musi mieć mniej niż :value elementów."
        },
        "lte": {
            "numeric": "Pole :attribute musi być mniejsze lub równe :value.",
            "file": "Pole :attribute musi mieć rozmiar mniejszy lub równy :value kilobajtów.",
            "string": "Pole :attribute musi mieć :value lub mniej znaków.",
            "array": "Pole :attribute nie może mieć więcej niż :value elementów."
        },
        "max": {
            "numeric": "Pole :attribute nie może być większe niż :max.",
            "file": "Pole :attribute nie może być większe niż :max kilobajtów.",
            "string": "Pole :attribute nie może mieć więcej niż :max znaków.",
            "array": "Pole :attribute nie może mieć więcej niż :max elementów."
        },
        "mimes": "Pole :attribute musi być plikiem typu: :values.",
        "mimetypes": "Pole :attribute musi być plikiem typu: :values.",
        "min": {
            "numeric": "Pole :attribute musi być co najmniej :min.",
            "file": "Pole :attribute musi mieć co najmniej :min kilobajtów.",
            "string": "Pole :attribute musi mieć co najmniej :min znaków.",
            "array": "Pole :attribute musi mieć co najmniej :min elementów."
        },
        "not_in": "Wybrane pole :attribute jest nieprawidłowe.",
        "not_regex": "Format pola :attribute jest nieprawidłowy.",
        "numeric": "Pole :attribute musi być liczbą.",
        "present": "Pole :attribute musi być obecne.",
        "regex": "Format pola :attribute jest nieprawidłowy.",
        "required": "Pole :attribute jest wymagane.",
        "required_if": "Pole :attribute jest wymagane, gdy :other jest :value.",
        "required_unless": "Pole :attribute jest wymagane, chyba że :other znajduje się w :values.",
        "required_with": "Pole :attribute jest wymagane, gdy :values jest obecne.",
        "required_with_all": "Pole :attribute jest wymagane, gdy wszystkie :values są obecne.",
        "required_without": "Pole :attribute jest wymagane, gdy :values nie jest obecne.",
        "required_without_all": "Pole :attribute jest wymagane, gdy żadne z :values nie jest obecne.",
        "same": "Pole :attribute i :other muszą być takie same.",
        "size": {
            "numeric": "Pole :attribute musi mieć rozmiar :size.",
            "file": "Pole :attribute musi mieć rozmiar :size kilobajtów.",
            "string": "Pole :attribute musi mieć rozmiar :size znaków.",
            "array": "Pole :attribute musi zawierać :size elementów."
        },
        "string": "Pole :attribute musi być ciągiem znaków.",
        "timezone": "Pole :attribute musi być prawidłową strefą czasową.",
        "unique": "Pole :attribute już zostało zajęte.",
        "uploaded": "Pole :attribute nie zostało przesłane.",
        "url": "Format pola :attribute jest nieprawidłowy.",
        "uuid": "Pole :attribute musi być poprawnym identyfikatorem UUID.",
        "remote": "Pole :attribute jest nieprawidłowe."
    }
}
