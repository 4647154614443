<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Listy zamówieniowe</h4>
            </div>
            <div class="mt-3 row">

              <div class="col-md-12 col-lg-6">
                <label for="project-select">Wybierz projekt:</label>
                <select id="project-select" class="form-select form-select-sm" @change="updateSelectedProject"
                        v-model="selectedProjectId">
                  <option value="" disabled>Wybierz projekt</option>
                  <option v-for="project in projects" :key="project.id" :value="project.id">
                    {{ project.label }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 col-lg-6">
                <label for="team-select">Wybierz zespół:</label>
                <select id="team-select" class="form-select form-select-sm" @change="updateSelectedTeam"
                        v-model="selectedTeamName" :disabled="!selectedProjectId || teams.length === 0">
                  <option value="" disabled>Wybierz zespół</option>
                  <option v-for="team in teams" :key="team.name" :value="team.name">
                    {{ team.name }}
                  </option>
                </select>
              </div>

            </div>
          </div>
        </div>

        <div class="widget-content">
          <p class="text-danger">TODO:</p>
          <p class="text-danger">sortowanie drag&drop</p>
          <p class="text-danger">Wylistowanie wszystkich kolumn</p>
          <p class="text-danger">Dopisanie odpowiedni filtrów do GridFilters\Filters</p>
          <p class="text-danger">J.W. dla OrdersBy</p>
          <div v-if="selectedProjectId && selectedTeamName">
            <ejs-grid ref="orderListGrid" id="orderListGrid" height="500px"
                      :dataSource="orderListDataManager"
                      :allowTextWrap='true'
                      :allowExcelExport='false'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='true'
                      :allowRowDragAndDrop="true"
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='false'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :rowDrop="rowDrop"
                      :keyPressed="onKeyDown">
              <e-columns>
                <e-column field="sort" headerText="Lp." width="92"></e-column>
                <e-column field="id" headerText="ID" width="92" :isPrimaryKey="true" :allowEditing="false"></e-column>
                <e-column field="catalog_number" headerText="Nr katalogowy" width="150"
                          :allowEditing="false"></e-column>
                <e-column field="article.model" headerText="Model" width="150" :allowEditing="false"></e-column>
                <e-column field="article.description" headerText="Nazwa/Opis" width="200"
                          :allowEditing="false"></e-column>
              </e-columns>

            </ejs-grid>
          </div>

        </div>
      </div>
    </div>

  </AppLayout>
</template>

<script>
import AppLayout from "../../Layouts/App.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {AutoComplete, MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {TextBox} from '@syncfusion/ej2-inputs';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  RowDD,
  VirtualScroll
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, WebMethodAdaptor, WebApiAdaptor, ODataAdaptor} from '@syncfusion/ej2-data';
import {DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";


let dropInstanceFilterUnits;
export default {

  components: {
    AppLayout,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, RowDD, VirtualScroll]
  },

  props: {
    projects: Object,
  },

  beforeMount() {
    // Odczytanie danych z sesji
    const storedProjectId = sessionStorage.getItem('selectedProjectId');
    const storedTeam = sessionStorage.getItem('selectedTeam');

    if (storedProjectId) {
      this.selectedProjectId = storedProjectId;
      this.updateTeams();
    }

    if (storedProjectId && storedTeam) {
      this.selectedTeamName = storedTeam;
      this.getOrderListForProject();
    }
  },

  data() {

    return {
      teams: [],
      orderListDataManager: [],
      fetchedOrders: [],
      selectedProjectId: null,
      selectedTeamName: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu"},
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {type: 'Multiple', cellSelectionMode: 'Box', mode: 'Cell'},
    }
  },

  methods: {
    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      return [
        "Add",
        {text: 'Usuń', prefixIcon: 'e-delete', id: 'delete_btn'},
        "Update",
        "Cancel",
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    updateSelectedProject(event) {
      this.selectedProjectId = Number(event.target.value);
      this.teams = null;
      this.selectedTeamName = null;
      if (this.selectedProjectId) {
        this.updateTeams()
      }
    },

    updateTeams() {
      if (this.selectedProjectId) {
        const project = this.projects.find(project => project.id === Number(this.selectedProjectId));
        if (project) {
          this.teams = project.teams;
          this.selectedTeamName = null;
        }
      } else {
        this.teams = [];
        this.selectedTeamName = null;
      }
    },

    updateSelectedTeam(event) {
      this.selectedTeamName = event.target.value;
      this.getOrderListForProject();
    },

    getOrderListForProject() {
      if (!this.selectedProjectId || !this.selectedTeamName) {
        return
      }

      sessionStorage.setItem('selectedProjectId', this.selectedProjectId);
      sessionStorage.setItem('selectedTeam', this.selectedTeamName);

      this.orderListDataManager = new DataManager({
        url: route('orderLists.getOrderList', {project: this.selectedProjectId, team: this.selectedTeamName}),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('orderLists.saveChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ],
      });
    },

    rowDrop(event) {

    },

    dateFormatter(field, data) {
      const date = data.created_at ? data.created_at : null;
      if (date) {
        try {
          return moment(date).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          return data
        }
      }

      return '';
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        // aktualizuj
        let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
        let buttons = toolbar.querySelectorAll('.e-toolbar-item');
        let updateButton = buttons[2].querySelector('button');
        if (updateButton) {
          updateButton.click();

        }
      }
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

  }
}
</script>

<style scoped>
.not-editable{
  color:orangered;
}
</style>