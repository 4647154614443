<style>
.floating-button {
  position: fixed;
  bottom: 5px;
  z-index: 9999;
  transition: all .5s ease-in-out;
}

.floating-button span {
  display: none;
}

.btn-preview {
  transition: all .5s ease-in-out;
  display: inline-block;
  margin-bottom: 1px;
}
</style>

<template>
  <div id="buttonPreviewOffer" class="align-bottom btn-preview" :class="{ 'floating-button': floatingButtonVisible }">
    <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-search"></i> <span>Podgląd</span>&nbsp;<i class="bi bi-chevron-down"></i>
    </button>
    <ul class="dropdown-menu">
      <li>
        <button type="button" class="dropdown-item" @click="generatePDF('pl')">PL</button>
      </li>
      <li>
        <button type="button" class="dropdown-item" @click="generatePDF('en')">EN</button>
      </li>
      <li>
        <button type="button" class="dropdown-item" @click="generatePDF('de')">DE</button>
      </li>
    </ul>
  </div>
</template>

<script>
import route from "../../../vendor/tightenco/ziggy/src/js/index.js";
import TinyMCEHelper from "../../../resources/library/js/tinymce/tinyMCEHelper.js";
import tinymce from "tinymce";

export default {
  components: {tinymce},
  props: {
    formData: Object,
    customer: Object,
    contact_persons: Object,
    editors: Array
  },
  data() {
    return {
      dataEditors: this.editors,
      generatingPDF: false,
      floatingButtonVisible: true,
      offerCard: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    // Wyłącz nasłuchiwanie zdarzenia przewijania strony, aby uniknąć wycieków pamięci
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async generatePDF(lang) {
      if (this.generatingPDF) {
        return;
      }
      this.generatingPDF = false;

      // Pobierz dane z formularza
      const data = this.formData;
      data.lang = lang;

      data.customer_id = this.customer ? this.customer.id : data.customer_id ?? null;

      data.offer_customer_contacts = data.offer_customer_contacts ?? {};

      data.introduction = tinymce.get('textarea_introduction').getContent();
      data.description = tinymce.get('textarea_description').getContent();
      data.epilogue = tinymce.get('textarea_epilogue').getContent();
      data.technical_data = tinymce.get('textarea_technical_data').getContent();

      data.contact_persons = this.contact_persons;

      // jeśli oferta nie jest ofertą aktywną, to pokaż jako historyczną
      if (!this.formData.is_active)
        data.history = true;

      try {
        // Wysyłanie danych POST do kontrolera
        await axios.post(route('offerPdf.preview'), data, {
          responseType: 'blob', // Ustawienie responseType na 'blob' do obsługi plików binarnych
        }).then(response => {
          // Stworzenie obiektu Blob z odpowiedzi
          const blob = new Blob([response.data], {type: 'application/pdf'});

          // Stworzenie URL do wyświetlenia pliku PDF w przeglądarce
          const pdfUrl = URL.createObjectURL(blob);

          // Otwarcie pliku PDF w nowym oknie przeglądarki
          window.open(pdfUrl, '_blank');
        });
      } catch (error) {
        console.error('Błąd podczas generowania podglądu PDF:', error);
      } finally {
        this.generatingPDF = false;
      }
    },
    handleScroll() {
      // Sprawdź, czy strona została przewinięta do końca card
      this.offerCard = $('.offer-card').height();
      const scrollTop = $(window).scrollTop() + $(window).height();
      this.floatingButtonVisible = this.offerCard > scrollTop;
    },
  },
}
;
</script>
