<style>
.floating-button {
    position: fixed;
    bottom: 5px;
    margin-right: 1rem;
    z-index: 9999;
    transition: all .5s ease-in-out;
}

.floating-button span {
    display: none;
}
</style>

<template>
    <div id="buttonPreviewOffer" class="align-bottom" :class="{ 'floating-button': floatingButtonVisible }" style="transition: all .5s ease-in-out">
        <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-search"></i> <span>Podgląd</span>&nbsp;<i class="bi bi-chevron-down"></i>
        </button>
        <ul class="dropdown-menu">
            <li>
                <button type="button" class="dropdown-item" @click="generatePDF('pl')">PL</button>
            </li>
            <li>
                <button type="button" class="dropdown-item" @click="generatePDF('en')">EN</button>
            </li>
            <li>
                <button type="button" class="dropdown-item" @click="generatePDF('de')">DE</button>
            </li>
        </ul>
    </div>
</template>

<script>
import route from "../../../vendor/tightenco/ziggy/src/js/index.js";
import tinymce from "tinymce";

export default {
    props: {
        formData: Object,
    },
    data() {
        return {
            generatingPDF: false,
            floatingButtonVisible: true,
            offerCard: 0,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        async generatePDF(lang) {
            if (this.generatingPDF) {
                return;
            }

            this.generatingPDF = true;

            const data = this.formData;
            data.lang = lang;
            try {
                const response = await axios.post(route('orderCardPdf.preview'), data, {
                    responseType: 'blob',
                });

                const blob = new Blob([response.data], {type: 'application/pdf'});
                const pdfUrl = URL.createObjectURL(blob);
                window.open(pdfUrl, '_blank');
            } catch (error) {
                console.error('Error generating PDF preview:', error);
            } finally {
                this.generatingPDF = false; // Reset generatingPDF when done
            }
        },
        handleScroll() {
            this.offerCard = $('.offer-card').height();
            const scrollTop = $(window).scrollTop() + $(window).height();
            this.floatingButtonVisible = this.offerCard > scrollTop;
        },
    },
};
</script>
