<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <div class="mb-3">
            <Link :href="route('customers.index')" class="btn btn-outline-primary">
              <i class="bi bi-people"></i>&nbsp;Lista kontrahentów
            </Link>
          </div>
          <div v-if="$page.props?.customer" class="">
            <form :action="route('customers.destroy', $page.props.customer)" method="post"
                  @submit.prevent="confirmDeleteCustomer" ref="deleteForm">
              <input type="hidden" name="_method" value="DELETE">
              <button type="submit" class="btn btn-outline-danger">Usuń kontrahenta</button>
            </form>
          </div>

        </div>

        <div class="widget-content">
          <CustomerEditForm :customer="customer" :customerCategories="customerCategories"></CustomerEditForm>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>

import {useForm} from "@inertiajs/vue3";
import AppLayout from "@/Layouts/App.vue";
import {ref} from "vue";
import ContactPersonCrud from "./ContactPersonCrud.vue";
import CustomerEditForm from './EditForm.vue';
export default {
  components: {ContactPersonCrud, AppLayout, CustomerEditForm},
  props: {
    customer: Object,
    customerCategories: Object
  },

  setup(){
    const confirmDeleteCustomer = (event, customer) => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      Toast.fire({
        toast: false,
        icon: 'warning',
        title: 'Usuwanie Klienta',
        html: 'Czy na pewno chcesz usunąć Klienta?',
        showDenyButton: true,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        position: 'center',
        showConfirmButton: true,
        confirmButtonText: 'Tak, usuń!',
        denyButtonText: 'Nie',
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            const csrfInput = document.createElement('input');
            csrfInput.type = 'hidden';
            csrfInput.name = '_token';
            csrfInput.value = csrfToken;
            event.target.appendChild(csrfInput);

            event.target.submit();
          } catch (e) {
            console.log(e);
          }
        }
      });
    }

    return {
      confirmDeleteCustomer
    }
  }

}
</script>
