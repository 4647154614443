<template>
  <div class="layout-spacing">
    <div class="widget widget-visitor-by-browser">
      <div class="widget-heading">
        <h5>Kto wystawił najwięcej ofert</h5>
        <div>
          <ejs-daterangepicker
              id="mostOffersId"
              placeholder="Wybierz zakres dat"
              :value="dateRange"
              :format="dateFormat"
              @change="onDateChange"
          ></ejs-daterangepicker>
        </div>
      </div>

      <template v-if="isLoading">
        <div class="text-center my-3">
          <div class="spinner-border spinner-border-reverse align-self-center loader-sm text-danger">Ładowanie...</div>
        </div>
      </template>

      <template v-else>
        <div class="widget-content" v-if="mostSentOffers.length > 0">
          <div class="browser-list" v-for="user in mostSentOffers">
            <div class="w-browser-details">
              <div class="w-browser-info">
                <h6 class="nameMostOffers">
                  {{ user.name }} <span class="text-muted">({{ user.position ?? '' }})</span>
                </h6>
                <p class="browser-count">{{ user.offersCount }}</p>
              </div>
              <div class="w-browser-stats">
                <div class="progress">
                  <div role="progressbar" aria-valuemin="0" aria-valuemax="100" :aria-valuenow="user.offersCountPercent"
                       class="progress-bar bg-primary" :style="{width: user.offersCountPercent + '%' }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import {DateRangePickerComponent} from "@syncfusion/ej2-vue-calendars";

export default {
  components: {'ejs-daterangepicker': DateRangePickerComponent},

  data() {
    const today = new Date();
    const _startDate = new Date(today);
    _startDate.setMonth(_startDate.getMonth() - 1); // Ustaw na jeden miesiąc przed dzisiejszą datą
    const _endDate = new Date(today); // Ustaw dzisiejszą datę


    return {
      isLoading: false,
      mostSentOffers: [],
      topMostOffersUsers: 5,
      dateRange: [_startDate, _endDate], // Początkowy zakres dat
      dateFormat: 'dd/MM/yyyy', // Format daty
    }
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    onDateChange(args) {
      this.dateRange = args.value;
      this.fetchData();
    },

    fetchData() {
      this.isLoading = true;
      axios.post(route('graphs.users.mostSentOffers', {
        'topMostOffersUsers': this.topMostOffersUsers,
        'dates': this.dateRange
      })).then(response => {
        this.mostSentOffers = response.data;
      }).catch(err => {
        console.error('Błąd podczas pobierania who sent most offers:', err);
      }).finally(() => {
        this.isLoading = false;
      })
    }
  }
}
</script>