<template>
    <div>
        <Header></Header>
        <FlashNotifications></FlashNotifications>

        <!--  BEGIN MAIN CONTAINER  -->
        <div class="main-container" id="container" :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']">
            <!--  BEGIN OVERLAY  -->
            <div class="overlay" :class="{ show: !$store.state.is_show_sidebar }" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"></div>

            <!--<div class="search-overlay" :class="{ show: $store.state.is_show_search }" @click="$store.commit('toggleSearch', !$store.state.is_show_search)"></div>-->
            <!-- END OVERLAY -->

            <Sidebar></Sidebar>

            <!--  BEGIN CONTENT AREA  -->
            <div id="content" class="main-content">
                <div class="layout-px-spacing">
                    <div class="middle-content p-0">
                        <div class="row layout-top-spacing">

                            <slot/>

                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </div>
            <!--  END CONTENT AREA  -->
        </div>
    </div>
</template>

<script setup>
import '../../assets/sass/app.scss';

import Header from "../Components/Layout/header.vue";
import Sidebar from "../Components/Layout/sidebar.vue";
import Footer from "../Components/Layout/footer.vue";
</script>

<script>

import FlashNotifications from "../Components/FlashNotifications.vue";

export default {
    components: {FlashNotifications},
}
</script>
