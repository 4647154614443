<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
              <h4 class="me-3 my-0">Utwórz nowy projekt</h4>
              <Link :href="route('projects.index')" class="btn btn-outline-primary">
                <i class="bi bi-box"></i>&nbsp;Lista projektów
              </Link>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-4">
              <h4 class="me-3 my-0 mb-2">Projekt</h4>
              <form @submit.prevent="submitForm">

                <div>
                  <label class="form-label" for="offer_id">Z oferty:
                    <i class="bi bi-info-circle" data-bs-toggle="tooltip" data-bs-placement="right"
                       title="Lista zawiera jedynie zaakceptowane oferty"></i>
                  </label>
                  <div v-if="form.errors.offer_id">{{ form.errors.offer_id }}</div>
                  <select class="select2 form-select" id="sel_offer_id" :value="form.offer_id">
                    <option v-for="offer in offers" :value="offer.id" :data-name="offer.version_title"
                            :data-number="offer.version_number">
                      {{ offer.version_number }} - {{ offer.version_title }}
                    </option>
                  </select>
                </div>

                <div>
                  <label class="form-label" for="customer_id">Klient*:</label>
                  <div v-if="form.errors.customer_id">{{ form.errors.customer_id }}</div>
                  <select class="select2 form-select" id="sel_customer_id" :value="form.customer_id">
                    <option v-for="customer in customers" :value="customer.id" :data-name="customer.name"
                            :data-number="customer.customer_id">
                      {{ customer.name }}
                    </option>
                  </select>
                </div>

                <label class="form-label" for="number">Numer*:</label>
                <div v-if="form.errors.number">{{ form.errors.number }}</div>
                <input class="form-control form-control-sm" id="number" v-model="form.number"/>

                <label class="form-label" for="name">Nazwa*:</label>
                <div v-if="form.errors.name">{{ form.errors.name }}</div>
                <input class="form-control form-control-sm" id="name" v-model="form.name"/>

                <label class="form-label" for="description">Opis:</label>
                <div v-if="form.errors.description">{{ form.errors.description }}</div>
                <input class="form-control form-control-sm" id="description" v-model="form.description"/>

                <template v-if="!isLoading">
                  <button class="btn btn-primary mt-3" type="submit" :disabled="isLoading">Zapisz</button>
                </template>

                <template v-if="isLoading">
                  <div class="mt-4 spinner-border spinner-border-reverse align-self-center loader-sm text-secondary">
                    Ładowanie...
                  </div>
                </template>

              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import route from "../../../../vendor/tightenco/ziggy/src/js/index.js";
import {router} from "@inertiajs/vue3";
import {Tooltip} from 'bootstrap';
import {isDisabled} from "bootstrap/js/src/util/index.js";

export default {
  components: {AppLayout},
  data() {
    return {
      isLoading: false,
      form: {
        name: null,
        description: null,
        offer_id: null,
        errors: {},
      }
    }
  },
  props: {
    offers: Array,
    customers: Array
  },
  mounted() {
    this.handleSelect2();
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
  methods: {
    isDisabled,
    handleSelect2() {
      $('#sel_offer_id').select2().on('select2:select', (e) => {
        this.form.offer_id = parseInt(e.params.data.id)
      });

      $('#sel_customer_id').select2().on('select2:select', (e) => {
        this.form.customer_id = parseInt(e.params.data.id)
      });
    },

    async submitForm() {
      this.isLoading = true;
      await router.post(route('projects.store', this.form));
      this.isLoading = false;
    },
  }
}
</script>
<style scoped>
/* Dodaj ten styl, aby zainicjować Bootstrap Tooltip */
.tooltip {
  opacity: 1 !important;
}
</style>
